import { useState, useEffect } from "react";

import { useHttpClient } from "../../hooks/http-hook";

const Languages = () => {
  const { sendRequest } = useHttpClient();
  const [availiableLanguages, setAvailableLanguages] = useState();

  useEffect(() => {
    let isMounted = true;

    const fetchLanguages = async () => {
      try {
        const responseData = await sendRequest(
          "https://restcountries.com/v3.1/all"
        );

        const languages = responseData.reduce((acc, country) => {
          if (country.languages) {
            Object.entries(country.languages).forEach(([key, value]) => {
              const optionText = `${value} (${key.toUpperCase()})`;
              acc.push({
                value: key,
                label: optionText,
              });
            });
          }
          return acc;
        }, []);

        const uniqueLanguages = [
          ...new Map(languages.map((item) => [item["value"], item])).values(),
        ];

        if (isMounted) {
          setAvailableLanguages(
            [...new Set(uniqueLanguages)].sort((a, b) => {
              const nameA = a.value.toUpperCase();
              const nameB = b.value.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
          );
        }
      } catch (err) {}
    };

    fetchLanguages();

    return () => {
      isMounted = false;
    };
  }, [sendRequest]);

  return availiableLanguages;
};

export default Languages;
