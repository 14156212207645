import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Button from "../../shared/FormElements/Button";

import { useUser } from "../../shared/context/user-context";
import { Data } from "../../shared/connections/Data";

const ProfileTop = () => {
  const isMounted = useIsComponentMounted("ProfileTop");

  const { user } = useUser();
  const user_indicators = Data({ type: "data", name: "user_indicators" });

  /* ######################################################################## */

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "var(--rpo-green)",
      color: "var(--rpo-green)",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  /* ######################################################################## */

  return (
    <React.Fragment>
      {isMounted && (
        <div className="d-flex flex-wrap flex-sm-nowrap">
          <div className="me-7 mb-4">
            <StyledBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                alt={user.profile.displayname}
                variant="rounded"
                sx={{ width: 150, height: 150 }}
                src={
                  process.env.REACT_APP_ASSET_URL_PATH + user.profile.image_url
                }
              />
            </StyledBadge>
          </div>
          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <span className="text-gray-900 fs-2 fw-bold me-1">
                    {user.displayname}
                  </span>
                </div>
                <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                  <span className="d-flex align-items-center text-gray-400 me-5 mb-2">
                    {user.title}
                  </span>
                  {!!user.profile.contactInformation.location.city ? (
                    <span className="d-flex align-items-center text-gray-400 me-5 mb-2">
                      {user.profile.contactInformation.location.city}
                      {user.profile.contactInformation.location.address}
                    </span>
                  ) : (
                    ""
                  )}
                  <span className="d-flex align-items-center text-gray-400 mb-2">
                    {user.email}
                  </span>
                </div>
              </div>
              <div className="d-flex my-4">
                <Button title="Action 1" />
                <Button title="Action 2" />
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center pe-8">
              <div className="d-flex flex-wrap">
                {!!user_indicators &&
                  user_indicators.map((indicator, index) => {
                    return (
                      <div
                        key={index}
                        className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                      >
                        <div className="d-flex align-items-center">
                          <span className="fs-2 fw-bold">
                            {indicator.value}
                          </span>
                        </div>
                        <span className="fw-semibold fs-6 text-gray-400">
                          {indicator.title}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ProfileTop;
