const Checkbox = (props) => {
  return (
    <label
      className={`form-check form-check-solid form-check-custom ${
        !!props.layout && props.layout === "switch"
          ? "form-switch fv-row"
          : "form-check-inline me-5"
      }`}
    >
      <input
        id={props.name}
        type="checkbox"
        className={(props.isValid!==undefined && !props.isValid)?"form-check-input border border-danger":"form-check-input"}
        defaultChecked={props.value}
        onChange={props.handler}
      />
      {!!props.label && (
        <span className="fw-semibold ps-2 fs-6">{props.label}</span>
      )}
    </label>
  );
};

export default Checkbox;
