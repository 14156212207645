import React from "react";

import Modal from "@mui/material/Modal";

import Button from "../../shared/FormElements/Button";
import * as assets from "../../assets/svgs.js";

const ClientModal = (props) => {
  const selectedClient = props.selectedEntity;
  return (
    <Modal
      open={props.open}
      onClose={props.closeModalHandler}
      aria-labelledby="staticBackdrop"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-dialog float-end pe-16" role="document">
        <div
          className="modal-content p-10 pe-10 bg-white border-rounded rounded-3"
          style={{ top: "35px", minWidth: "450px", maxWidth: "490px" }}
        >
          <div className="modal-header p-0 border-0">
            <span className="fs-6 fw-semibold text-black">Client Profile</span>
            <div
              className="rpo-svg-25px rpo-fill-first-orange-other-white rpo-clickable"
              aria-label="Close"
              onClick={props.closeModalHandler}
            >
              {assets.svg_close_circle}
            </div>
          </div>

          <div className="modal-body p-0">
            <div className="d-flex justify-content-start align-items-center pt-14 pb-10">
              <div className="symbol symbol-30px symbol-md-65px symbol-circle">
                <img
                  src={
                    process.env.REACT_APP_ASSET_URL_PATH +
                    selectedClient.profile.image_url
                  }
                  alt="profile"
                />
              </div>
              <div className="d-none d-md-flex flex-column align-items-start justify-content-center ms-2 ms-md-4">
                <span className="fs-3 text-black lh-1">
                  {selectedClient.title}
                </span>
                <span className="fs-6 text-gray-300 lh-1 py-2">
                  {selectedClient.company.market}
                </span>
                <div
                  className={`badge fs-7 fw-normal px-3 ${selectedClient.status.display.color.badge_color_class}`}
                >
                  <span>{selectedClient.status.display.text.title}</span>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center mt-10">
              <span className="fs-6 fw-semibold text-info pe-4 text-nowrap">
                Matching score:
              </span>
              <div className="progress h-6px w-100 me-2 bg-info-light">
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{
                    width: `${Math.max(
                      ...[
                        ...new Set(
                          selectedClient.jobs.map((job) =>
                            job.matches.map((match) => parseFloat(match.match))
                          )
                        ),
                      ].flatMap((match) => match)
                    )}%`,
                  }}
                  aria-valuenow="40"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <span className="fs-6 fw-semibold text-gray-600">
                {Math.max(
                  ...[
                    ...new Set(
                      selectedClient.jobs.map((job) =>
                        job.matches.map((match) => parseFloat(match.match))
                      )
                    ),
                  ].flatMap((match) => match)
                )}
                %
              </span>
            </div>

            <div className="d-flex justify-content-start align-items-center pb-10">
              <span className="fs-6 fw-semibold text-info pe-4 text-nowrap rpo-min-w-128px text-end">
                Income:
              </span>
              <span className="fs-6 fw-semibold text-gray-600">
                {selectedClient.income}$
              </span>
            </div>

            <div className="separator my-10"></div>

            <div className="d-flex justify-content-start align-items-center pb-10">
              <span className="fs-6 fw-semibold text-info pe-4 text-nowrap rpo-min-w-128px text-end">
                Openings:
              </span>
              <span className="fs-6 fw-semibold text-gray-600">
                {selectedClient.jobs.length}
              </span>
            </div>

            <div className="d-flex justify-content-start align-items-center pb-10">
              <span className="fs-6 fw-semibold text-info pe-4 text-nowrap rpo-min-w-128px text-end">
                Candidates:
              </span>
              <span className="fs-6 fw-semibold text-gray-600">
                {
                  [
                    ...new Set(
                      selectedClient.jobs.map((job) =>
                        job.matches.map((match) => match.candidate)
                      )
                    ),
                  ].flatMap((candidates) => candidates).length
                }
              </span>
            </div>

            <div className="d-flex justify-content-start align-items-center pb-10">
              <span className="fs-6 fw-semibold text-info pe-4 text-nowrap rpo-min-w-128px text-end">
                Contact:
              </span>
              <span className="fs-6 fw-semibold text-gray-600">
                {selectedClient.profile.display_name}
              </span>
            </div>

            <div className="d-flex justify-content-start align-items-center pb-10">
              <span className="fs-6 fw-semibold text-info pe-4 text-nowrap rpo-min-w-128px text-end">
                Website:
              </span>
              <a
                className="fs-6 fw-semibold text-warning"
                href={selectedClient.profile.contactInformation.social.website}
                alt="website"
              >
                {selectedClient.profile.contactInformation.social.website}
              </a>
            </div>

            <div className="d-flex justify-content-start align-items-center">
              <span className="fs-6 fw-semibold text-info pe-4 text-nowrap rpo-min-w-128px text-end">
                Email:
              </span>
              <a
                className="fs-6 fw-semibold text-warning"
                href={selectedClient.profile.contactInformation.email}
                alt="email"
              >
                {selectedClient.profile.contactInformation.email}
              </a>
            </div>

            <div className="separator my-10"></div>
            <div className="d-flex flex-column">
              <span className="fs-6 fw-semibold text-info pb-2">Address</span>
              <span className="fs-6 fw-semibold text-gray-600">
                {selectedClient.profile.contactInformation.location.address}
              </span>
              <span className="fs-6 fw-semibold text-gray-600">
                {selectedClient.profile.contactInformation.location.aptsuite}
              </span>
              <span className="fs-6 fw-semibold text-gray-600">
                {selectedClient.profile.contactInformation.location.country}
              </span>
              <span className="fs-6 fw-semibold text-gray-600 pt-2">
                {selectedClient.profile.contactInformation.phone}
              </span>
            </div>

            <div className="separator my-10"></div>

            <div className="d-flex justify-content-center align-items-center">
              <Button
                title="Show more"
                to={`/client/${selectedClient.id}`}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ClientModal;
