import React, { useState } from "react";

import Modal from "@mui/material/Modal";

import Button from "../../shared/FormElements/Button";
import * as assets from "../../assets/svgs.js";

const UserActionAddAJob = (props) => {
  const submitHandler = async (event) => {
    event.preventDefault();
    console.log("UserActionAddAJob", event);

    setOpen(false);
  };

  /* ######################################################################## */

  const [open, setOpen] = useState(false);
  const openModalHandler = function (state) {
    setOpen(true);
  };
  const closeModalHandler = () => setOpen(false);

  /* ######################################################################## */

  return (
    <React.Fragment>
      <Button
        icon={assets.svg_briefcase}
        title="Add a job"
        onClick={openModalHandler}
      />
      <Modal
        open={open}
        onClose={closeModalHandler}
        aria-labelledby="staticBackdrop"
        aria-describedby="modal-modal-description"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="modal-content p-10 pe-10 bg-white border-rounded rounded-3">
            <div className="modal-header border-0 pb-4">
              <div className="d-flex">
                <div className="rpo-svg-35px rpo-fill-purple">
                  {assets.svg_settings}
                </div>
                <span className="fs-1 text-gray-600 d-flex ms-4">
                  AI Job genarator
                </span>
              </div>
              <div
                className="rpo-svg-35px rpo-fill-first-orange-other-white rpo-clickable"
                aria-label="Close"
                onClick={closeModalHandler}
              >
                {assets.svg_close_circle}
              </div>
            </div>

            <div className="modal-body">
              <form className="form" onSubmit={submitHandler}>
                <div className="row mb-8">
                  <div className="col-lg-12 fv-row">
                    <label className="fs-4 text-gray-600 fw-bold d-flex mb-4">
                      Job title
                    </label>
                    <input
                      type="text"
                      name="jobtitle"
                      className="form-control form-control-lg rpo-input fs-6 fw-normal mb-3 mb-lg-0"
                      placeholder="Add the title you are hiring for"
                    />
                  </div>
                </div>

                <div className="row mb-8">
                  <div className="col-lg-12 fv-row">
                    <label className="fs-4 text-gray-600 fw-bold d-flex mb-4">
                      Skills
                    </label>
                    <input
                      type="text"
                      name="skills"
                      className="form-control form-control-lg rpo-input fs-6 fw-normal mb-3 mb-lg-0"
                      placeholder="Add the type of skills you are looking for"
                    />
                  </div>
                </div>

                <div className="row mb-8">
                  <div className="col-lg-12 fv-row">
                    <label className="fs-4 text-gray-600 fw-bold d-flex mb-4">
                      Company
                    </label>
                    <input
                      type="text"
                      name="company"
                      className="form-control form-control-lg rpo-input fs-6 fw-normal mb-3 mb-lg-0"
                      placeholder="Company name"
                    />
                  </div>
                </div>

                <div className="row mb-7">
                  <div className="col-lg-12 fv-row">
                    <label className="fs-4 text-gray-600 fw-bold d-flex mb-4">
                      Workplace Type
                    </label>

                    <div className="d-flex px-1 enki-checkbox-group">
                      <div className="form-check form-check-lg form-check-custom me-4">
                        <label className="form-check-label text-gray-600 fs-6 m-0 pe-4">
                          On-site:
                        </label>
                        <input
                          className="form-check-input rpo-clickable"
                          type="checkbox"
                          name="onsite"
                        />
                      </div>

                      <div className="form-check form-check-lg form-check-custom me-4">
                        <label className="form-check-label text-gray-600 fs-6 m-0 pe-4">
                          Hybrid:
                        </label>
                        <input
                          className="form-check-input rpo-clickable"
                          type="checkbox"
                          name="hybrid"
                        />
                      </div>

                      <div className="form-check form-check-lg form-check-custom me-4">
                        <label className="form-check-label text-gray-600 fs-6 m-0 pe-4">
                          Remote:
                        </label>
                        <input
                          className="form-check-input rpo-clickable"
                          type="checkbox"
                          name="remote"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-8">
                  <div className="col-lg-12 fv-row">
                    <label className="fs-4 text-gray-600 fw-bold d-flex mb-4">
                      Job location
                    </label>
                    <input
                      type="text"
                      name="jobtitle"
                      className="form-control form-control-lg rpo-input fs-6 fw-normal mb-3 mb-lg-0"
                      placeholder="California, USA"
                    />
                  </div>
                </div>

                <div className="row mb-7">
                  <div className="col-lg-12 fv-row">
                    <label className="fs-4 text-gray-600 fw-bold d-flex mb-4">
                      Job type
                    </label>

                    <div className="d-flex px-1">
                      <div className="form-check form-check-lg form-check-custom me-4">
                        <label className="form-check-label text-gray-600 fs-6 m-0 pe-4">
                          Full-time:
                        </label>
                        <input
                          className="form-check-input rpo-clickable"
                          type="checkbox"
                          name="fulltime"
                        />
                      </div>

                      <div className="form-check form-check-lg form-check-custom me-4">
                        <label className="form-check-label text-gray-600 fs-6 m-0 pe-4">
                          Part-time:
                        </label>
                        <input
                          className="form-check-input rpo-clickable"
                          type="checkbox"
                          name="parttime"
                        />
                      </div>

                      <div className="form-check form-check-lg form-check-custom me-4">
                        <label className="form-check-label text-gray-600 fs-6 m-0 pe-4">
                          Contract:
                        </label>
                        <input
                          className="form-check-input rpo-clickable"
                          type="checkbox"
                          name="contract"
                        />
                      </div>

                      <div className="form-check form-check-lg form-check-custom me-4">
                        <label className="form-check-label text-gray-600 fs-6 m-0 pe-4">
                          Intership:
                        </label>
                        <input
                          className="form-check-input rpo-clickable"
                          type="checkbox"
                          name="intership"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end align-items-center pt-4">
                  <Button icon={assets.svg_settings} title="Generate" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default UserActionAddAJob;
