import React, { useState } from "react";
// import { Link } from "react-router-dom";

import Modal from "@mui/material/Modal";
import Slider from "@mui/material/Slider";

import DatatableCard from "../cards/DatatableCard";

import Button from "../../shared/FormElements/Button";
import * as assets from "../../assets/svgs.js";

function valuetext(value) {
  return `${value}%`;
}

const UserActionAIMatching = (props) => {
  const submitHandler = async (event) => {
    event.preventDefault();
    console.log("UserActionAddAJob", event);

    setOpen(false);
  };

  const updateMatchesHandler = async (event) => {
    event.preventDefault();
    console.log("updateMatchesHandler", event);
  };

  /* ######################################################################## */

  const [value, setValue] = React.useState([20, 80]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* ######################################################################## */

  const [open, setOpen] = useState(false);
  const openModalHandler = function (state) {
    setOpen(true);
  };
  const closeModalHandler = () => setOpen(false);

  /* ######################################################################## */

  return (
    <React.Fragment>
      <Button
        icon={assets.svg_share}
        title="AI Matching"
        onClick={openModalHandler}
      />
      <Modal
        open={open}
        onClose={closeModalHandler}
        aria-labelledby="staticBackdrop"
        aria-describedby="modal-modal-description"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "800px",
          }}
        >
          <div className="modal-content p-10 pe-10 bg-white border-rounded rounded-3">
            <div className="modal-header border-0 pb-4">
              <div className="d-flex">
                <div className="rpo-svg-35px rpo-fill-purple">
                  {assets.svg_share}
                </div>
                <span className="fs-1 text-gray-600 d-flex ms-4">
                  AI Matching
                </span>
              </div>
              <div
                className="rpo-svg-35px rpo-fill-first-orange-other-white rpo-clickable"
                aria-label="Close"
                onClick={closeModalHandler}
              >
                {assets.svg_close_circle}
              </div>
            </div>

            <div className="modal-body">
              <form className="form" onSubmit={submitHandler}>
                <div className="row mb-8">
                  <div className="col-lg-12 fv-row">
                    <label className="fs-4 text-gray-600 fw-bold d-flex mb-4">
                      Quick search
                    </label>
                    <div className="row">
                      <div className="col-10 pe-10">
                        <div className="position-relative">
                          <div>
                            <div className="rpo-svg-20px rpo-fill-purple position-absolute top-50 translate-middle ms-6">
                              {assets.svg_briefcase}
                            </div>

                            <input
                              type="text"
                              className="form-control rpo-input ps-12"
                              name="jobsearch"
                              placeholder="No. of ID or Job title"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="d-flex justify-content-end align-items-center">
                          <Button
                            icon={assets.svg_search_normal}
                            title="Search"
                            onClick={updateMatchesHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-8">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-10 pe-10">
                        <div className="row">
                          <div className="col-3">
                            <label className="fs-4 text-gray-600 fw-bold">
                              Matching gap:
                            </label>
                          </div>
                          <div className="col-9 d-flex justify-content-center align-items-center p-0">
                            <Slider
                              getAriaLabel={() => "Matching range"}
                              value={value}
                              onChange={handleChange}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetext}
                              color="secondary"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-8">
                  <div className="col-lg-12">
                    <label className="fs-4 text-gray-600 fw-bold d-flex mb-4">
                      Select your job opening
                    </label>
                    <DatatableCard
                      title="Candidates"
                      datatableEntity="job"
                      datatableColumns={["ID", "Job Title", "Company"]}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center pt-4 mb-8">
                  <Button
                    layout="inactive"
                    icon={assets.svg_arrow_left}
                    title="Previous"
                  />
                  <Button
                    layout="inactive"
                    icon={assets.svg_arrow_right}
                    iconAfterTitle={true}
                    title="Next"
                  />
                </div>

                <div className="d-flex justify-content-end align-items-center pt-4">
                  <Button icon={assets.svg_share} title="Match" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default UserActionAIMatching;
