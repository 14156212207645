import React, { useEffect } from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import MainNavigation from "../../shared/Navigation/MainNavigation";
import DashboardTop from "../parts/DashboardTop";
import Scrolltop from "../../shared/components/UIElements/Scrolltop";

import ReportsSearchCard from "../cards/ReportsSearchCard";
import VsWidgetCard from "../cards/VsWidgetCard";
import TotalsOverallOverviewCard from "../cards/TotalsOverallOverviewCard";
import VisitsByCountryCard from "../cards/VisitsByCountryCard";
import ClientConversionsCard from "../cards/ClientConversionsCard";
import UserEventsCard from "../cards/UserEventsCard";
import SourcesCandidatePercentageCard from "../cards/SourcesCandidatePercentageCard";
import DatatableCard from "../cards/DatatableCard";

const ClientDashboard = () => {
  const isMounted = useIsComponentMounted("ClientDashboard");
  useEffect(() => {
    document.title = "Client Dashboard";
  }, []);

  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          <div className="page d-flex flex-row flex-column-fluid">
            <div className="wrapper d-flex flex-column flex-row-fluid rpo-wrapper-fuild-padding">
              <div className="d-flex flex-column-fluid">
                <MainNavigation />
                <div className="d-flex flex-column flex-column-fluid container-fluid rpo-px-32">
                  <div className="content flex-column-fluid">
                    <div className="row m-0 p-0">
                      <div className="col-12 m-0 p-0 rpo-pb-32">
                        <DashboardTop />
                      </div>
                    </div>

                    <div className="row m-0 p-0">
                      <div className="col-lg-12 col-xl-12 col-xxl-8 m-0 p-0 rpo-pe-32">
                        <div className="row m-0 p-0">
                          <div className="col-lg-12 m-0 p-0 rpo-pb-32">
                            <ReportsSearchCard />
                          </div>
                        </div>

                        <div className="row m-0 p-0">
                          <div className="col-lg-12 col-xl-4 m-0 p-0 rpo-pb-32 rpo-pe-16">
                            <VsWidgetCard name="clients_vs" />
                          </div>
                          <div className="col-lg-12 col-xl-4 m-0 p-0 rpo-pb-32 rpo-ps-16 rpo-pe-16">
                            <VsWidgetCard name="candidates_vs" />
                          </div>
                          <div className="col-lg-12 col-xl-4 m-0 p-0 rpo-pb-32 rpo-ps-16">
                            <VsWidgetCard name="income_vs" />
                          </div>
                        </div>

                        <div className="row m-0 p-0">
                          <div className="col-lg-12 m-0 p-0 rpo-pb-32">
                            <TotalsOverallOverviewCard />
                          </div>
                        </div>

                        <div className="row m-0 p-0">
                          <div className="col-lg-12 col-xl-12 col-xxl-6 m-0 p-0 rpo-pb-32 rpo-pe-16">
                            <VisitsByCountryCard />
                          </div>
                          <div className="col-lg-12 col-xl-12 col-xxl-6 m-0 p-0 rpo-pb-32 rpo-ps-16">
                            <ClientConversionsCard />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-xl-12 col-xxl-4 m-0 p-0">
                        <div className="row m-0 p-0">
                          <div className="col-lg-12 col-xl-12 m-0 p-0 rpo-pb-32">
                            <UserEventsCard />
                          </div>
                        </div>

                        <div className="row m-0 p-0">
                          <div className="col-lg-12 col-xl-12 m-0 p-0 rpo-pb-32">
                            <SourcesCandidatePercentageCard />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row m-0 p-0">
                      <div className="col-lg-12 col-xl-12 col-xxl-12 m-0 p-0 rpo-pb-32">
                        <DatatableCard
                          title="Recent Clients"
                          badgeText="Users"
                          toolbarLink={{
                            url: "/clients",
                            title: "See all clients",
                          }}
                          datatableEntity="client"
                          datatableColumns={[
                            "Company",
                            "Status",
                            "Job openings",
                            "Candidates",
                            "% of Best Matches",
                          ]}
                        />
                      </div>
                    </div>

                    <div className="row m-0 p-0">
                      <div className="col-12 m-0 p-0 rpo-pb-32">
                        <DatatableCard
                          title="Recent Candidates"
                          badgeText="Users"
                          toolbarLink={{
                            url: "/candidates",
                            title: "See all candidates",
                          }}
                          datatableEntity="candidate"
                          datatableColumns={[
                            "Name",
                            "Status",
                            "Current Title",
                            "Skill set",
                            "",
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrolltop />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ClientDashboard;
