const TextArea = (props) => {

    let className = !!props.className?props.className:"form-control rpo-textarea-no-resize";

    if(props.isValid!==undefined && !props.isValid){
      className += " border-danger";
    }

  return (
    <textarea
    id={props.id}
    defaultValue={props.value}
    className={className}
    rows={props.rows}
    onInput={props.handler}
    onChange={props.handler}
    //onBlur={props.handler}
  />
  );
};

export default TextArea;
