import { useState, useEffect } from "react";

import { useHttpClient } from "../../hooks/http-hook";

function getGMTOffset(timezone) {
  const now = new Date();
  const offset = now
    .toLocaleTimeString("en-US", { timeZone: timezone, timeZoneName: "short" })
    .split(" ")[2];

  return offset;
}

const Timezones = () => {
  const { sendRequest } = useHttpClient();
  const [availiableTimezones, setAvailableTimezones] = useState();

  useEffect(() => {
    let isMounted = true;

    const fetchTimezones = async () => {
      try {
        const responseData = await sendRequest(
          "https://worldtimeapi.org/api/timezone"
        );
        const timezones = responseData.reduce((acc, timezone) => {
          let gmtOffset = getGMTOffset(timezone);
          let optionText = `(${gmtOffset}) ${timezone}`;
          acc.push({
            value: timezone,
            label: optionText,
          });

          return acc;
        }, []);

        if (isMounted) {
          setAvailableTimezones(timezones);
        }
      } catch (err) {}
    };

    fetchTimezones();

    return () => {
      isMounted = false;
    };
  }, [sendRequest]);

  return availiableTimezones;
};

export default Timezones;
