import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import ReactApexChart from "react-apexcharts";
import * as assets from "../../assets/svgs.js";
import { Data } from "../../shared/connections/Data";

function CandidatesGrowthWidget(props) {
  const isMounted = useIsComponentMounted("CandidatesGrowthWidget");

  const data = Data({ type: "data", path: "chart", name: "candidates_growth" });
  let options = null;

  if (!!data) {
    let labelColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--bs-text-gray-600");

    let colorOptions = [
      "--rpo-dark-purple",
      "--rpo-purple-2",
      "--rpo-purple-3",
    ];
    let colors = [];
    for (let i = 0; i < data.series.length; i++) {
      let colorIndex = i % colorOptions.length;
      colors.push(colorOptions[colorIndex]);
    }

    options = {
      series: data.series.map((record) => {
        return {
          name: record.name,
          type: "bar",
          stacked: true,
          data: record.data,
        };
      }),
      chart: {
        fontFamily: "inherit",
        stacked: true,
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          stacked: true,
          horizontal: false,
          borderRadius: 2,
          columnWidth: ["40%"],
        },
      },
      legend: {
        show: true,
        position: "top",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: data.xaxis_categories,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        title: {
          text: data.xaxis_title,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
            color: labelColor,
          },
        },
      },
      yaxis: {
        max: 1000,
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        title: {
          text: data.yaxis_title,
          offsetX: 0,
          offsetY: 10,
          style: {
            fontSize: "14px",
            color: labelColor,
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "$" + val + " thousands";
          },
        },
      },
      colors: colors.map((color) =>
        getComputedStyle(document.documentElement).getPropertyValue(color)
      ),
      grid: {
        borderColor: "transparent",
        strokeDashArray: 0,
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    };
  }

  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          <div className="d-flex justify-content-between align-items-center border-bottom">
            <span className="fs-6 fw-semibold p-5">Candidates growth</span>
            <div className="rpo-svg-25px rpo-fill-orange rpo-stroke-path-orange pe-2 rpo-clickable">
              {assets.svg_more_vertical}
            </div>
          </div>
          {!!data && !!options && (
            <ReactApexChart
              options={options}
              series={options.series}
              type={options.chart.type}
              height={options.chart.height}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default CandidatesGrowthWidget;
