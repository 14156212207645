import React from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
  let selectedLayout = null;

  switch (props.layout) {
    case "active":
      selectedLayout = {
        button_class: `btn border border-warning d-flex ${
          !!props.icon ? "justify-content-between" : "justify-content-center"
        } align-items-center min-w-125px ${props?.iconAfterTitle?"":"me-4"}`,
        icon_class: "rpo-svg-20px rpo-fill-orange",
        title_class: "fs-lg fw-light text-warning ms-2",
      };
      break;
    case "active-2":
      selectedLayout = {
        button_class: `rpo-btn-white-bg ${props?.iconAfterTitle?"":"me-4"}`,
        icon_class: "rpo-svg-20px",
        title_class: "ps-2",
      };
      break;
      case "inactive":
        selectedLayout = {
          button_class: `rpo-btn-grey-bg ${props?.iconAfterTitle?"":"me-4"}`,
          icon_class: "rpo-svg-20px rpo-fill-grey",
          title_class: "text-gray-300",
        };
        break;
    default:
      selectedLayout = {
        // button_class: `rpo-btn-orange-bg ${props?.iconAfterTitle?"":"me-4"}`,
        button_class: `rpo-btn-purple-bg ${props?.iconAfterTitle?"":"me-4"}`,
        icon_class: "rpo-svg-20px",
        title_class: "ps-2",
      };
      break;
  }

  const innerElements = {
    icon: <div className={selectedLayout.icon_class}>{props.icon}</div>,
    title: <span className={selectedLayout.title_class}>{props.title}</span>,
  };

  if (!props.icon) {
    innerElements.icon = null;
  }

  if (props.to) {
    return (
      <button className="btn btn-warning px-4 py-3">
        <Link to={props.to} className="fs-7 fw-normal text-white">
          {props.title}
        </Link>
      </button>
    );
  }

  return (
    <React.Fragment>
      <button
        className={selectedLayout?.button_class +" "+ props?.ClassName}
        onClick={props.onClick}
        type={props.type}
      >
        {props.iconAfterTitle ? (
          <React.Fragment>
            {innerElements.title}
            {innerElements.icon}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {innerElements.icon}
            {innerElements.title}
          </React.Fragment>
        )}
      </button>
    </React.Fragment>
  );
};

export default Button;
