import React, { createContext, useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const PageContext = createContext();

export const usePageContext = () => useContext(PageContext);

export const PageProvider = ({ children }) => {
  const [previousPageTitle, setPreviousPageTitle] = useState("");
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      setPreviousPageTitle(document.title);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <PageContext.Provider value={{ previousPageTitle }}>
      {children}
    </PageContext.Provider>
  );
};
