import React from "react";

import Input from "../../../../shared/FormElements/Input";
import ImageUpload from "../../../../shared/FormElements/ImageUpload";

const FieldBlocks = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const getInput = (id) => (
    <Input
      id={id}
      value={formFields[id].value}
      handler={inputHandler}
      isValid={formFields[id].isValid}
      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
    />
  );

  const block1Elements = [
    { title: "Name", element: getInput("name") },
    { title: "Value", element: getInput("value") },
    { title: "Weight", element: getInput("weight") },
    { title: "is Active", element: getInput("is_active") },
  ];

  const block2Elements = [
    { title: "Display Text Title", element: getInput("display_text_title") },
    {
      title: "Display Text Description",
      element: getInput("display_text_description"),
    },
    { title: "Display Color Name", element: getInput("display_color_name") },
    { title: "Display Color Class", element: getInput("display_color_class") },
    {
      title: "Display Color Badge Color Class",
      element: getInput("display_color_badge_color_class"),
    },
    {
      title: "Display Color Badge SVG Fill Color Class",
      element: getInput("display_color_badge_svg_fill_color_class"),
    },
  ];

  return (
    <React.Fragment>
      <div className="separator separator-dashed my-6"></div>

      <div className="row mb-6">
        <div className="col-lg-6 fv-row mt-8">
          {block1Elements.map((blockElement, index) => {
            return (
              <div key={index} className="row mb-8">
                <label className="col-lg-6 col-form-label fw-semibold fs-6">
                  {blockElement.title}
                </label>
                <div className="col-lg-6 fv-row">{blockElement.element}</div>
              </div>
            );
          })}
          <div className="d-flex justify-content-center align-items-start pt-8">
            <ImageUpload
              id="image"
              inputHandler={inputHandler}
              value={formFields["image"].value}
              initialValue={formFields["image"].initialValue}
            />
          </div>
        </div>
        <div className="col-lg-6 fv-row mt-8">
          {block2Elements.map((blockElement, index) => {
            return (
              <div key={index} className="row mb-8">
                <label className="col-lg-6 col-form-label fw-semibold fs-6">
                  {blockElement.title}
                </label>
                <div className="col-lg-6 fv-row">{blockElement.element}</div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FieldBlocks;
