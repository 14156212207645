import { datatable_mapped_columns } from "./mappings";

/* ######################################################################## */

export function getDatatableColumns(requestedEntity, requestedColumns) {
  let selectedColumns = [];

  // Note that this requestedEntity rule could change
  if (requestedEntity === "activejob") {
    requestedEntity = "job";
  }

  if (datatable_mapped_columns[requestedEntity]) {
    requestedColumns.forEach((columnName) => {
      let selectedColumn = datatable_mapped_columns[requestedEntity].filter(
        (column) => column.name === columnName
      );
      if (selectedColumn.length === 1) {
        selectedColumns.push(selectedColumn[0]);
      } else {
        console.log(
          `>> Requested ${requestedEntity} column (${columnName}) has ${
            selectedColumn.length > 0 ? "multiple mappings" : "no mapping"
          }`
        );
      }
    });
  } else {
    console.log(`>> Requested entity (${requestedEntity}) mapping not found`);
  }

  return selectedColumns;
}

/* ######################################################################## */