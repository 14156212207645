import React from "react";
import { Link } from "react-router-dom";

import * as assets from "../../assets/svgs.js";

/* ######################################################################## */

function getTitleLayout(text) {
  return !(typeof text === "object") ? (
    <span className="text-gray-800 fw-semibold fs-6">{text}</span>
  ) : (
    ""
  );
}
function getSkillsLayout(skills) {
  const maxDisplayedSkills = 3;
  return (
    <div>
      {skills.map((skill, index) => {
        if (index < maxDisplayedSkills) {
          return (
            <Link
              key={index}
              to={"/field/" + skill.id}
              className="fs-5 text-warning"
            >
              <span
                className={`badge ${skill.display.color.badge_color_class} fw-bold ms-2 p-2`}
              >
                {skill.display.text.title}
              </span>
            </Link>
          );
        } else {
          return "";
        }
      })}
      {skills.length > maxDisplayedSkills ? (
        <span className="badge rpo-badge-grey fw-bold ms-2 p-2">
          +{skills.length - maxDisplayedSkills}
        </span>
      ) : (
        ""
      )}
    </div>
  );
}
function getStatusLayout(status) {
  return (
    <span
      className={`badge ${status.display.color.badge_color_class} fw-bold px-4 py-1 w-75 d-flex align-items-center justify-content-between`}
    >
      <div
        className={`rpo-svg-10px ${status.display.color.badge_svg_fill_color_class}`}
      >
        {assets.svg_dot}
      </div>
      <span className="fw-normal">{status.display.text.title}</span>
    </span>
  );
}
function getPriorityLayout(priority) {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <div className="d-flex w-100">
    <span
      className={`badge ${priority.display.color.badge_color_class} fw-bold px-4 py-2 w-75 d-flex align-items-center justify-content-between`}
    >
      <div
        className={`rpo-svg-14px ${priority.display.color.badge_svg_fill_color_class}`}
      >
        {assets.svg_danger}
      </div>
      <span className="fw-normal">{priority.display.text.title}</span>
    </span>
    </div>
    </div>

  );
}

export function getProfileLayout(profile) {
  return (
    <div className="d-flex justify-content-start align-items-center">
      <div className="symbol symbol-30px symbol-md-40px symbol-circle">
        <img
          src={process.env.REACT_APP_ASSET_URL_PATH + profile.image_url}
          alt="profile"
        />
      </div>
      <div className="d-none d-md-flex flex-column align-items-start justify-content-center ms-2 ms-md-4">
        <span className="fs-6 fw-semibold text-gray-600 lh-1 mb-1">
          {profile.displayname}
        </span>
        <span className="fs-6 fw-semibold text-gray-300 lh-1">
          {profile.contactInformation.email}
        </span>
      </div>
    </div>
  );
}
export function getCompanyLayout(company) {
  return (
    <Link to={"/company/" + company.id} className="fs-5 text-warning">
      <div className="d-flex justify-content-start align-items-center">
        <div className="symbol symbol-30px symbol-md-40px symbol-circle">
          <img
            src={process.env.REACT_APP_ASSET_URL_PATH + company.image_url}
            alt="company"
          />
        </div>
        <div className="d-none d-md-flex flex-column align-items-start justify-content-center ms-2 ms-md-4">
          <span className="fs-6 fw-semibold text-gray-600 lh-1 mb-1">
            {company.name}
          </span>
          <span className="fs-6 fw-semibold text-gray-300 lh-1">
            {company.website}
          </span>
        </div>
      </div>
    </Link>
  );
}

function getHiringManagerLayout(hiring_managers) {
  return (
    <React.Fragment>
      {hiring_managers && hiring_managers.length > 0 ? (
        <div className="d-flex align-items-center justify-content-start">
          <div className="symbol-group symbol-hover mb-1 overflow-visible">
            {hiring_managers.map((hiring_manager, index) => {
              return (
                <div key={index} className="symbol symbol-circle symbol-25px">
                  <img
                    src={
                      process.env.REACT_APP_ASSET_URL_PATH +
                      hiring_manager.image_url
                    }
                    alt=""
                  />
                </div>
              );
            })}
          </div>
          <Link to="/">
            <div className="rpo-svg-25px rpo-plus-icon mb-1 ms-4">
              {assets.svg_plus}
            </div>
          </Link>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-start">
          <Link to="/" className="fs-6 fw-semibold text-warning">
            Add new
          </Link>
        </div>
      )}
    </React.Fragment>
  );
}
function getTeamMembersLayout(team_members) {
  return (
    <React.Fragment>
      {team_members && team_members.length > 0 ? (
        <div className="d-flex align-items-center justify-content-start">
          <div className="symbol-group symbol-hover mb-1 overflow-visible">
            {team_members.map((member, index) => {
              return (
                <div key={index} className="symbol symbol-circle symbol-25px">
                  <img
                    src={
                      process.env.REACT_APP_ASSET_URL_PATH + member.image_url
                    }
                    alt="member"
                  />
                </div>
              );
            })}
          </div>
          <Link to="/">
            <div className="rpo-svg-25px rpo-plus-icon mb-1 ms-4">
              {assets.svg_plus}
            </div>
          </Link>
        </div>
      ) : (
        <Link to="/" className="fs-6 fw-semibold text-warning">
          Add new
        </Link>
      )}
    </React.Fragment>
  );
}
function getCandidatesLayout(candidates) {
  return (
    <div className="d-flex align-items-center justify-content-start">
      <div className="symbol-group symbol-hover mb-1 overflow-visible">
        {candidates.map((candidate, index) => {
          if (index < 5)
            return (
              <div key={index} className="symbol symbol-circle symbol-30px">
                <img
                  src={
                    process.env.REACT_APP_ASSET_URL_PATH +
                    candidate.profile.image_url
                  }
                  alt="candidate"
                />
              </div>
            );
          return "";
        })}
        {candidates.length > 5 && (
          <div className="symbol symbol-circle symbol-30px rpo-symbol-ligh-purple w-30px h-30px d-flex justify-content-center align-items-center">
            <span className="fs-6 text-info">+{candidates.length - 5}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export function getBestMatchesLayout(best_matches_percentage) {
  return (
    <div className="d-flex align-items-center w-100 mw-125px">
      <div className="progress h-6px w-100 me-2 bg-info-light">
        <div
          className="progress-bar bg-info"
          role="progressbar"
          style={{ width: `${best_matches_percentage}%` }}
          aria-valuenow={best_matches_percentage}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <span className="fs-6 fw-semibold text-nowrap">
        {best_matches_percentage}%
      </span>
    </div>
  );
}

function getMoreLayout() {
  return (
    <div className="rpo-svg-20px rpo-fill-gray">{assets.svg_more_vertical}</div>
  );
}
function getActionsLayout() {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="rpo-svg-25px rpo-fill-white rpo-stroke-path-grey p-2 me-4">
        {assets.svg_trash}
      </div>

      <div className="rpo-svg-25px rpo-fill-white rpo-stroke-path-grey p-2">
        {assets.svg_edit_pencil}
      </div>
    </div>
  );
}

/* ######################################################################## */

export const datatable_mapped_columns = {
  job: [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      cell: (row) => getTitleLayout(row.id),
    },
    {
      name: "Job Title",
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => getTitleLayout(row.title),
      grow: 2,
    },
    {
      name: "Client",
      selector: (row) => row.company.name,
      sortable: true,
      cell: (row) => getTitleLayout(row.company.name),
    },
    {
      name: "Job Status",
      selector: (row) => row.status.weight,
      sortable: true,
      cell: (row) => getStatusLayout(row.status),
      style: {
        display: "initial",
      },
    },
    {
      name: "Employment",
      selector: (row) => row.information.employment_type.display.text.title,
      sortable: true,
      cell: (row) =>
        getTitleLayout(row.information.employment_type.display.text.title),
    },
    {
      name: "Owner",
      selector: (row) => row.owner.displayname,
      sortable: true,
      cell: (row) => getTitleLayout(row.owner.displayname),
    },
    {
      name: "Company",
      selector: (row) => row.company.name,
      sortable: true,
      cell: (row) => getTitleLayout(row.company.name),
    },
    {
      name: "Hiring Manager",
      selector: (row) => row.hiring_managers,
      sortable: true,
      cell: (row) => getHiringManagerLayout(row.hiring_managers),
      grow: 2,
    },
    {
      name: "Assigned Team Members",
      selector: (row) => row.team_members,
      sortable: true,
      cell: (row) => getTeamMembersLayout(row.team_members),
      grow: 2,
    },
    {
      name: "Contacted",
      selector: (row) => row.applications.length,
      sortable: true,
      cell: (row) => getTitleLayout(row.applications.length),
    },
    {
      name: "Active Candidates",
      selector: (row) =>
        [
          ...new Set(
            row.applications.map((application) => application.candidate)
          ),
        ].length,
      sortable: true,
      cell: (row) =>
        getTitleLayout(
          [
            ...new Set(
              row.applications.map((application) => application.candidate)
            ),
          ].length
        ),
    },
    {
      name: "No. Applications",
      selector: (row) => row.applications.length,
      sortable: true,
      cell: (row) => getTitleLayout(row.applications.length),
    },
    {
      name: "% of Best Matches",
      selector: (row) =>
        Math.max(
          ...[
            ...new Set(
              row.matches.map((matche) => parseFloat(matche.matchPercentage))
            ),
          ]
        ),
      sortable: true,
      cell: (row) =>
        getBestMatchesLayout(
          Math.max(
            ...[
              ...new Set(
                row.matches.map((matche) => parseFloat(matche.matchPercentage))
              ),
            ]
          )
        ),
      style: {
        display: "initial",
      },
      grow: 2,
    },
    {
      name: "Priority",
      selector: (row) => row.priority.weight,
      sortable: true,
      cell: (row) => getPriorityLayout(row.priority),
      style: {
        display: "initial",
      },
    },
  ],
  candidate: [
    {
      name: "ID",
      selector: (row) => row._id,
      width: "6em",
      sortable: true,
      cell: (row) => getTitleLayout(row._id),
    },
    {
      name: "Name",
      selector: (row) => row.display_name,
      sortable: true,
      cell: (row) => getProfileLayout(row.profile),
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      cell: (row) =>
        getTitleLayout(row.profile.contactInformation.location.country),
    },
    {
      name: "Status",
      selector: (row) => row.employment.status,
      sortable: true,
      cell: (row) => getStatusLayout(row.employment.status),
      style: {
        display: "initial",
      },
    },
    {
      name: "Current/Recent Company",
      selector: (row) =>
        row.employment.history[row.employment.history.length - 1].company.name,
      sortable: true,
      cell: (row) =>
        getTitleLayout(
          row.employment.history[row.employment.history.length - 1].company.name
        ),
    },
    {
      name: "Current Title",
      selector: (row) =>
        row.employment.history[row.employment.history.length - 1].title,
      sortable: true,
      cell: (row) =>
        getTitleLayout(
          row.employment.history[row.employment.history.length - 1].title
        ),
    },
    {
      name: "Skill set",
      selector: (row) => row.skills,
      sortable: true,
      cell: (row) => getSkillsLayout(row.skills),
    },
    {
      name: "Position applied",
      selector: (row) => row.applications.length,
      sortable: true,
      cell: (row) => getTitleLayout(row.applications.length),
    },
    {
      name: "No.Companies Applied",
      selector: (row) =>
        [...new Set(row.applications.map((application) => application.company))]
          .length,
      sortable: true,
      cell: (row) =>
        getTitleLayout(
          [
            ...new Set(
              row.applications.map((application) => application.company)
            ),
          ].length
        ),
    },
    {
      name: "% of Best Matches",
      selector: (row) =>
        Math.max(
          ...[
            ...new Set(
              row.applications.map((application) =>
                parseFloat(application.matchPercentage)
              )
            ),
          ]
        ),
      sortable: true,
      cell: (row) =>
        getBestMatchesLayout(
          Math.max(
            ...[
              ...new Set(
                row.applications.map((application) =>
                  parseFloat(application.matchPercentage)
                )
              ),
            ]
          )
        ),
      style: {
        display: "initial",
      },
    },
    {
      name: "",
      selector: (row) => row.id,
      sortable: false,
      cell: (row) => getActionsLayout(),
    },
  ],
  client: [
    {
      name: "Company",
      selector: (row) => row.company,
      sortable: true,
      cell: (row) => getCompanyLayout(row.company),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => getStatusLayout(row.status),
    },
    {
      name: "Job openings",
      selector: (row) => row.jobs.length,
      sortable: true,
      cell: (row) => getTitleLayout(row.jobs.length),
    },
    {
      name: "Candidates",
      selector: (row) =>
        [
          ...new Set(
            row.jobs.map((job) => job.matches.map((match) => match.candidate))
          ),
        ].flatMap((candidates) => candidates).length,
      sortable: true,
      cell: (row) =>
        getCandidatesLayout(
          [
            ...new Set(
              row.jobs.map((job) => job.matches.map((match) => match.candidate))
            ),
          ].flatMap((candidates) => candidates)
        ),
    },
    {
      name: "% of Best Matches",
      selector: (row) =>
        Math.max(
          ...[
            ...new Set(
              row.jobs.map((job) =>
                job.matches.map((match) => parseFloat(match.match))
              )
            ),
          ].flatMap((match) => match)
        ),
      sortable: true,
      cell: (row) => (
        <div className="d-flex align-items-center justify-content-between">
          {getBestMatchesLayout(
            Math.max(
              ...[
                ...new Set(
                  row.jobs.map((job) =>
                    job.matches.map((match) => parseFloat(match.match))
                  )
                ),
              ].flatMap((match) => match)
            )
          )}
          {getMoreLayout()}
        </div>
      ),
      style: {
        display: "initial",
      },
    },
  ],
};

/* ######################################################################## */
