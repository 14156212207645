import React, { useContext, useState } from "react";

import Input from "../../shared/FormElements/Input";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { validate } from "../../shared/util/validators";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from "../../shared/util/validators";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import Logo from "../../shared/components/UIElements/Logo";

const AuthForm = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest } = useHttpClient();

  /* ######################################################################## */

  const [formFields, setFormFields] = useState({
    email: {
      value: "",
      initialValue: "",
      isValid: true,
      hasChanges: false,
      validators: [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()],
    },
    password: {
      value: "",
      initialValue: "",
      isValid: true,
      hasChanges: false,
      validators: [VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(6)],
    },
  });

  const [formState, setFormState] = useState({
    isValid: false,
    hasChanges: false,
  });

  /* ######################################################################## */

  const inputHandler = (event) => {
    const { id, value } = event.target;
    const field = formFields[id];
    let hasChanges = !(field.initialValue === value);
    let isValid = !!field.validators ? validate(value, field.validators) : true;

    setFormFields((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value: value,
        isValid: isValid,
        hasChanges: hasChanges,
      },
    }));

    setFormState((prevState) => ({
      ...prevState,
      isValid: Object.entries(formFields).every(([fieldId, fieldData]) =>
        id === fieldId ? isValid : fieldData.isValid
      ),
      hasChanges: Object.entries(formFields).some(([fieldId, fieldData]) =>
        id === fieldId ? hasChanges : fieldData.hasChanges
      ),
    }));
  };

  /* ######################################################################## */

  const authSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/users/login",
        "POST",
        JSON.stringify({
          email: formFields.email.value,
          password: formFields.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      auth.login(responseData.userId, responseData.token);
    } catch (err) {}
  };

  /* ######################################################################## */

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <form className="form w-100" onSubmit={authSubmitHandler}>
        <div className="text-center mb-11">
          <a href="/" alt="Homepage">
            <Logo />
          </a>
        </div>

        <h1 className="fv-row mb-11 rpo-fs-intro rpo-text-shadow-intro fw-lighter text-white text-nowrap">
            {/* Disrupting the Recruiting Tech World! */}
            AI-Powered Recruiting and RPO
        </h1>

        <div className="fv-row mb-11 fw-lighter text-white justify-content-center align-items-center" style={{ textAlign : 'center' }}> Log in using email address </div>

        <div className="fv-row mb-8 d-flex justify-content-center align-items-center">
          <div className="col-7">
            <label className="text-white pb-2">Email</label>
            <Input
              id="email"
              value={formFields["email"].value}
              handler={inputHandler}
              isValid={formFields["email"].isValid}
              className="rpo-min-h-52px form-control form-control-lg rpo-input bg-transparent text-white rpo-fw-200 fs-6"
            />
          </div>
        </div>

        <div className="fv-row mb-8 d-flex justify-content-center align-items-center">
          <div className="col-7">
            <label className="text-white pb-2">Password</label>
            <Input
              id="password"
              value={formFields["password"].value}
              handler={inputHandler}
              isValid={formFields["password"].isValid}
              className="rpo-min-h-52px form-control form-control-lg rpo-input bg-transparent text-white rpo-fw-200 fs-6"
              type="password"
            />
          </div>
        </div>

        <div className="fv-row mb-8 d-flex justify-content-center align-items-center">
          <div className="col-7">
            {error && (
              <span className="d-flex aling-items-center justify-content-center text-danger p-2 py-3 mb-10 rpo-bg-light-red rpo-border-1-r2em-red">
                {error}
              </span>
            )}
            <button
              type="submit"
              className="btn btn-primary bg-transparent border rounded-5 w-100 rpo-min-h-52px"
              disabled={!formState.isValid}
            >
              <span className="indicator-label fs-4 rpo-fw-200 lh-2"> 
                {/* Ignite Digital Change  */}
                Log in
              </span>
            </button>
          </div>
        </div>

        <div className="fv-row mb-11 fw-lighter text-white d-flex justify-content-center align-items-center">  
          <div style={{ width : "fit-content" , paddingRight : "15px" }}> 
            Need to create an account? 
          </div>
          <a href="/signup" className="text-white"> Sign-Up  </a>
        </div>

      </form>
    </React.Fragment>
  );
};

export default AuthForm;
