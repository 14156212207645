import React from "react";

import TextArea from "../../../../shared/FormElements/TextArea";
import Select from "../../../../shared/FormElements/Select";

const CandidateFlagsBlock = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const workauthorizationValues = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const availabilitytointerviewValues = [
    { label: "Open", value: true },
    { label: "Not Availiable", value: false },
  ];

  const blockElements = [
    {
      title: "Availability/Timeline",
      element: (
        <TextArea
          id="notes_availability"
          value={formFields["notes_availability"].value}
          handler={inputHandler}
          isValid={formFields["notes_availability"].isValid}
        />
      ),
    },
    {
      title: "Interview Activity",
      element: (
        <TextArea
          id="notes_interviewactivity"
          value={formFields["notes_interviewactivity"].value}
          handler={inputHandler}
          isValid={formFields["notes_interviewactivity"].isValid}
          rows={1}
        />
      ),
    },
    {
      title: "Compensation",
      element: (
        <TextArea
          id="notes_compensation"
          value={formFields["notes_compensation"].value}
          handler={inputHandler}
          isValid={formFields["notes_compensation"].isValid}
          rows={1}
        />
      ),
    },
    {
      title: "Work Authorization",
      element: (
        <Select
          name="flags_workauthorization"
          value={formFields["flags_workauthorization"].value}
          handler={inputHandler}
          options={workauthorizationValues}
          isValid={formFields["flags_workauthorization"].isValid}
        />
      ),
    },
    {
      title: "Availability to Interview",
      element: (
        <Select
          name="flags_availabilitytointerview"
          value={formFields["flags_availabilitytointerview"].value}
          handler={inputHandler}
          options={availabilitytointerviewValues}
          isValid={formFields["flags_availabilitytointerview"].isValid}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      {blockElements.map((blockElement, index) => {
        return (
          <React.Fragment key={index}>
            <div className="separator my-6"></div>

            <div className="row form-group pe-19 mb-6">
              <div className="col-4 d-flex justify-content-start align-items-start">
                <label className="fs-6 fw-semibold text-gray-600 px-4">
                  {blockElement.title}
                </label>
              </div>
              <div className="col-8">{blockElement.element}</div>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default CandidateFlagsBlock;
