const Input = (props) => {

    let value = props.value;
    let className = !!props.className?props.className:"form-control form-control-lg form-control-solid mb-3 mb-lg-0";
  
    if (props.id === "contactInformation_location_coordinates") {
      value = `${props.value.lat},${props.value.lng}`;
      className += " d-none";
    }

    if(props.isValid!==undefined && !props.isValid){
      className += " border-danger";
    }

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    }; 

  return (
    <input
      id={props.id}
      type={!!props.type?props.type:"text"}
      defaultValue={value}
      className={className}
      onInput={props.handler}
      onChange={props.handler}
      placeholder={props.placeholder}
      //onBlur={props.handler}
      onKeyPress={handleKeyPress}
    />
  );
};

export default Input;
