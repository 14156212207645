import React from "react";

import Input from "../../../../shared/FormElements/Input";

const CandidateContactBlock = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const blockElements = [
    {
      title: "Email address",
      element: (
        <Input
          id="profile_contactInformation_email"
          value={formFields["profile_contactInformation_email"].value}
          handler={inputHandler}
          isValid={formFields["profile_contactInformation_email"].isValid}
          className="form-control form-control-lg rpo-input rpo-input-icon rpo-input-icon-mail fs-6 fw-normal text-warning mb-3 mb-lg-0 me-6"
        />
      ),
    },
    {
      title: "LinkedIn",
      element: (
        <Input
          id="profile_contactInformation_social_linkedin"
          value={formFields["profile_contactInformation_social_linkedin"].value}
          handler={inputHandler}
          isValid={formFields["profile_contactInformation_social_linkedin"].isValid}
          className="form-control form-control-lg rpo-input rpo-input-icon rpo-input-icon-globe fs-6 fw-normal text-warning mb-3 mb-lg-0 me-6"
        />
      ),
    },
    {
      title: "Portfolio",
      element: (
        <Input
          id="profile_contactInformation_social_website"
          value={formFields["profile_contactInformation_social_website"].value}
          handler={inputHandler}
          isValid={formFields["profile_contactInformation_social_website"].isValid}
          className="form-control form-control-lg rpo-input rpo-input-icon rpo-input-icon-globe fs-6 fw-normal text-warning mb-3 mb-lg-0 me-6"
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      {blockElements.map((blockElement, index) => {
        return (
          <React.Fragment key={index}>
            <div className="separator my-6"></div>

            <div className="row form-group pe-19 mb-6">
              <div className="col-4 d-flex justify-content-start align-items-start">
                <label className="fs-6 fw-semibold text-gray-600 px-4">
                  {blockElement.title}
                </label>
              </div>
              <div className="col-8">{blockElement.element}</div>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default CandidateContactBlock;
