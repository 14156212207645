import React from "react";
import { useHistory } from "react-router-dom";
import { usePageContext } from "../../shared/context/page-context";
import * as assets from "../../assets/svgs.js";

const Breadcrumb = (props) => {
  const { previousPageTitle } = usePageContext();
  const history = useHistory();

  const handleGoBack = () => {
    if (props?.route) {
      history.push(props.route);
    } else {
      history.goBack();
    }
  };

  return (
    <div
      onClick={handleGoBack}
      className="d-flex"
      style={{ cursor: "pointer" }}
    >
      <div className="rpo-svg-15px rpo-fill-orange">
        {assets.svg_arrow_left}
      </div>
      <span className="text-warning ps-4">
        Back to {props?.route ? "Overview" : previousPageTitle}
      </span>
      {props?.route ? (
        ""
      ) : (
        <React.Fragment>
          <span className="fs-7 p-0 px-1 text-gray-600">|</span>
          <span className="fs-7 p-0 text-gray-600">{document.title}</span>
        </React.Fragment>
      )}
    </div>
  );
};

export default Breadcrumb;
