import React from "react";

import AuthForm from "../forms/AuthForm";

const Login = () => {
  return (
    <React.Fragment>
      <div className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center bg-rpo">
        <div className="d-block align-items-stretch flex-center rounded-4 w-md-777px">
          <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
            <AuthForm />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
