import { useState, useEffect } from 'react';

let isMounted = {};

export function useIsComponentMounted(componentName) {
  const [mounted, setMounted] = useState(isMounted[componentName] || false);

  useEffect(() => {
    isMounted[componentName] = true;
    setMounted(true);

    return () => {
      isMounted[componentName] = false;
      setMounted(false);
    };
  }, [componentName]);

  return mounted;
}