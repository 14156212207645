import React, { useState, useContext } from "react";

import CandidateProfileBlock from "./parts/candidate/CandidateProfileBlock";
import CandidateStatusBlock from "./parts/candidate/CandidateStatusBlock";
import CandidateContactBlock from "./parts/candidate/CandidateContactBlock";
import CandidateNotesBlock from "./parts/candidate/CandidateNotesBlock";
import CandidateLocationBlock from "./parts/candidate/CandidateLocationBlock";
import CandidateFlagsBlock from "./parts/candidate/CandidateFlagsBlock";
import CandidateDocumentsBlock from "./parts/candidate/CandidateDocumentsBlock";

import Button from "../../shared/FormElements/Button";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { validate } from "../../shared/util/validators";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_LINKEDIN,
  VALIDATOR_WEBSITE,
} from "../../shared/util/validators";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const CandidateForm = (props) => {
  const auth = useContext(AuthContext);

  const candidate = props.candidate;
  const latestCandidatePosition = candidate.employment.history.sort(
    (a, b) => new Date(b.dates.creation) - new Date(a.dates.creation)
  )[0];

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  /* ######################################################################## */

  const generateField = (value, validators = [], initialValue = value) => {
    const field = {
      initialValue,
      isValid: true,
      hasChanges: false,
      validators,
    };

    if (value !== undefined && value !== null) {
      field.value = value;
    }

    return field;
  };

  /* ######################################################################## */

  const candidateBlockFields = {
    latest_candidate_position_title: generateField(
      latestCandidatePosition?.title,
      [VALIDATOR_REQUIRE()],
      ""
    ),
    employment_status: generateField(
      candidate?.employment?.status?.id,
      [VALIDATOR_REQUIRE()],
      ""
    ),
  };

  const profileBlockFields = {
    profile_firstname: generateField(
      candidate?.profile?.firstname,
      [VALIDATOR_REQUIRE()],
      ""
    ),
    profile_lastname: generateField(
      candidate?.profile?.lastname,
      [VALIDATOR_REQUIRE()],
      ""
    ),
    image: generateField(candidate?.profile?.image_url, [], ""),
    profile_contactInformation_email: generateField(
      candidate?.profile?.contactInformation?.email,
      [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()],
      ""
    ),
    profile_contactInformation_social_linkedin: generateField(
      candidate?.profile?.contactInformation?.social?.linkedin,
      [VALIDATOR_REQUIRE(), VALIDATOR_LINKEDIN()],
      ""
    ),
    profile_contactInformation_social_website: generateField(
      candidate?.profile?.contactInformation?.social?.website,
      [VALIDATOR_REQUIRE(), VALIDATOR_WEBSITE()],
      ""
    ),
    profile_contactInformation_location_country: generateField(
      candidate?.profile?.contactInformation?.location?.country,
      [VALIDATOR_REQUIRE()],
      ""
    ),
    profile_contactInformation_timezone: generateField(
      candidate?.profile?.timezone,
      [VALIDATOR_REQUIRE()],
      ""
    ),
  };

  const notesBlockFields = {
    notes_summary: generateField(candidate?.notes?.summary, [], ""),
    notes_currently: generateField(candidate?.notes?.currently, [], ""),
    notes_whylooking: generateField(candidate?.notes?.whylooking, [], ""),
    notes_previously: generateField(candidate?.notes?.previously, [], ""),
    notes_availability: generateField(
      candidate?.notes?.availability,
      [VALIDATOR_REQUIRE()],
      ""
    ),
    notes_interviewactivity: generateField(
      candidate?.notes?.interviewactivity,
      [VALIDATOR_REQUIRE()],
      ""
    ),
    notes_compensation: generateField(
      candidate?.notes?.compensation,
      [VALIDATOR_REQUIRE()],
      ""
    ),
  };

  const flagsBlockFields = {
    flags_workauthorization: generateField(
      candidate?.flags?.workauthorization,
      [VALIDATOR_REQUIRE()],
      ""
    ),
    flags_availabilitytointerview: generateField(
      candidate?.flags?.availabilitytointerview,
      [VALIDATOR_REQUIRE()],
      ""
    ),
  };

  /* ######################################################################## */

  const [formFields, setFormFields] = useState({
    ...candidateBlockFields,
    ...profileBlockFields,
    ...notesBlockFields,
    ...flagsBlockFields,
  });

  const [formState, setFormState] = useState({
    isValid: true,
    hasChanges: false,
  });

  /* ######################################################################## */

  const inputHandler = (event) => {
    const { id, value } = event.target;
    const field = formFields[id];
    let hasChanges = !(field.initialValue === value);
    let isValid = !!field.validators ? validate(value, field.validators) : true;

    setFormFields((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value: value,
        isValid: isValid,
        hasChanges: hasChanges,
      },
    }));

    setFormState((prevState) => ({
      ...prevState,
      isValid: Object.entries(formFields).every(([fieldId, fieldData]) =>
        id === fieldId ? isValid : fieldData.isValid
      ),
      hasChanges: Object.entries(formFields).some(([fieldId, fieldData]) =>
        id === fieldId ? hasChanges : fieldData.hasChanges
      ),
    }));
  };

  /* ######################################################################## */

  const submitFormHandler = async (event) => {
    event.preventDefault();

    const formData = {};
    for (const key in formFields) {
      if (formFields[key].hasChanges) {
        if (
          typeof formFields[key].value === "object" &&
          typeof formFields[key].value.type === "undefined"
        ) {
          formData[key] = formFields[key].value;
        } else {
          formData[key] = JSON.stringify(formFields[key].value);
        }
      }
    }

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/candidates/candidate/${candidate.id}`,
        "PATCH",
        JSON.stringify(formData),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      console.log('CandidateForm',responseData);
      clearError();

      setFormState((prevState) => ({
        ...prevState,
        isValid: true,
        hasChanges: false,
      }));
    } catch (err) {}
  };

  const resetFormHandler = (event) => {
    //event.preventDefault();
    setFormFields((prevFormFields) => {
      const resetedFormFields = Object.fromEntries(
        Object.entries(prevFormFields).map(([key, value]) => [
          key,
          {
            ...value,
            value: value.initialValue,
            isValid: true,
            hasChanges: false,
          },
        ])
      );
      return resetedFormFields;
    });
    setFormState((prevState) => ({
      ...prevState,
      isValid: true,
      hasChanges: false,
    }));
    clearError();
  };

  /* ######################################################################## */

  return (
    <form
      className="form w-100"
      onSubmit={submitFormHandler}
      onReset={resetFormHandler}
    >
      {isLoading && <LoadingSpinner asOverlay />}
      {error && (
        <span
          className="d-flex aling-items-center justify-content-center text-danger p-2 my-4 bg-white rounded rpo-clickable"
          onClick={resetFormHandler}
        >
          {error}
        </span>
      )}
      <div className="d-flex flex-column align-items-start justify-content-center">
        <span className="fs-3 text-gray-600 pb-2">Personal info</span>
        <span className="fs-7 text-gray-600">
          Candidate personal and information details
        </span>
      </div>

      <CandidateProfileBlock
        formFields={formFields}
        inputHandler={inputHandler}
      />
      <CandidateStatusBlock
        formFields={formFields}
        inputHandler={inputHandler}
      />
      <CandidateContactBlock
        formFields={formFields}
        inputHandler={inputHandler}
      />
      <CandidateNotesBlock
        formFields={formFields}
        inputHandler={inputHandler}
      />
      <CandidateLocationBlock
        formFields={formFields}
        inputHandler={inputHandler}
      />
      <CandidateFlagsBlock
        formFields={formFields}
        inputHandler={inputHandler}
      />
      <CandidateDocumentsBlock candidate={candidate} />

      {(formState.isValid || formState.hasChanges) && (
        <React.Fragment>
          <div className="separator separator-dashed my-6"></div>
          <div className="d-flex justify-content-end align items-center">
            {formState.hasChanges && <Button title="Discard" type="reset" />}
            {formState.isValid && formState.hasChanges && (
              <Button title="Save Changes" type="submit" />
            )}
          </div>
        </React.Fragment>
      )}
    </form>
  );
};

export default CandidateForm;
