import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import JobBlocks from "./parts/job/JobBlocks";
import EntityGroupSelectionBlock from "./parts/entity/EntityGroupSelectionBlock";
import EntitySelectionBlock from "./parts/entity/EntitySelectionBlock";

import TextArea from "../../shared/FormElements/TextArea";
import Button from "../../shared/FormElements/Button";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Modal from "@mui/material/Modal";

import {
  getProfileLayout,
  getBestMatchesLayout,
} from "../../shared/util/mappings";
import * as assets from "../../assets/svgs.js";

import { validate } from "../../shared/util/validators";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const JobForm = (props) => {
  const auth = useContext(AuthContext);

  const job = props.job;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  /* ######################################################################## */

  const generateField = (value, validators = [], initialValue = value) => {
    const field = {
      initialValue,
      isValid: true,
      hasChanges: false,
      validators,
    };

    if (value !== undefined && value !== null) {
      field.value = value;
    }

    return field;
  };

  /* ######################################################################## */

  const jobBlockFields = {
    job_title: generateField(job?.title, [VALIDATOR_REQUIRE()]),
    job_status: generateField(job?.status?.id),
    job_priority: generateField(job?.priority?.id),
    job_company: generateField(job?.company?.id),
    job_owner: generateField(job?.owner?.id),
    job_hiring_managers: generateField(
      job?.hiring_managers.map((p) => p.id).join(",")
    ),
    job_team_members: generateField(
      job?.team_members.map((p) => p.id).join(",")
    ),
    job_information_employment_type: generateField(
      job?.information?.employment_type?.id
    ),
    job_information_workplace_type: generateField(
      job?.information?.workplace_type?.id
    ),
    job_information_location: generateField(job?.information?.location?.id),
    job_details: generateField(job?.details),
    job_notes: generateField(job?.notes),
  };

  /* ######################################################################## */

  const [formFields, setFormFields] = useState({
    ...jobBlockFields,
  });

  const [formState, setFormState] = useState({
    isValid: true,
    hasChanges: false,
  });

  /* ######################################################################## */

  const inputHandler = (event) => {
    const { id, value } = event.target;
    const field = formFields[id];
    let hasChanges = !(field.initialValue === value);
    let isValid = !!field.validators ? validate(value, field.validators) : true;

    setFormFields((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value: value,
        isValid: isValid,
        hasChanges: hasChanges,
      },
    }));

    setFormState((prevState) => ({
      ...prevState,
      isValid: Object.entries(formFields).every(([fieldId, fieldData]) =>
        id === fieldId ? isValid : fieldData.isValid
      ),
      hasChanges: Object.entries(formFields).some(([fieldId, fieldData]) =>
        id === fieldId ? hasChanges : fieldData.hasChanges
      ),
    }));
  };

  /* ######################################################################## */

  const submitFormHandler = async (event) => {
    event.preventDefault();

    const formData = {};
    for (const key in formFields) {
      if (formFields[key].hasChanges) {
        if (
          typeof formFields[key].value === "object" &&
          typeof formFields[key].value.type === "undefined"
        ) {
          formData[key] = formFields[key].value;
        } else {
          formData[key] = JSON.stringify(formFields[key].value);
        }
      }
    }

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/jobs/job/${job.id}`,
        "PATCH",
        JSON.stringify(formData),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      console.log("JobForm", responseData);
      clearError();

      setFormState((prevState) => ({
        ...prevState,
        isValid: true,
        hasChanges: false,
      }));
    } catch (err) {}
  };

  const resetFormHandler = (event) => {
    //event.preventDefault();
    setFormFields((prevFormFields) => {
      const resetedFormFields = Object.fromEntries(
        Object.entries(prevFormFields).map(([key, value]) => [
          key,
          {
            ...value,
            value: value.initialValue,
            isValid: true,
            hasChanges: false,
          },
        ])
      );
      return resetedFormFields;
    });
    setFormState((prevState) => ({
      ...prevState,
      isValid: true,
      hasChanges: false,
    }));
    clearError();
  };

  /* ######################################################################## */

  const [openDeleteModal, setDeleteModalOpen] = useState(false);
  const openDeleteModalHandler = function (state) {
    setDeleteModalOpen(true);
  };
  const closeDeleteModalHandler = () => setDeleteModalOpen(false);

  const history = useHistory();

  const confirmDeleteActionModalHandler = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/jobs/job/${job.id}`,
        "DELETE",
        null,
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      history.goBack();
    } catch (err) {}
    closeDeleteModalHandler();
  };

  const deleteModal = (
    <Modal
      open={openDeleteModal}
      onClose={closeDeleteModalHandler}
      aria-labelledby="staticBackdrop"
      aria-describedby="modal-modal-description"
    >
      <div
        className="modal-dialog"
        role="document"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "650px",
        }}
      >
        <div className="modal-content p-10 pe-10 bg-white border-rounded rounded-3">
          <div className="modal-header border-0 pb-4">
            <div className="d-flex">
              <div className="rpo-svg-35px rpo-fill-purple">
                {assets.svg_clipboard_close}
              </div>
              <span className="fs-1 text-gray-600 d-flex ms-4">Delete Job</span>
            </div>
            <div
              className="rpo-svg-35px rpo-fill-first-orange-other-white rpo-clickable"
              aria-label="Close"
              onClick={closeDeleteModalHandler}
            >
              {assets.svg_close_circle}
            </div>
          </div>
          <div className="modal-body pt-8">
            <span className="fs-4 fw-bolder text-gray-600">
              Are you sure you want to delete this Job?
            </span>
            <div className="d-flex justify-content-end align-items-center pt-12">
              <Button
                layout="active-2"
                title="CANCEL"
                onClick={closeDeleteModalHandler}
              />
              <Button
                title="DELETE"
                onClick={confirmDeleteActionModalHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  /* ######################################################################## */

  const [openNumberOfApplicationsModal, setNumberOfApplicationsModalOpen] =
    useState(false);
  const openNumberOfApplicationsModalHandler = function (state) {
    setNumberOfApplicationsModalOpen(true);
  };
  const closeNumberOfApplicationsModalHandler = () =>
    setNumberOfApplicationsModalOpen(false);

  const numberOfApplicationsModal = (
    <Modal
      open={openNumberOfApplicationsModal}
      onClose={closeNumberOfApplicationsModalHandler}
      aria-labelledby="staticBackdrop"
      aria-describedby="modal-modal-description"
    >
      <div
        className="modal-dialog"
        role="document"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "650px",
        }}
      >
        <div className="modal-content p-10 pe-10 bg-white border-rounded rounded-3">
          <div className="modal-header border-0 pb-4">
            <div className="d-flex">
              <div className="rpo-svg-35px rpo-fill-purple">
                {assets.svg_edit}
              </div>
              <span className="fs-1 text-gray-600 d-flex ms-4">
                Number of Applications
              </span>
            </div>
            <div
              className="rpo-svg-35px rpo-fill-first-orange-other-white rpo-clickable"
              aria-label="Close"
              onClick={closeNumberOfApplicationsModalHandler}
            >
              {assets.svg_close_circle}
            </div>
          </div>
          <div className="modal-body pt-8">
            <span className="fs-4 fw-bolder text-gray-600">
              {job.applications.length} result
              {job.applications.length > 1 ? "s" : ""}
            </span>
            <div className="row mt-10 border rounded">
              <div className="col-12">
                <div className="row  border-bottom bg-secondary">
                  <div className="col-8 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">Name</span>
                  </div>
                  <div className="col-4 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">
                      Match %
                    </span>
                  </div>
                </div>

                {job.applications.map((application, index) => {
                  return (
                    <div
                      className={`row ${
                        index + 1 < job.applications.length
                          ? "border-bottom"
                          : ""
                      }`}
                      key={index}
                    >
                      <div className="col-8 py-4 px-10">
                        {getProfileLayout(application.candidate.profile)}
                      </div>
                      <div className="col-4 py-4 px-10 d-flex align-items-center justify-content-start">
                        {getBestMatchesLayout(application.matchPercentage)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row pt-12">
              <div className="col-12 d-flex justify-content-between align-items-center p-0">
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_left}
                  title="Previous"
                />
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_right}
                  iconAfterTitle={true}
                  title="Next"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  /* ######################################################################## */

  const [openBestMatchesModal, setBestMatchesModalOpen] = useState(false);
  const openBestMatchesModalHandler = function (state) {
    setBestMatchesModalOpen(true);
  };
  const closeBestMatchesModalHandler = () => setBestMatchesModalOpen(false);

  const bestMatchesModal = (
    <Modal
      open={openBestMatchesModal}
      onClose={closeBestMatchesModalHandler}
      aria-labelledby="staticBackdrop"
      aria-describedby="modal-modal-description"
    >
      <div
        className="modal-dialog"
        role="document"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "650px",
        }}
      >
        <div className="modal-content p-10 pe-10 bg-white border-rounded rounded-3">
          <div className="modal-header border-0 pb-4">
            <div className="d-flex">
              <div className="rpo-svg-35px rpo-fill-purple">
                {assets.svg_ranking}
              </div>
              <span className="fs-1 text-gray-600 d-flex ms-4">
                Best Matches
              </span>
            </div>
            <div
              className="rpo-svg-35px rpo-fill-first-orange-other-white rpo-clickable"
              aria-label="Close"
              onClick={closeBestMatchesModalHandler}
            >
              {assets.svg_close_circle}
            </div>
          </div>
          <div className="modal-body pt-8">
            <span className="fs-4 fw-bolder text-gray-600">
              {job.matches.length} result{job.matches.length > 1 ? "s" : ""}
            </span>
            <div className="row mt-10 border rounded">
              <div className="col-12">
                <div className="row  border-bottom bg-secondary">
                  <div className="col-8 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">Name</span>
                  </div>
                  <div className="col-4 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">
                      Match %
                    </span>
                  </div>
                </div>

                {job.matches.map((match, index) => {
                  return (
                    <div
                      className={`row ${
                        index + 1 < job.matches.length ? "border-bottom" : ""
                      }`}
                      key={index}
                    >
                      <div className="col-8 py-4 px-10">
                        {getProfileLayout(match.candidate.profile)}
                      </div>
                      <div className="col-4 py-4 px-10 d-flex align-items-center justify-content-start">
                        {getBestMatchesLayout(match.matchPercentage)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row pt-12">
              <div className="col-12 d-flex justify-content-between align-items-center p-0">
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_left}
                  title="Previous"
                />
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_right}
                  iconAfterTitle={true}
                  title="Next"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  /* ######################################################################## */

  return (
    <form
      className="form w-100"
      onSubmit={submitFormHandler}
      onReset={resetFormHandler}
    >
      {isLoading && <LoadingSpinner asOverlay />}
      {error && (
        <span
          className="d-flex aling-items-center justify-content-center text-danger p-2 my-4 bg-white rounded rpo-clickable"
          onClick={resetFormHandler}
        >
          {error}
        </span>
      )}

      <div className="row">
        <div className="col-lg-12 col-xl-12 col-xxl-6 m-0 p-0 rpo-pb-32 rpo-ps-16 rpo-pe-16">
          <div className="card border mb-5 mb-xl-10">
            <div className="card-header border-0 pt-10 pb-2">
              <span className="fs-5 fw-semibold text-uppercase text-gray-600">
                Job Information
              </span>
              <div className="card-toolbar">
                <span
                  className="fs-5 text-warning rpo-clickable"
                  onClick={openDeleteModalHandler}
                >
                  Delete Job
                </span>
                {deleteModal}
              </div>
            </div>
            <div className="card-body pt-0">
              <JobBlocks formFields={formFields} inputHandler={inputHandler} />
              <div className="separator separator-solid my-6"></div>
              <div className="row">
                <div className="col-lg-6">
                  <label className="col-lg-6 fs-6 fw-semibold text-gray-600 px-4">
                    Nº of Applications:
                    <small
                      className="fs-6 fw-semibold text-warning px-2 rpo-clickable"
                      onClick={openNumberOfApplicationsModalHandler}
                    >
                      {job?.applications.length}
                    </small>
                    {numberOfApplicationsModal}
                  </label>
                </div>

                <div className="col-lg-6">
                  <label className="col-lg-6 fs-6 fw-semibold text-gray-600 px-4">
                    Matches:
                    <small
                      className="fs-6 fw-semibold text-warning px-2 rpo-clickable"
                      onClick={openBestMatchesModalHandler}
                    >
                      {job?.matches.length}
                    </small>
                    {bestMatchesModal}
                    {job?.matches.length > 0 ? (
                      <React.Fragment>
                        (
                        {Math.max(
                          ...[
                            ...new Set(
                              job.matches.map((matche) =>
                                parseFloat(matche.matchPercentage)
                              )
                            ),
                          ]
                        )}
                        % max.)
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              </div>

              <div className="separator separator-solid my-6"></div>

              <div className="row form-group">
                <div className="col-12 d-flex justify-content-start align-items-start mb-4">
                  <label className="col-lg-3 fs-6 fw-semibold text-gray-600 px-4">
                    Details
                  </label>
                  <div className="col-9">
                    <TextArea
                      id="job_details"
                      value={formFields["job_details"].value}
                      handler={inputHandler}
                      isValid={formFields["job_details"].isValid}
                      rows={Math.min(
                        12,
                        formFields["job_details"]?.value?.length
                          ? Math.round(
                              formFields["job_details"].value.length / 50
                            ) + 2
                          : 12
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="separator separator-solid my-6"></div>

              <div className="row form-group">
                <div className="col-12 d-flex justify-content-start align-items-start mb-4">
                  <label className="col-lg-3 fs-6 fw-semibold text-gray-600 px-4">
                    Notes
                  </label>
                  <div className="col-9">
                    <TextArea
                      id="job_notes"
                      value={formFields["job_notes"].value}
                      handler={inputHandler}
                      isValid={formFields["job_notes"].isValid}
                      rows={Math.min(
                        12,
                        formFields["job_notes"]?.value?.length
                          ? Math.round(
                              formFields["job_notes"].value.length / 50
                            ) + 2
                          : 6
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-xl-12 col-xxl-6 m-0 p-0 rpo-pb-32 rpo-ps-16 rpo-pe-16">
          <div className="card border mb-5 mb-xl-10">
            <div className="card-header border-0 pt-10 py-2">
              <span className="fs-5 fw-semibold text-uppercase text-gray-600">
                More Details
              </span>
            </div>

            <div className="card-body pt-0">
              <EntitySelectionBlock
                entity={job?.owner}
                formFields={formFields}
                inputId="job_owner"
                inputHandler={inputHandler}
                type="owner"
                title="Created By"
                selectionTitle="Select an Owner"
                displaySeperator={true}
              />
              <EntitySelectionBlock
                entity={job?.company}
                formFields={formFields}
                inputId="job_company"
                inputHandler={inputHandler}
                type="company"
                title="Company"
                selectionTitle="Select a Company"
                displaySeperator={true}
              />
              <EntityGroupSelectionBlock
                entities={job?.hiring_managers}
                formFields={formFields}
                inputId="job_hiring_managers"
                inputHandler={inputHandler}
                type="hiring_managers"
                title="Hiring Managers"
                selectionTitle="Select a Hiring Manager"
                displaySeperator={true}
              />
              <EntityGroupSelectionBlock
                entities={job?.team_members}
                formFields={formFields}
                inputId="job_team_members"
                inputHandler={inputHandler}
                type="team_members"
                title="Team Members"
                selectionTitle="Select a Team Member"
                displaySeperator={true}
              />
              <EntitySelectionBlock
                entity={job?.information?.location}
                formFields={formFields}
                inputId="job_information_location"
                inputHandler={inputHandler}
                type="location"
                title="Location"
                selectionTitle="Select a location"
                displaySeperator={true}
              />
            </div>
          </div>
        </div>
      </div>

      {(formState.isValid || formState.hasChanges) && (
        <React.Fragment>
          <div className="d-flex justify-content-end align items-center">
            {formState.hasChanges && <Button title="Discard" type="reset" />}
            {formState.isValid && formState.hasChanges && (
              <Button title="Save Changes" type="submit" />
            )}
          </div>
        </React.Fragment>
      )}
    </form>
  );
};

export default JobForm;
