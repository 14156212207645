import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import MainNavigation from "../../shared/Navigation/MainNavigation";
import Scrolltop from "../../shared/components/UIElements/Scrolltop";

import { Data } from "../../shared/connections/Data";
import ProfileForm from "../forms/ProfileForm";

const Profile = () => {
  const isMounted = useIsComponentMounted("Profile");
  useEffect(() => {
    document.title = "Profile";
  }, []);

  const profileId = useParams().profileId;
  const profile = Data({
    type: "profiles",
    path: "profile",
    name: profileId,
  });

  return (
    <React.Fragment>
      {isMounted && !!profile && (
        <React.Fragment>
          <div className="page d-flex flex-row flex-column-fluid">
            <div className="wrapper d-flex flex-column flex-row-fluid rpo-wrapper-fuild-padding">
              <div className="d-flex flex-column-fluid">
                <MainNavigation />
                <div className="d-flex flex-column flex-column-fluid container-fluid rpo-px-32">
                  <div className="content flex-column-fluid">
                    <div className="row g-5 g-xl-12">
                      <div className="col-12 mb-0">
                        <div className="card mb-0">
                          <div className="collapse show">
                            <div className="card-body p-9">
                              <ProfileForm profile={profile}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrolltop />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Profile;
