import React from "react";

const ClientJobsBlock = (props) => {
  let jobs = props.jobs;
  return (
    <React.Fragment>
      <div className="separator separator-dashed my-6"></div>

      <div className="row mb-6 mt-8">
        <div className="col-lg-12">
          <div className="row">
            <label className="fw-bold fs-6 pb-5">Jobs</label>
            <ul className="list-group">
              {jobs.map((job, index) => {
                return (
                  <React.Fragment key={index}>
                    <li className="list-group-item">
                      <div className="row">
                        <div className="col-4">
                          <label className="fs-6">
                            <strong>{job.title}</strong>
                          </label>
                        </div>
                        <div className="col-4">
                          <label className="fs-6">
                            Matches
                            <strong>{" (" + job.matches.length + ")"}</strong>
                          </label>
                        </div>
                        <div className="col-4">
                          <label className="fs-6">
                            best match:
                            <strong>
                              {job.matches.length > 0
                                ? " " +
                                  Math.max(
                                    ...[
                                      ...new Set(
                                        job.matches.map((match) =>
                                          parseFloat(match.match)
                                        )
                                      ),
                                    ].flatMap((match) => match)
                                  ) +
                                  "%"
                                : " n/a"}
                            </strong>
                          </label>
                        </div>
                      </div>
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClientJobsBlock;
