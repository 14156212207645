import React from "react";

import Input from "../../../../shared/FormElements/Input";
import Select from "../../../../shared/FormElements/Select";

import { Data } from "../../../../shared/connections/Data";

const CandidateStatusBlock = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const candidateStatuses = Data({ type: "fields", path: "availiable", name: "candidate_status" });

  return (
    <React.Fragment>
      <div className="separator my-6"></div>

      <div className="row form-group pe-19 mb-6">
        <div className="col-4 d-flex justify-content-start align-items-start">
          <label className="fs-6 fw-semibold text-gray-600 px-4">Title</label>
        </div>
        <div className="col-8">
          <Input
            id="latest_candidate_position_title"
            value={formFields["latest_candidate_position_title"].value}
            handler={inputHandler}
            isValid={formFields["latest_candidate_position_title"].isValid}
            className="form-control form-control-lg rpo-input fs-6 fw-normal text-gray-600 mb-3 mb-lg-0"
          />
        </div>
      </div>

      <div className="row form-group pe-19">
        <div className="col-4 d-flex justify-content-start align-items-start">
          <label className="fs-6 fw-semibold text-gray-600 px-4">Status</label>
        </div>
        <div className="col-4 pe-6">
          <Select
            name="employment_status"
            value={formFields["employment_status"].value}
            handler={inputHandler}
            defaultText="Select a status"
            options={candidateStatuses}
            isValid={formFields["employment_status"].isValid}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CandidateStatusBlock;
