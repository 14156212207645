import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import * as assets from "../../assets/svgs.js";
import { Data } from "../../shared/connections/Data";

const ActivitySummaryCard = () => {
  const isMounted = useIsComponentMounted("ActivitySummaryCard");

  const data = Data({ type: "data", name: "activity_summary" });

  const displayedSummaryData = [
    {
      title: "New candidates sourced",
      value: !!data ? data.new_candidates : "",
      icon: assets.svg_user_add,
    },
    {
      title: "Upcoming Interviews",
      value: !!data ? data.upcoming_interviews : "",
      icon: assets.svg_user_add,
    },
    {
      title: "Active jobs",
      value: !!data ? data.active_jobs : "",
      icon: assets.svg_briefcase,
    },
    {
      title: "Active Candidates",
      value: !!data ? data.active_candidates : "",
      icon: assets.svg_user,
    },
    {
      title: "Active Team Members",
      value: !!data ? data.active_team_members : "",
      icon: assets.svg_briefcase,
    },
  ];

  return (
    <React.Fragment>
      {isMounted && (
        <div className="card border">
          <div className="card-header border-0 pt-5 align-items-center">
            <span className="fs-5 text-uppercase">Activity summary</span>
          </div>
          <div className="card-body pt-0">
            <div className="d-flex justify-content-between align-items-center pt-6">
              {displayedSummaryData.map((summaryData, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="rpo-svg-35px rpo-fill-purple">
                      {summaryData.icon}
                    </div>
                    <div className="d-block">
                      <span className="fs-1 text-gray-600 fw-bold d-flex">
                        {summaryData.value}
                      </span>
                      {/* <span className="text-gray-600 d-flex"> */}
                      <span className="text-warning d-flex"> 
                        {summaryData.title}
                      </span>
                    </div>
                    {displayedSummaryData.length > index + 1 ? (
                      <div className="vr"></div>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ActivitySummaryCard;
