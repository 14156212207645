import { useState, useEffect } from "react";

import { useHttpClient } from "../../hooks/http-hook";

const Countries = () => {
  const { sendRequest } = useHttpClient();
  const [availiableCountries, setAvailableCountries] = useState();

  useEffect(() => {
    let isMounted = true;

    const fetchCountries = async () => {
      try {
        const responseData = await sendRequest(
          "https://restcountries.com/v3.1/all"
        );
        const countries = responseData.reduce((acc, country) => {
          acc.push({
            value: country.name.common,
            label: country.name.common,
          });
          return acc;
        }, []);

        if (isMounted) {
          setAvailableCountries(
            [...new Set(countries)].sort((a, b) => {
              const nameA = a.value.toUpperCase();
              const nameB = b.value.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
          );
        }
      } catch (err) {}
    };

    fetchCountries();

    return () => {
      isMounted = false;
    };
  }, [sendRequest]);

  return availiableCountries;
};

export default Countries;
