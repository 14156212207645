import { useState, useEffect, useContext } from "react";
import { useHttpClient } from "../hooks/http-hook";
import { AuthContext } from "../context/auth-context";

function formatResponse(name, response, type, path) {
  if (type === "fields" && path === "availiable") {
    return response.map((status) => {
      return {
        value: status.id,
        label: status.display.text.title,
      };
    });
  }

  return response;
}

export const Data = (props) => {
  const { sendRequest } = useHttpClient();
  const [fields, setFields] = useState();

  const auth = useContext(AuthContext);
  useEffect(() => {
    let isMounted = true;

    const fetchServerData = async () => {
      try {
        const response = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            "/" +
            props.type +
            "/" +
            (!!props.path ? props.path + "/" : "") +
            props.name +
            "/",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        if (isMounted) {
          setFields(formatResponse(props.name, response, props.type, (!!props.path?props.path:"")));
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchServerData();

    return () => {
      isMounted = false;
    };
  }, [sendRequest, auth.token, props.path, props.name, props.type]);

  return fields;
};
