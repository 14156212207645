import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import JobForm from "../forms/JobForm";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { Data } from "../../shared/connections/Data";

const JobCard = (props) => {
  const isMounted = useIsComponentMounted("JobCard");

  const job = Data({
    type: "jobs",
    path: "job",
    name: props.jobId,
  });
  /* ######################################################################## */

  return (
    <React.Fragment>
      {isMounted && (
        <div className="row">
          <div className="col-12 p-1">
            {!job && <LoadingSpinner asOverlay />}
            {job && (
              <React.Fragment>
                <JobForm job={job} />
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default JobCard;
