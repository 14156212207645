import React from "react";

import * as assets from "../../../../assets/svgs.js";

const CandidateDocumentsBlock = (props) => {
  const candidate = props.candidate;

  return (
    <React.Fragment>
      <div className="separator my-6"></div>

      <div className="row form-group pe-19 mb-6">
        <div className="col-4 d-flex justify-content-start align-items-start">
          <div className="d-flex flex-column align-items-start justify-content-center">
            <label className="fs-6 fw-semibold text-gray-600 px-4 pb-2">
              Documents
            </label>
            <span className="fs-7 text-gray-600 px-4 pb-2">
              Share a few snippets of your work.
            </span>
          </div>
        </div>
        <div className="col-8">
          {candidate.documents.length && candidate.documents.length > 0 &&
            candidate.documents.map((file, index) => {
              return (
                <div
                  key={index}
                  className="border rounded rounded-2 border-info p-3 mb-5"
                >
                  <div className="row">
                    <div className="col-1">
                      <div className="rpo-fill-transparent rpo-file-icon">
                        {assets.svg_file}
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="d-none d-md-flex flex-column align-items-start justify-content-center">
                        <span className="fs-6 text-gray-600">{file.name}</span>
                        <span className="fs-6 text-gray-600">{file.size}</span>
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="rpo-check-icon">{assets.svg_check}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">
                      <div className="d-flex align-items-center">
                        <div className="progress h-6px w-100 me-2 bg-info-light">
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{
                              width: `${file.uploaded_percentage}%`,
                            }}
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <span className="fs-6 text-gray-600">
                          {file.uploaded_percentage}%
                        </span>
                      </div>
                    </div>
                    <div className="col-1"></div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CandidateDocumentsBlock;
