import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import ClientForm from "../forms/ClientForm";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { Data } from "../../shared/connections/Data";

const ClientCard = (props) => {
  const isMounted = useIsComponentMounted("ClientCard");

  const client = Data({
    type: "clients",
    path: "client",
    name: props.clientId,
  });
  /* ######################################################################## */

  return (
    <React.Fragment>
      {isMounted && (
        <div className="card border mb-5 mb-xl-10">
          <div className="card-header border-0 pt-10 pb-7">
            <span className="fs-5 text-uppercase">Client Information</span>
          </div>

          <div className="card-body pt-0">
            {!client && <LoadingSpinner asOverlay />}
            {client && (
              <React.Fragment>
                <ClientForm client={client} />
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ClientCard;
