import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import { Data } from "../../shared/connections/Data";

import * as assets from "../../assets/svgs.js";

const TotalsOverallOverviewCard = () => {
  const isMounted = useIsComponentMounted("TotalsOverallOverviewCard");

  const data = Data({ type: "data", name: "overview_summary" });

  const displayedOverviewData = [
    {
      title: "Clients",
      value: !!data ? data.clients : "",
      icon: assets.svg_user_octagon,
    },
    {
      title: "Candidates",
      value: !!data ? data.candidates : "",
      icon: assets.svg_user,
    },
    {
      title: "Interviews",
      value: !!data ? data.interviews : "",
      icon: assets.svg_calendar,
    },
    {
      title: "Hires",
      value: !!data ? data.hires : "",
      icon: assets.svg_award,
    },
    {
      title: "Income",
      value: !!data ? data.income : "",
      icon: assets.svg_money_recive,
    },
  ];

  return (
    <React.Fragment>
      {isMounted && (
        <div className="card h-md-100 border">
          <div className="card-header border-0 pt-5 align-items-center">
            <span className="fs-5 text-uppercase">Totals overall overview</span>
          </div>
          <div className="card-body pt-0">
            <div className="d-flex justify-content-between align-items-center pt-6">
              {displayedOverviewData.map((overviewData, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="d-flex justify-content-center align-items-center col-2">
                      <div className="rpo-svg-35px rpo-fill-purple pe-6">
                        {overviewData.icon}
                      </div>
                      <div className="d-block">
                        <span className="fs-1 text-gray-600 fw-bold d-flex">
                          {overviewData.value}
                        </span>
                        <span className="fs-6 text-warning d-flex">
                          {overviewData.title}
                        </span>
                      </div>
                    </div>
                    {displayedOverviewData.length > index + 1 ? (
                      <div className="vr"></div>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TotalsOverallOverviewCard;
