import React, { useEffect, useState } from "react";

import Input from "../../../../shared/FormElements/Input";
import Button from "../../../../shared/FormElements/Button";
import Modal from "@mui/material/Modal";

import {
  getCompanyLayout,
  getProfileLayout,
} from "../../../../shared/util/mappings";
import * as assets from "../../../../assets/svgs.js";

import { Data } from "../../../../shared/connections/Data";

const EntitySelectionBlock = (props) => {
  const inputId = props.inputId;
  const hasChanges = props.formFields[inputId].hasChanges;
  const inputHandler = props.inputHandler;

  const type = props.type;
  const title = props.title;
  const selectionTitle = props.selectionTitle;

  const availiableEntities = Data({
    type: "data",
    path: "datatable",
    name: type,
  });

  /* ######################################################################## */

  const [inputSelectedValue, setInputSelectedValue] = useState();
  const [selectedEntity, setSelectedProfile] = useState();

  useEffect(() => {
    if (!hasChanges) {
      setInputSelectedValue(props.formFields[inputId].value);
      setSelectedProfile(props.entity);
    }
  }, [hasChanges, props, inputId]);

  /* ######################################################################## */

  const selectionHandler = (entity) => {
    setSelectedProfile(entity);
    setInputSelectedValue(entity.id);
    inputHandler({ target: { id: inputId, value: entity.id } });
    setOpen(false);
  };

  /* ######################################################################## */

  const [open, setOpen] = useState(false);
  const openModalHandler = function (state) {
    setOpen(true);
  };
  const closeModalHandler = () => setOpen(false);

  /* ######################################################################## */

  let entityLayout = "";
  if (!!selectedEntity) {
    switch (props.type) {
      case "company":
        entityLayout = (
          <React.Fragment>
            <div className="col-11 d-flex align-items-center justify-content-start">
              <div className="symbol symbol-30px symbol-md-40px symbol-circle mr-2">
                <img
                  src={
                    process.env.REACT_APP_ASSET_URL_PATH +
                    selectedEntity.image_url
                  }
                  alt="company"
                />
              </div>
              <div className="d-md-flex flex-column align-items-start justify-content-center ms-2 ms-md-4 rpo-clickable">
                <label className="ps-4 fs-6 fw-semibold text-gray-600">
                  {selectedEntity.name}
                </label>
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "owner":
        entityLayout = (
          <React.Fragment>
            <div className="col-11 d-flex align-items-center justify-content-start">
              <div className="symbol symbol-30px symbol-md-40px symbol-circle mr-2">
                <img
                  src={
                    process.env.REACT_APP_ASSET_URL_PATH +
                    selectedEntity.image_url
                  }
                  alt="profile"
                />
              </div>
              <div className="d-flex align-items-start justify-content-center ms-2 ms-md-4 rpo-clickable">
                <label className="ps-4 fs-6 fw-semibold text-gray-600">
                  {selectedEntity.firstname}{" "}
                </label>
                <label className="ps-4 fs-6 fw-semibold text-gray-600">
                  {selectedEntity.lastname}{" "}
                </label>
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "location":
        entityLayout = (
          <React.Fragment>
            <div className="col-11 d-flex align-items-center justify-content-start">
              <div className="d-md-flex flex-column align-items-start justify-content-center ms-md-2 rpo-clickable py-2">
                <label className="fs-6 text-gray-600">
                  {selectedEntity.address}{" "}
                  {!!selectedEntity.aptsuite
                    ? "(" + selectedEntity.aptsuite + ")"
                    : ""}
                </label>
                <label className="fs-6 text-gray-600">
                  {selectedEntity.postalcode} {selectedEntity.city},{" "}
                  {selectedEntity.stateprovince}{" "}
                </label>
              </div>
            </div>
          </React.Fragment>
        );
        break;
      default:
        entityLayout = "";
        break;
    }
  }

  /* ######################################################################## */

  let optionsLayout = "";
  let optionsIcon = "";
  let optionNumberOfResults = -1;
  if (!!availiableEntities) {
    switch (props.type) {
      case "company":
        optionNumberOfResults =
          availiableEntities.length -
          (props.formFields[inputId]?.value ? 1 : 0);
        optionsIcon = (
          <div className="rpo-svg-35px rpo-fill-purple">
            {assets.svg_building_4}
          </div>
        );
        optionsLayout = (
          <React.Fragment>
            <span className="fs-4 fw-bolder text-gray-600">
              {optionNumberOfResults} result
              {optionNumberOfResults > 1 ? "s" : ""}
            </span>
            <div className="row mt-10 border rounded">
              <div className="col-12">
                <div className="row  border-bottom bg-secondary">
                  <div className="col-12 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">
                      Company
                    </span>
                  </div>
                </div>
                {availiableEntities.map((company, index) => {
                  if (company.id !== props.formFields[inputId].value)
                    return (
                      <div
                        className={`row ${
                          index + 1 < availiableEntities.length
                            ? "border-bottom"
                            : ""
                        } rpo-clickable`}
                        onClick={() => selectionHandler(company)}
                        key={index}
                      >
                        <div className="col-12 py-4 px-10">
                          {getCompanyLayout(company)}
                        </div>
                      </div>
                    );
                  else {
                    return "";
                  }
                })}
              </div>
            </div>
            <div className="row pt-12">
              <div className="col-12 d-flex justify-content-between align-items-center p-0">
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_left}
                  title="Previous"
                />
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_right}
                  iconAfterTitle={true}
                  title="Next"
                />
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "owner":
        optionNumberOfResults =
          availiableEntities.length -
          (props.formFields[inputId]?.value ? 1 : 0);
        optionsIcon = (
          <div className="rpo-svg-35px rpo-fill-purple">
            {assets.svg_user_octagon}
          </div>
        );
        optionsLayout = (
          <React.Fragment>
            <span className="fs-4 fw-bolder text-gray-600">
              {optionNumberOfResults} result
              {optionNumberOfResults > 1 ? "s" : ""}
            </span>
            <div className="row mt-10 border rounded">
              <div className="col-12">
                <div className="row  border-bottom bg-secondary">
                  <div className="col-8 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">Name</span>
                  </div>
                  <div className="col-4 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">Role</span>
                  </div>
                </div>
                {availiableEntities.map((profile, index) => {
                  if (profile.id !== props.formFields[inputId].value)
                    return (
                      <div
                        className={`row ${
                          index + 1 < availiableEntities.length
                            ? "border-bottom"
                            : ""
                        } rpo-clickable`}
                        onClick={() => selectionHandler(profile)}
                        key={index}
                      >
                        <div className="col-8 py-4 px-10">
                          {getProfileLayout(profile)}
                        </div>
                        <div className="col-4 py-4 px-10 d-flex align-items-center justify-content-start">
                          <span className="fs-6 text-gray-600">
                            {profile?.role}
                          </span>
                        </div>
                      </div>
                    );
                  else {
                    return "";
                  }
                })}
              </div>
            </div>
            <div className="row pt-12">
              <div className="col-12 d-flex justify-content-between align-items-center p-0">
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_left}
                  title="Previous"
                />
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_right}
                  iconAfterTitle={true}
                  title="Next"
                />
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "location":
        optionNumberOfResults =
          availiableEntities.length -
          (props.formFields[inputId]?.value ? 1 : 0);
        optionsLayout = (
          <React.Fragment>
            <span className="fs-4 fw-bolder text-gray-600">
              {optionNumberOfResults} result
              {optionNumberOfResults > 1 ? "s" : ""}
            </span>
            <div className="row mt-10 border rounded">
              <div className="col-12">
                <div className="row  border-bottom bg-secondary">
                  <div className="col-12 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">
                      Location
                    </span>
                  </div>
                </div>
                {availiableEntities.map((location, index) => {
                  if (location.id !== props.formFields[inputId].value)
                    return (
                      <div
                        className={`row ${
                          index + 1 < availiableEntities.length
                            ? "border-bottom"
                            : ""
                        } rpo-clickable`}
                        onClick={() => selectionHandler(location)}
                        key={index}
                      >
                        <div className="col-12 py-4 px-10">
                          <label className="fs-6 text-gray-600 rpo-clickable">
                            {location.address}{" "}
                            {!!location.aptsuite
                              ? "(" + location.aptsuite + ")"
                              : ""}
                          </label>
                          <label className="fs-6 text-gray-600 rpo-clickable">
                            {location.postalcode} {location.city},{" "}
                            {location.stateprovince}{" "}
                          </label>
                        </div>
                      </div>
                    );
                  else {
                    return "";
                  }
                })}
              </div>
            </div>
            <div className="row pt-12">
              <div className="col-12 d-flex justify-content-between align-items-center p-0">
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_left}
                  title="Previous"
                />
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_right}
                  iconAfterTitle={true}
                  title="Next"
                />
              </div>
            </div>
          </React.Fragment>
        );
        break;
      default:
        optionsLayout = "";
        break;
    }
  }

  const optionsModal = (
    <Modal
      open={open}
      onClose={closeModalHandler}
      aria-labelledby="staticBackdrop"
      aria-describedby="modal-modal-description"
    >
      <div
        className="modal-dialog"
        role="document"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "650px",
        }}
      >
        <div className="modal-content p-10 pe-10 bg-white border-rounded rounded-3">
          <div className="modal-header border-0 pb-4">
            <div className="d-flex">
              {optionsIcon}
              <span className="fs-1 text-gray-600 d-flex ms-4">
                {selectionTitle}
              </span>
            </div>
            <div
              className="rpo-svg-35px rpo-fill-first-orange-other-white rpo-clickable"
              aria-label="Close"
              onClick={closeModalHandler}
            >
              {assets.svg_close_circle}
            </div>
          </div>

          <div className="modal-body pt-8">{optionsLayout}</div>
        </div>
      </div>
    </Modal>
  );

  /* ######################################################################## */

  return (
    <React.Fragment>
      {props?.displaySeperator && (
        <div className="separator separator-solid my-6"></div>
      )}
      <div className="row mb-8">
        <label className="col-lg-5 fs-6 fw-semibold text-gray-600 px-4 d-flex justify-content-start align-items-center">
          {title}
          <Input
            id={inputId}
            value={inputSelectedValue}
            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 d-none"
          />
        </label>
        <div className="col-lg-7">
          <div className="row rounded p-2 my-2">
            {entityLayout}
            <div className="col-1 d-flex align-items-center justify-content-end">
              <div
                className="rpo-svg-20px rpo-fill-white rpo-stroke-path-orange rpo-clickable"
                onClick={openModalHandler}
              >
                {assets.svg_edit_pencil}
              </div>
            </div>
          </div>
        </div>
      </div>
      {optionsModal}
    </React.Fragment>
  );
};

export default EntitySelectionBlock;
