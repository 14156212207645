import React from 'react';

// import logo from "../../../assets/media/RPO_Logo_150x167.png";
import logo from "../../../assets/media/rpo_logo_555x555.png";

const Logo = props => {
  return (
    <img alt="Logo" style={{ maxHeight : '200px' }} src={logo} className={props.className}/>
  );
};

export default Logo;
