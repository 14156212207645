import React from "react";
import Checkbox from "../../../../shared/FormElements/Checkbox";

const ProfileFlagsBlock = (props) => {

    const formFields = props.formFields;
    const checkboxHandler = props.checkboxHandler;

    return (
        <React.Fragment>
          <div className="separator separator-dashed my-6"></div>
    
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
              Communication
            </label>
            <div className="col-lg-8 fv-row">
              <div className="d-flex align-items-center mt-3">
                <Checkbox
                  name="profile_contactInformation_communication_email"
                  value={formFields["profile_contactInformation_communication_email"].value}
                  handler={checkboxHandler}
                  isValid={formFields["profile_contactInformation_communication_email"].isValid}
                  label="Email"
                />
                <Checkbox
                  name="profile_contactInformation_communication_phone"
                  value={formFields["profile_contactInformation_communication_phone"].value}
                  handler={checkboxHandler}
                  isValid={formFields["profile_contactInformation_communication_phone"].isValid}
                  label="Phone"
                />
              </div>
            </div>
          </div>
    
          <div className="row mb-0">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">
              Allow email Notifications
            </label>
            <div className="col-lg-8 d-flex align-items-center">
              <Checkbox
                layout="switch"
                name="profile_allowemailnotifications"
                value={formFields["profile_allowemailnotifications"].value}
                handler={checkboxHandler}
                isValid={formFields["profile_allowemailnotifications"].isValid}
              />
            </div>
          </div>
        </React.Fragment>
      );
};

export default ProfileFlagsBlock;
