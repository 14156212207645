import React from "react";
import { Link } from "react-router-dom";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import { Data } from "../../shared/connections/Data";

const VisitsByCountryCard = () => {
  const isMounted = useIsComponentMounted("VisitsByCountryCard");

  const data = Data({ type: "data", name: "assigned_team_members" });
  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          {!!data && (
            <div className="card border h-100">
              <div className="card-header border-0 pt-5 align-items-center">
                <span className="fs-5 text-uppercase">
                  Team members assigned
                </span>
                <div className="card-toolbar">
                  <Link to={`/jobs`} className=" fs-5 text-warning">
                    See all assigned
                  </Link>
                </div>
              </div>
              <div className="card-body">
                {!!data &&
                  data.map((profile, index) => {
                    return (
                      <Link
                        to={`/profile/${profile?.id}`}
                        key={index}
                        className="row px-1 py-4"
                      >
                        <div className="col-10 d-flex justify-content-start align-items-center">
                          <div className="symbol symbol-30px symbol-md-40px symbol-circle mr-2">
                            <img
                              src={
                                process.env.REACT_APP_ASSET_URL_PATH +
                                profile?.image_url
                              }
                              alt="profile"
                            />
                          </div>
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2 ps-2">
                            <div className="me-5">
                              <span className="text-gray-800 fw-bold text-hover-primary fs-6">
                                {profile?.firstname} {profile?.lastname}
                              </span>
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                {profile?.contactInformation?.email}
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="text-gray-800 fw-semibold fs-6 me-3 d-block">
                                {profile?.title}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-2 d-flex justify-content-end align-items-center">
                          <div className="symbol symbol-30px symbol-md-40px symbol-circle mr-2 bg-dark">
                            <img
                              src={
                                process.env.REACT_APP_ASSET_URL_PATH +
                                profile?.company?.image_url
                              }
                              alt="profile"
                            />
                          </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default VisitsByCountryCard;
