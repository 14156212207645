import React from "react";
import ReactApexChart from "react-apexcharts";

const AreaChart = (props) => {
  const color = props.color;

  const labelColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-gray-800");
  const strokeColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-gray-300");
  const baseColor = getComputedStyle(document.documentElement).getPropertyValue(
    "--bs-" + color
  );
  const lightColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-" + color + "-light");

  const options = {
    series: props.series,
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: props.height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: props.categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColor: [baseColor],
      strokeWidth: 3,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={options.series}
      type={options.chart.type}
      height={options.chart.height}
    />
  );
};

export default AreaChart;
