import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import * as assets from "../../assets/svgs.js";
import { Data } from "../../shared/connections/Data";

const StatsCard = () => {
  const isMounted = useIsComponentMounted("StatsCard");

  const data = Data({ type: "data", name: "stats" });

  const displayedSummaryData = [
    {
      title: "New candidates sourced",
      value: !!data ? data.new_candidates : "",
      icon: assets.svg_user_add,
    },
    {
        title: "Candidates contacted",
        value: !!data ? data.candidates_contacted : "",
        icon: assets.svg_user_add,
    },
    {
        title: "Emails sent",
        value: !!data ? data.emails_sent : "",
        icon: assets.svg_user_add,
    },
    {
        title: "Candidates curently active",
        value: !!data ? data.active_candidates : "",
        icon: assets.svg_user_add,
    },
    {
        title: "Candidates responded",
        value: !!data ? data.responded_candidates : "",
        icon: assets.svg_user_add,
    },
    {
        title: "Candidates converted",
        value: !!data ? data.converted_candidates : "",
        icon: assets.svg_user_add,
    },
    {
        title: "Candidates scheduled",
        value: !!data ? data.scheduled_candidates : "",
        icon: assets.svg_user_add,
    },
    {
        title: "Candidates in final interview",
        value: !!data ? data.final_interview_candidates : "",
        icon: assets.svg_user_add,
    },
    {
        title: "Offers",
        value: !!data ? data.offers : "",
        icon: assets.svg_user_add,
    },
    {
        title: "Hires",
        value: !!data ? data.hires : "",
        icon: assets.svg_user_add,
    },

  ];

  return (
    <React.Fragment>
      {isMounted && (
        <div className="card border">
          <div className="card-header border-0 pt-5 align-items-center">
            <span className="fs-5 text-uppercase">Stats</span>
          </div>
          <div className="card-body pt-0">
            <div className="d-flex justify-content-between align-items-center pt-6" style={{overflow : "scroll"}}>
              {displayedSummaryData.map((summaryData, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="d-flex justify-content-between align-items-center pt-6 stats-item">
                        <div className="rpo-svg-35px rpo-fill-purple ps-4 pe-4">
                        {summaryData.icon}
                        </div>
                        <div className="d-block pe-4">
                        <span className="fs-1 text-gray-600 fw-bold d-flex">
                            {summaryData.value}
                        </span>
                        {/* <span className="text-gray-600 d-flex"> */}
                        <span className="text-warning d-flex text-nowrap"> 
                            {summaryData.title}
                        </span>
                        </div>
                        {displayedSummaryData.length > index + 1 ? (
                        <div className="vr"></div>
                        ) : (
                        ""
                        )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default StatsCard;
