import React, { useState } from "react";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimeClock } from "@mui/x-date-pickers/TimeClock";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

const EventTimeBlocks = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  /* ######################################################################## */
  const [eventDate, setEventDate] = useState(
    dayjs(formFields["event_date"].value)
  );
  const [eventDuration, setEventDuration] = useState(
    dayjs(formFields["event_duration"].value)
  );
  const eventEndDateTime = new Date(
    formFields["event_endDateTime"].value
  ).toLocaleString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  /* ######################################################################## */

  const dateTimeHandler = (newValue) => {
    inputHandler({
      target: {
        id: "event_date",
        value: newValue
          .toDate()
          .toISOString()
          .replace("Z", newValue.format("Z")),
      },
    });
    setEventDate(newValue);
  };

  const timeHandler = (newValue) => {
    inputHandler({
      target: {
        id: "event_duration",
        value: newValue.toDate().getMinutes(),
      },
    });
    setEventDuration(newValue);
  };

  /* ######################################################################## */

  return (
    <React.Fragment>
      <div className="row mb-6">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="col-6 mt-8">
            <DateTimePicker
              label="Date"
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              value={eventDate}
              onChange={(newValue) => dateTimeHandler(newValue)}
              className="form-control form-control-lg rpo-input fs-6 fw-normal text-gray-600 mb-3 mb-lg-0"
            />
            <div className="separator separator-dashed my-6"></div>
            <label className="fs-6 fw-semibold text-gray-600">
              End time{" "}
              <small className="text-warning">{eventEndDateTime}</small>
            </label>
          </div>
          <div className="col-6 mt-8 text-center">
          <label className="fs-6 fw-semibold text-gray-600 pb-4">Duration <small className="text-warning">(in minutes)</small></label>
            <TimeClock
              views={["minutes"]}
              value={eventDuration}
              onChange={(newValue) => timeHandler(newValue)}
            />
          </div>
        </LocalizationProvider>
        <div className="separator separator-solid my-6"></div>
      </div>
    </React.Fragment>
  );
};

export default EventTimeBlocks;
