import React from "react";
import Input from "../../../../shared/FormElements/Input";
import Map from "../../../../shared/components/UIElements/Map";

const ProfileLocationBlock = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const getInput = (id) => (
    <Input
      id={id}
      value={formFields[id].value}
      handler={inputHandler}
      isValid={formFields[id].isValid}
      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
    />
  );

  const blockElements = [
    {
      title: "Address",
      element: getInput("profile_contactInformation_location_address"),
    },
    {
      title: "Apt, Suite",
      element: getInput("profile_contactInformation_location_aptsuite"),
    },
    {
      title: "City",
      element: getInput("profile_contactInformation_location_city"),
    },
    {
      title: "State",
      element: getInput("profile_contactInformation_location_stateprovince"),
    },
    {
      title: "Postal Code",
      element: getInput("profile_contactInformation_location_postalcode"),
    },
    {
      title: "Country",
      element: getInput("profile_contactInformation_location_country"),
    },
  ];

  let selectedCoordinates =
    formFields["profile_contactInformation_location_coordinates"].value;
  if (
    typeof formFields["profile_contactInformation_location_coordinates"]
      .value === "string"
  ) {
    const [lat, lng] = selectedCoordinates.split(",").map(parseFloat);

    // Formatting into the desired format
    selectedCoordinates = {
      lat: lat,
      lng: lng,
    };
  }

  return (
    <React.Fragment>
      <div className="separator separator-dashed my-6"></div>

      <div className="row mb-6">
        <div className="col-lg-8 fv-row">
          {blockElements.map((blockElement, index) => {
            return (
              <div
                key={index}
                className={`row ${index < blockElements.length ? "mb-6" : ""}`}
              >
                <label className="col-lg-6 col-form-label fw-semibold fs-6">
                  {blockElement.title}
                </label>
                <div className="col-lg-6 fv-row">{blockElement.element}</div>
              </div>
            );
          })}
        </div>

        <div className="col-lg-4 fv-row">
          <Input
            id="profile_contactInformation_location_coordinates"
            value={selectedCoordinates}
            handler={inputHandler}
            isValid={
              formFields["profile_contactInformation_location_coordinates"]
                .isValid
            }
            className="d-none"
          />
          <Map
            mapId="profile-map"
            className="w-100 h-100 border rounded shadow"
            center={selectedCoordinates}
            zoom={10}
            hasAutoCompleteForm={true}
            autocompleteFields={{
              route: "profile_contactInformation_location_address",
              subpremise: "profile_contactInformation_location_aptsuite",
              locality: "profile_contactInformation_location_city",
              administrative_area_level_1:
                "profile_contactInformation_location_stateprovince",
              country: "profile_contactInformation_location_country",
              postal_code: "profile_contactInformation_location_postalcode",
              coordinates: "profile_contactInformation_location_coordinates",
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileLocationBlock;
