import React from "react";

import Input from "../../../../shared/FormElements/Input";
import ImageUpload from "../../../../shared/FormElements/ImageUpload";

const ProfileBlock = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const getInput = (id) => (
    <Input
      id={id}
      value={formFields[id].value}
      handler={inputHandler}
      isValid={formFields[id].isValid}
      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
    />
  );

  const blockElements = [
    { title: "First Name", element: getInput("profile_firstname") },
    { title: "Last Name", element: getInput("profile_lastname") },
    { title: "Display Name", element: getInput("profile_displayname") },
  ];

  return (
    <React.Fragment>
      <div className="separator separator-dashed my-6"></div>
      <div className="row mb-6">
        <div className="col-lg-8 fv-row mt-8">
          {blockElements.map((blockElement, index) => {
            return (
              <div key={index} className="row mb-8">
                <label className="col-lg-6 col-form-label fw-semibold fs-6">
                  {blockElement.title}
                </label>
                <div className="col-lg-6 fv-row">{blockElement.element}</div>
              </div>
            );
          })}
        </div>
        <div className="col-lg-4 fv-row d-flex align-items-center justify-content-center">
          <ImageUpload
            id="image"
            inputHandler={inputHandler}
            value={formFields["image"].value}
            initialValue={formFields["image"].initialValue}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileBlock;
