import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import RadialBarChart from "../../shared/charts/RadialBarChart";
import { Data } from "../../shared/connections/Data";

function ClientConversionsCard() {
  const isMounted = useIsComponentMounted("ClientConversionsCard");

  const data = Data({
    type: "data",
    path: "chart",
    name: "client_conversion_score",
  });

  return (
    <React.Fragment>
      {isMounted && (
        <div className="card card-flush h-xl-100 border">
          <div className="card-header pt-7">
            <span className="fs-5 text-uppercase">Client Conversions</span>
            <div className="card-toolbar">
              <a href="/" className=" fs-5 text-warning">
                See details
              </a>
            </div>
          </div>
          <div className="card-body pt-5 mh-275px overflow-hidden">
            <div
              id="clientConversionsWidget"
              className="w-100 h-400px clientConversionsWidget"
            >
              {!!data && (
                <RadialBarChart
                  labels={data.labels}
                  series={data.series}
                  height="400"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
export default ClientConversionsCard;
