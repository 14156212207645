import React, { useState, useContext } from "react";

import CompanyBlocks from "./parts/company/CompanyBlocks";

import Button from "../../shared/FormElements/Button";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { validate } from "../../shared/util/validators";
import { VALIDATOR_WEBSITE } from "../../shared/util/validators";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const CompanyForm = (props) => {
  const auth = useContext(AuthContext);

  const company = props.company;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  /* ######################################################################## */

  const generateField = (value, validators = [], initialValue = value) => {
    const field = {
      initialValue,
      isValid: true,
      hasChanges: false,
      validators,
    };

    if (value !== undefined && value !== null) {
      field.value = value;
    }

    return field;
  };

  /* ######################################################################## */

  const companyBlockFields = {
    company_name: generateField(company?.name),
    company_market: generateField(company?.market),
    company_website: generateField(company?.website, [VALIDATOR_WEBSITE()]),
    image: generateField(company?.image_url),
  };

  /* ######################################################################## */

  const [formFields, setFormFields] = useState({
    ...companyBlockFields,
  });

  const [formState, setFormState] = useState({
    isValid: true,
    hasChanges: false,
  });

  /* ######################################################################## */

  const inputHandler = (event) => {
    const { id, value } = event.target;
    const field = formFields[id];
    let hasChanges = !(field.initialValue === value);
    let isValid = !!field.validators ? validate(value, field.validators) : true;

    setFormFields((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value: value,
        isValid: isValid,
        hasChanges: hasChanges,
      },
    }));

    setFormState((prevState) => ({
      ...prevState,
      isValid: Object.entries(formFields).every(([fieldId, fieldData]) =>
        id === fieldId ? isValid : fieldData.isValid
      ),
      hasChanges: Object.entries(formFields).some(([fieldId, fieldData]) =>
        id === fieldId ? hasChanges : fieldData.hasChanges
      ),
    }));
  };

  /* ######################################################################## */

  const submitFormHandler = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    for (const key in formFields) {
      if (
        typeof formFields[key].value === "object" &&
        typeof formFields[key].value.type === "undefined"
      ) {
        formData.append(key, JSON.stringify(formFields[key].value));
      } else {
        formData.append(key, formFields[key].value);
      }
    }

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/companies/company/${company.id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      console.log('CompanyForm',responseData);
      clearError();

      setFormState((prevState) => ({
        ...prevState,
        isValid: true,
        hasChanges: false,
      }));
    } catch (err) {}
  };

  const resetFormHandler = (event) => {
    //event.preventDefault();
    setFormFields((prevFormFields) => {
      const resetedFormFields = Object.fromEntries(
        Object.entries(prevFormFields).map(([key, value]) => [
          key,
          {
            ...value,
            value: value.initialValue,
            isValid: true,
            hasChanges: false,
          },
        ])
      );
      return resetedFormFields;
    });
    setFormState((prevState) => ({
      ...prevState,
      isValid: true,
      hasChanges: false,
    }));
    clearError();
  };

  /* ######################################################################## */

  return (
    <form
      className="form w-100"
      onSubmit={submitFormHandler}
      onReset={resetFormHandler}
    >
      {isLoading && <LoadingSpinner asOverlay />}
      {error && (
        <span
          className="d-flex aling-items-center justify-content-center text-danger p-2 my-4 bg-white rounded rpo-clickable"
          onClick={resetFormHandler}
        >
          {error}
        </span>
      )}

      <CompanyBlocks formFields={formFields} inputHandler={inputHandler} />

      {(formState.isValid || formState.hasChanges) && (
        <React.Fragment>
          <div className="separator separator-dashed my-6"></div>
          <div className="d-flex justify-content-end align items-center">
            {formState.hasChanges && <Button title="Discard" type="reset" />}
            {formState.isValid && formState.hasChanges && (
              <Button title="Save Changes" type="submit" />
            )}
          </div>
        </React.Fragment>
      )}
    </form>
  );
};

export default CompanyForm;
