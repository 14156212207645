import React, { useEffect } from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import MainNavigation from "../../shared/Navigation/MainNavigation";
import DashboardTop from "../parts/DashboardTop";
import Scrolltop from "../../shared/components/UIElements/Scrolltop";

const Reports = () => {
  const isMounted = useIsComponentMounted("Reports");
  useEffect(() => {
    document.title = "Reports";
  }, []);

  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          <div className="page d-flex flex-row flex-column-fluid">
            <div className="wrapper d-flex flex-column flex-row-fluid rpo-wrapper-fuild-padding">
              <div className="d-flex flex-column-fluid">
                <MainNavigation />
                <div className="d-flex flex-column flex-column-fluid container-fluid rpo-px-32">
                  <div className="content flex-column-fluid">
                    <div className="row m-0 p-0">
                      <div className="col-12 m-0 p-0 rpo-pb-32">
                        <DashboardTop />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrolltop />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Reports;
