import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import ReactApexChart from "react-apexcharts";
import * as assets from "../../assets/svgs.js";
import { Data } from "../../shared/connections/Data";

function CandidatesActiveWidget(props) {
  const isMounted = useIsComponentMounted("CandidatesActiveWidget");

  const data = Data({ type: "data", path: "chart", name: "active_candidates" });
  let options = null;

  if (!!data) {
    let colorOptions = [
      "--rpo-dark-purple",
      "--rpo-purple-2",
      "--rpo-purple-3",
    ];
    let colors = [];
    for (let i = 0; i < data.series.length; i++) {
      let colorIndex = i % colorOptions.length;
      colors.push(colorOptions[colorIndex]);
    }

    options = {
      series: data.series,
      labels: data.labels,
      chart: {
        fontFamily: "inherit",
        type: "donut",
        height: 350,
        offsetY: 0,
      },
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            size: "50%",
            labels: {
              show: true,
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: colors.map((color) =>
        getComputedStyle(document.documentElement).getPropertyValue(color)
      ),
    };
  }

  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          <div className="d-flex justify-content-between align-items-center border-bottom">
            <span className="fs-6 fw-semibold p-5">Active candidates</span>
            <div className="rpo-svg-25px rpo-fill-orange rpo-stroke-path-orange pe-2 rpo-clickable">
              {assets.svg_more_vertical}
            </div>
          </div>
          {!!data && !!options && (
            <ReactApexChart
              options={options}
              series={options.series}
              type={options.chart.type}
              height={options.chart.height}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default CandidatesActiveWidget;
