import React from "react";

import Logo from "../../shared/components/UIElements/Logo";

const Landing = () => {
  return (
    <div className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center bg-rpo">
        <a href="/auth" className="center">
          <Logo/>
        </a>
    </div>
  );
};

export default Landing;
