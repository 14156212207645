import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import Button from "../../shared/FormElements/Button";
import * as assets from "../../assets/svgs.js";

function UserQuickActionsCard(props) {
  const isMounted = useIsComponentMounted("UserQuickActionsCard");

  return (
    <React.Fragment>
      {isMounted && (
        <div className="card border">
          <div className="card-header border-0 pt-5 align-items-center">
            <span className="fs-5 text-uppercase">Quick Actions</span>
          </div>
          <div className="card-body pt-1">
            <div className="d-flex-row-fluid">
              <Button
                icon={assets.svg_sms_tracking}
                title="Send Email"
                ClassName="mt-4"
              />
              <Button
                icon={assets.svg_calendar_edit}
                title="Schedule Interview"
                ClassName="mt-4"
              />
              <Button
                icon={assets.svg_message_text}
                title="Message"
                ClassName="mt-4"
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
export default UserQuickActionsCard;
