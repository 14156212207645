import React from "react";

import Input from "../../../../shared/FormElements/Input";

const ProfileSocialBlock = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const getInput = (id, placeholder) => (
    <Input
      id={id}
      value={formFields[id].value}
      handler={inputHandler}
      isValid={formFields[id].isValid}
      placeholder={placeholder}
      className="form-control form-control-lg rpo-input rpo-input-icon rpo-input-icon-globe fs-6 fw-normal text-warning mb-3 mb-lg-0 me-6"
    />
  );

  const blockElements = [
    {
      title: "LinkedIn",
      element: getInput(
        "profile_contactInformation_social_linkedin",
        "LinkedIn"
      ),
    },
    {
      title: "Portfolio",
      element: getInput(
        "profile_contactInformation_social_website",
        "Portfolio"
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="separator separator-dashed my-6"></div>

      <div className="row mb-6">
        <label className="col-lg-4 col-form-label fw-bold fs-6">Social</label>
        <div className="col-lg-8">
          <div className="row">
            {blockElements.map((blockElement, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="col-lg-6 fv-row">{blockElement.element}</div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileSocialBlock;
