import React from "react";
import Input from "../../../../shared/FormElements/Input";

const CompanyRowBlock = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const getInput = (id) => (
    <Input
      id={id}
      value={formFields[id].value}
      handler={inputHandler}
      isValid={formFields[id].isValid}
      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
    />
  );

  const blockElements = [
    { title: "Name", element: getInput("company_name") },
    { title: "Website", element: getInput("company_website") },
  ];

  return (
    <React.Fragment>
      <div className="separator separator-dashed my-6"></div>

      <div className="row mb-6">
        <label className="col-lg-4 col-form-label required fw-bold fs-6">
          Company Information
        </label>
        <div className="col-lg-8">
          <div className="row">
            {blockElements.map((blockElement, index) => {
              return (
                <div className="col-lg-6 fv-row" key={index}>
                  {blockElement.element}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompanyRowBlock;
