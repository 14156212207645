import React, { useRef, useState, useEffect } from "react";

import Badge from "@mui/material/Badge";
import * as assets from "../../assets/svgs.js";

const ImageUpload = (props) => {
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  /* ######################################################################## */

  const filePickerRef = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  const pickedHandler = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);

      props.inputHandler({
        target: {
          id: event.target.id,
          value: pickedFile,
        },
      });
    }
  };

  const removeImageHandler = (event) => {
    setPreviewUrl(props.initialValue);
    setFile(null);
    props.inputHandler({
      target: {
        id: props.id,
        value: props.initialValue,
      },
    });
  };

  /* ######################################################################## */

  const imageElement = (
    <React.Fragment>
      <input
        id={props.id}
        className="d-none"
        ref={filePickerRef}
        type="file"
        accept=".jpg,.png,.jpeg"
        onChange={pickedHandler}
      />
      {props.initialValue === undefined ? (
        <label className="w-100 h-100 d-block bg-warning text-white fw-bold text-center border rounded p-8 rpo-clickable" onClick={pickImageHandler}>Select an image</label>
      ) : (
        <img
          src={
            !!previewUrl
              ? props.value !== props.initialValue
                ? previewUrl
                : process.env.REACT_APP_ASSET_URL_PATH + props.initialValue
              : process.env.REACT_APP_ASSET_URL_PATH + props.value
          }
          className="w-100 rounded"
          alt="Preview"
        />
      )}
    </React.Fragment>
  );

  /* ######################################################################## */

  return (
    <Badge
      className="rpo-fill-orange rpo-clickable"
      badgeContent={
        <div onClick={pickImageHandler}>{assets.svg_edit_pencil}</div>
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      sx={{ position: "relative", width: "38%", height: "auto" }}
    >
      {imageElement}
      {props.value !== props.initialValue && (
        <Badge
          className="rpo-fill-first-orange-other-white rpo-clickable"
          badgeContent={
            <div onClick={removeImageHandler}>{assets.svg_close_circle}</div>
          }
          overlap="circular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        ></Badge>
      )}
    </Badge>
  );
};

export default ImageUpload;
