import React from "react";

import Input from "../../../../shared/FormElements/Input";

const ClientBlock = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const getInput = (id) => (
    <Input
      id={id}
      value={formFields[id].value}
      handler={inputHandler}
      isValid={formFields[id].isValid}
      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
    />
  );

  const blockElements = [
    { title: "Title", element: getInput("title") },
    { title: "Income", element: getInput("income") },
  ];

  return (
    <React.Fragment>
      <div className="separator separator-dashed my-6"></div>

      <div className="row mb-6 mt-8">
        <div className="col-lg-12">
          <div className="row">
            <label className="required fw-bold fs-6 pb-5">
              Client Information
            </label>
            {blockElements.map((blockElement, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="row form-group pe-19 mb-6">
                    <div className="col-4 d-flex justify-content-start align-items-center">
                      <label className="fs-6 fw-semibold text-gray-600 px-4">
                        {blockElement.title}
                      </label>
                    </div>
                    <div className="col-8">{blockElement.element}</div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClientBlock;
