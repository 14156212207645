import React, { useEffect } from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import MainNavigation from "../../shared/Navigation/MainNavigation";
import DashboardTop from "../parts/DashboardTop";
import Scrolltop from "../../shared/components/UIElements/Scrolltop";

import ActivitySummaryCard from "../cards/ActivitySummaryCard";
import DatatableCard from "../cards/DatatableCard";
import VisitsByCountryCard from "../cards/VisitsByCountryCard";
import CandidatesConversionCard from "../cards/CandidatesConversionCard";
import UserEventsCard from "../cards/UserEventsCard";
import SourcesCandidatePercentageCard from "../cards/SourcesCandidatePercentageCard";

import StatsCard from "../cards/StatsCard";

const Dashboard = () => {
  const isMounted = useIsComponentMounted("Dashboard");
  useEffect(() => {
    document.title = "Overview";
  }, []);

  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          <div className="page d-flex flex-row flex-column-fluid">
            <div className="wrapper d-flex flex-column flex-row-fluid rpo-wrapper-fuild-padding">
              <div className="d-flex flex-column-fluid">
                <MainNavigation />
                <div className="d-flex flex-column flex-column-fluid container-fluid rpo-px-32">
                  <div className="content flex-column-fluid">
                    <div className="row m-0 p-0">
                      <div className="col-12 m-0 p-0 rpo-pb-32">
                        <DashboardTop />
                      </div>
                    </div>

                    <div className="row m-0 p-0">
                      <div className="col-lg-12 col-xl-12 col-xxl-9 m-0 p-0 rpo-pe-32">
                        <div className="row m-0 p-0">
                          <div className="col-lg-12 m-0 p-0 rpo-pb-32">
                            <ActivitySummaryCard />
                          </div>
                        </div>

                        <div className="row m-0 p-0">
                          <div className="col-lg-12 m-0 p-0 rpo-pb-32">
                            <DatatableCard
                              title="Active Jobs"
                              toolbarLink={{
                                url: "/jobs",
                                title: "See all jobs",
                              }}
                              datatableEntity="activejob"
                              datatableColumns={[
                                "Job Title",
                                "Hiring Manager",
                                "Assigned Team Members",
                                "Contacted",
                                "Active Candidates",
                                "Priority",
                              ]}
                            />
                          </div>
                        </div>

                        <div className="row m-0 p-0">
                          <div className="col-lg-12 col-xl-12 col-xxl-6 m-0 p-0 rpo-pb-32 rpo-pe-16">
                            <VisitsByCountryCard />
                          </div>
                          <div className="col-lg-12 col-xl-12 col-xxl-6 m-0 p-0 rpo-pb-32 rpo-ps-16">
                            <CandidatesConversionCard />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-xl-12 col-xxl-3 m-0 p-0">
                        <div className="row m-0 p-0">
                          <div className="col-lg-12 col-xl-12 m-0 p-0 rpo-pb-32">
                            <UserEventsCard limit={3} />
                          </div>
                        </div>

                        <div className="row m-0 p-0">
                          <div className="col-lg-12 col-xl-12 m-0 p-0 rpo-pb-32">
                            <SourcesCandidatePercentageCard />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row m-0 p-0">
                      <div className="col-lg-12 col-xl-12 col-xxl-12 m-0 p-0 rpo-pb-32">
                        <DatatableCard
                          title="Candidates"
                          badgeText="Users"
                          toolbarLink={{
                            url: "/candidates",
                            title: "See all candidates",
                          }}
                          datatableEntity="candidate"
                          datatableColumns={[
                            "Name",
                            "Location",
                            "Current/Recent Company",
                            "Current Title",
                            "Skill set",
                          ]}
                        />
                      </div>
                    </div>

                    <div className="row m-0 p-0">
                      <div className="col-lg-12 m-0 p-0 rpo-pb-32">
                        <StatsCard />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrolltop />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Dashboard;
