import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function BarChart(props) {
  useLayoutEffect(() => {
    let root = am5.Root.new("rpo_conversions_chart");

    root.setThemes([am5themes_Animated.new(root)]);

    // Remove Created by Logo
    root._logo.dispose();

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      })
    );

    // Define data
    let data = props.data;

    // Create Y-axis
    var yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.labels.template.set("visible", false);

    yRenderer.grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
      })
    );

    // Create X-Axis
    var xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.ticks.template.set("visible", false);

    xRenderer.labels.template.setAll({
      tooltipText: "{category}",
      oversizedBehavior: "truncate",
      maxWidth: 100,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: "category",
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      paddingTop: 20,
      fontWeight: "400",
      fontSize: 10,
      fill: am5.color(
        getComputedStyle(document.documentElement).getPropertyValue(
          "--bs-gray-500"
        )
      ),
    });

    xAxis.get("renderer").grid.template.setAll({
      disabled: true,
      strokeOpacity: 0,
    });

    xAxis.data.setAll(data);

    // Create series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
        fill: am5.color(
          getComputedStyle(document.documentElement).getPropertyValue(
            "--rpo-purple"
          )
        ),
      })
    );

    // Set separate rounded corners for top-left and top-right
    series.columns.template.setAll({
      strokeOpacity: 0,
      cornerRadiusBR: 0,
      cornerRadiusTR: 6,
      cornerRadiusBL: 0,
      cornerRadiusTL: 6,
      tooltipText: "{categoryX}: {valueY}%",
      tooltipY: 0,
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          text: "{valueY}%",
          fill: am5.color(
            getComputedStyle(document.documentElement).getPropertyValue(
              "--rpo-white"
            )
          ),
          fontSize: 25,
          fontWeight: "500",
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          populateText: true,
        }),
      });
    });

    series.data.setAll(data);

    return () => {
      root.dispose();
    };
  }, [props]);

  return <div id="rpo_conversions_chart" className="min-h-auto h-350px"></div>;
}
export default BarChart;
