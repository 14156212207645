import React, { useState, useContext } from "react";

import ProfileBlocks from "./parts/profile/ProfileBlocks";

import Button from "../../shared/FormElements/Button";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { validate } from "../../shared/util/validators";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_PHONE,
  VALIDATOR_LINKEDIN,
  VALIDATOR_WEBSITE,
} from "../../shared/util/validators";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const ProfileForm = (props) => {
  const auth = useContext(AuthContext);

  const profile = props.profile;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  /* ######################################################################## */

  const generateField = (value, validators = [], initialValue = value) => {
    const field = {
      initialValue,
      isValid: true,
      hasChanges: false,
      validators,
    };

    if (value !== undefined && value !== null) {
      field.value = value;
    }

    return field;
  };

  /* ######################################################################## */

  const profileBlockFields = {
    profile_firstname: generateField(profile?.firstname, [
      VALIDATOR_REQUIRE(),
    ]),
    profile_lastname: generateField(profile?.lastname, [
      VALIDATOR_REQUIRE(),
    ]),
    profile_displayname: generateField(profile?.displayname, [
      VALIDATOR_REQUIRE(),
    ]),
    image: generateField(profile?.image_url),
    profile_contactInformation_email: generateField(
      profile?.contactInformation?.email,
      [VALIDATOR_EMAIL()]
    ),
    profile_contactInformation_phone: generateField(
      profile?.contactInformation?.phone,
      [VALIDATOR_PHONE()]
    ),
    profile_contactInformation_location_address: generateField(
      profile?.contactInformation?.location?.address
    ),
    profile_contactInformation_location_aptsuite: generateField(
      profile?.contactInformation?.location?.aptsuite
    ),
    profile_contactInformation_location_city: generateField(
      profile?.contactInformation?.location?.city
    ),
    profile_contactInformation_location_stateprovince: generateField(
      profile?.contactInformation?.location?.stateprovince
    ),
    profile_contactInformation_location_postalcode: generateField(
      profile?.contactInformation?.location?.postalcode
    ),
    profile_contactInformation_location_country: generateField(
      profile?.contactInformation?.location?.country
    ),
    profile_contactInformation_location_coordinates: generateField(
      profile?.contactInformation?.location?.coordinates
    ),
    profile_contactInformation_social_linkedin: generateField(
      profile?.contactInformation?.social?.linkedin,
      [VALIDATOR_REQUIRE(), VALIDATOR_LINKEDIN()]
    ),
    profile_contactInformation_social_website: generateField(
      profile?.contactInformation?.social?.website,
      [VALIDATOR_REQUIRE(), VALIDATOR_WEBSITE()]
    ),
    profile_language: generateField(profile?.language),
    profile_contactInformation_timezone: generateField(
      profile?.contactInformation?.timezone
    ),
    profile_currency: generateField(profile?.currency),
    profile_contactInformation_communication_email: generateField(
      profile?.contactInformation?.communication?.email
    ),
    profile_contactInformation_communication_phone: generateField(
      profile?.contactInformation?.communication?.phone
    ),
    profile_allowemailnotifications: generateField(
      profile?.allowemailnotifications
    ),
  };

  /* ######################################################################## */

  const [formFields, setFormFields] = useState({
    ...profileBlockFields,
  });

  const [formState, setFormState] = useState({
    isValid: true,
    hasChanges: false,
  });

  /* ######################################################################## */

  const inputHandler = (event) => {
    const { id, value } = event.target;
    const field = formFields[id];
    let hasChanges = !(field.initialValue === value);
    let isValid = !!field.validators ? validate(value, field.validators) : true;

    setFormFields((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value: value,
        isValid: isValid,
        hasChanges: hasChanges,
      },
    }));

    setFormState((prevState) => ({
      ...prevState,
      isValid: Object.entries(formFields).every(([fieldId, fieldData]) =>
        id === fieldId ? isValid : fieldData.isValid
      ),
      hasChanges: Object.entries(formFields).some(([fieldId, fieldData]) =>
        id === fieldId ? hasChanges : fieldData.hasChanges
      ),
    }));
  };

  const checkboxHandler = (event) => {
    const { id, checked } = event.target;
    let hasChanges = !(formFields[id].initialValue === checked);

    setFormFields((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value: checked,
        hasChanges: hasChanges,
      },
    }));
    setFormState((prevState) => ({
      ...prevState,
      hasChanges: Object.entries(formFields).some(([fieldId, fieldData]) =>
        id === fieldId ? hasChanges : fieldData.hasChanges
      ),
    }));
  };

  /* ######################################################################## */

  const submitFormHandler = async (event) => {
    event.preventDefault();

    const formData = {};
    for (const key in formFields) {
      if (formFields[key].hasChanges) {
        if (
          typeof formFields[key].value === "object" &&
          typeof formFields[key].value.type === "undefined"
        ) {
          formData[key] = formFields[key].value;
        } else {
          formData[key] = JSON.stringify(formFields[key].value);
        }
      }
    }

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/profiles/profile/${profile.id}`,
        "PATCH",
        JSON.stringify(formData),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      console.log("ProfileForm", responseData);
      clearError();

      setFormState((prevState) => ({
        ...prevState,
        isValid: true,
        hasChanges: false,
      }));
    } catch (err) {}
  };

  const resetFormHandler = (event) => {
    //event.preventDefault();
    setFormFields((prevFormFields) => {
      const resetedFormFields = Object.fromEntries(
        Object.entries(prevFormFields).map(([key, value]) => [
          key,
          {
            ...value,
            value: value.initialValue,
            isValid: true,
            hasChanges: false,
          },
        ])
      );
      return resetedFormFields;
    });
    setFormState((prevState) => ({
      ...prevState,
      isValid: true,
      hasChanges: false,
    }));
    clearError();
  };

  /* ######################################################################## */

  return (
    <form
      className="form w-100"
      onSubmit={submitFormHandler}
      onReset={resetFormHandler}
    >
      {isLoading && <LoadingSpinner asOverlay />}
      {error && (
        <span
          className="d-flex aling-items-center justify-content-center text-danger p-2 my-4 bg-white rounded rpo-clickable"
          onClick={resetFormHandler}
        >
          {error}
        </span>
      )}
      <div className="d-flex flex-column align-items-start justify-content-center">
        <span className="fs-3 text-gray-600 pb-2">Profile information</span>
      </div>

      <ProfileBlocks
        formFields={formFields}
        inputHandler={inputHandler}
        checkboxHandler={checkboxHandler}
      />

      {(formState.isValid || formState.hasChanges) && (
        <React.Fragment>
          <div className="separator separator-dashed my-6"></div>
          <div className="d-flex justify-content-end align items-center">
            {formState.hasChanges && <Button title="Discard" type="reset" />}
            {formState.isValid && formState.hasChanges && (
              <Button title="Save Changes" type="submit" />
            )}
          </div>
        </React.Fragment>
      )}
    </form>
  );
};

export default ProfileForm;
