import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import ReactApexChart from "react-apexcharts";
import * as assets from "../../assets/svgs.js";
import { Data } from "../../shared/connections/Data";

function ClientsActiveOpeningsWidget(props) {
  const isMounted = useIsComponentMounted("ClientsActiveOpeningsWidget");

  const data = Data({
    type: "data",
    path: "chart",
    name: "clients_active_openings",
  });
  let options = null;

  if (!!data) {
    options = {
      labels: data.labels,
      series: data.series,
      chart: {
        fontFamily: "inherit",
        height: 400,
        type: "radialBar",
        offsetY: 0,
      },
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          hollow: {
            margin: 0,
            size: "30%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: true,
              fontSize: "10px",
              fontWeight: "500",
              offsetY: -15,
              color: getComputedStyle(
                document.documentElement
              ).getPropertyValue("--rpo-grey"),
            },
            value: {
              show: true,
              fontSize: "24px",
              fontWeight: "500",
              offsetY: -4,
              color: getComputedStyle(
                document.documentElement
              ).getPropertyValue("--bs-text-gray-600"),
              formatter: function (value) {
                return value + "%";
              },
            },
            total: {
              show: true,
              label: "",
              formatter: () => "256",
            },
          },
          track: {
            background: getComputedStyle(
              document.documentElement
            ).getPropertyValue("--rpo-light-purple"),
            strokeWidth: "100%",
          },
        },
      },
      colors: [
        getComputedStyle(document.documentElement).getPropertyValue(
          "--rpo-dark-purple"
        ),
      ],
      stroke: {
        lineCap: "round",
      },
    };
  }

  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          <div className="d-flex justify-content-between align-items-center border-bottom">
            <span className="fs-6 fw-semibold p-5">Active openings</span>
            <div className="rpo-svg-25px rpo-fill-orange rpo-stroke-path-orange pe-2 rpo-clickable">
              {assets.svg_more_vertical}
            </div>
          </div>
          {!!data && !!options && (
            <ReactApexChart
              options={options}
              series={options.series}
              type={options.chart.type}
              height={options.chart.height}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default ClientsActiveOpeningsWidget;
