import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import CompanyForm from "../forms/CompanyForm";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { Data } from "../../shared/connections/Data";

const CompanyCard = (props) => {
  const isMounted = useIsComponentMounted("CompanyCard");

  const company = Data({
    type: "companies",
    path: "company",
    name: props.companyId,
  });
  /* ######################################################################## */

  return (
    <React.Fragment>
      {isMounted && (
        <div className="card border mb-5 mb-xl-10">
          <div className="card-header border-0 pt-10 pb-7">
            <span className="fs-5 text-uppercase">Company Information</span>
          </div>

          <div className="card-body pt-0">
            {!company && <LoadingSpinner asOverlay />}
            {company && (
              <React.Fragment>
                <CompanyForm company={company} />
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CompanyCard;
