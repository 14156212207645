import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Avatar from "@mui/material/Avatar";

import Button from "../../shared/FormElements/Button";
import * as assets from "../../assets/svgs.js";

import UserActionAddAJob from "./UserActionAddAJob";
import UserActionAIJobGenerator from "./UserActionAIJobGenerator";
import UserActionAIMatching from "./UserActionAIMatching";

import { AuthContext } from "../../shared/context/auth-context";
import { useUser } from "../../shared/context/user-context";

const DashboardTop = (props) => {
  const isMounted = useIsComponentMounted("DashboardTop");

  const { user } = useUser();
  const auth = useContext(AuthContext);

  /* ######################################################################## */

  const listUserActions = () => {
    return (
      <React.Fragment>
        <Button icon={assets.svg_checklist} title="Tasks" />
        <UserActionAddAJob />
        <Button icon={assets.svg_calendar_edit} title="Schedule Interview" />
        <Button icon={assets.svg_sms} title="Message Recruiter" />
        <UserActionAIJobGenerator />
        <UserActionAIMatching />
      </React.Fragment>
    );
  };

  /* ######################################################################## */

  const logout = () => {
    auth.logout();
  };

  /* ######################################################################## */

  // Profile Menu addition
  const [anchorEl, setAnchorEl] = React.useState(null);
  let open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* ######################################################################## */

  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          <div className="dashboard-user-card-container">
            <div className="card shadow-lg">
              <div className="card-body d-lg-flex align-items-center justify-content-between py-5">
                <div className="d-flex align-items-center justify-content-start mw-80 overflow-y-scroll">
                  <div className="rpo-search-bar-client-container rpo-border-1-r40-light-grey bg-white me-10">
                    <div className="input-group-prepend d-flex justify-content-center align-items-center">
                      <div className="rpo-svg-24px rpo-fill-orange">
                        {assets.svg_search_normal}
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control fs-7 fw-normal border-0 bg-transparent p-0"
                      name="search"
                      placeholder="Search..."
                      autoComplete="off"
                    />
                  </div>

                  <div className="d-flex align-items-center">
                    {listUserActions()}
                  </div>
                </div>

                <div className="d-flex align-items-center ms-1">
                  <div className="d-flex align-items-center bg-hover-white bg-hover-opacity-10 py-2 px-2 px-md-3">
                    <div className="symbol symbol-50px">
                      <div className="rpo-svg-20px rpo-fill-orange">
                        {assets.svg_notification}
                      </div>
                      <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 end-0"></span>
                    </div>

                    <div className="vr mx-8"></div>
                    <div className="d-flex" onClick={handleClick}>
                      <div className="d-flex">
                        {!!user && (
                          <Avatar
                            alt="Remy Sharp"
                            src={
                              process.env.REACT_APP_ASSET_URL_PATH +
                              user.profile.image_url
                            }
                          />
                        )}
                      </div>
                      <div className="d-md-flex flex-column align-items-start justify-content-center ms-2 ms-md-4">
                        <span className="rpo-profile-displayname fs-6 fw-bold lh-1 mb-1">
                          {!!user ? user.profile.displayname : ""}
                        </span>
                        <span className="fs-6 fw-normal text-gray-300 lh-1">
                          {!!user ? user.title : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Menu
            id="user-profile-header-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            disableScrollLock={true}
            MenuListProps={{
              "aria-labelledby": "user-profile-header-menu-btn",
            }}
          >
            <div className="p-4 user-select-none">
              <div className="menu-content d-flex align-items-center px-3">
                <div className="symbol symbol-60px me-5">
                  <img
                    alt="Logo"
                    src={
                      process.env.REACT_APP_ASSET_URL_PATH +
                      (user ? user.profile.image_url : "")
                    }
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="fw-bold d-flex align-items-center fs-5">
                    <span className="badge badge-light-success fw-bold px-2 py-1">
                      {!!user ? user.role : ""}
                    </span>
                  </div>
                  <div className="fw-bold d-flex align-items-center fs-5">
                    {!!user ? user.username : ""}
                  </div>
                  <span className="fw-semibold text-muted fs-7">
                    {!!user ? user.title : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="separator my-2"></div>
            <MenuItem>
              <Link to="/profile/" className="fs-6 fw-semibold px-5">
                Account Settings
              </Link>
            </MenuItem>
            <MenuItem onClick={logout}>
              <span className="fs-6 fw-semibold px-5">Sign Out</span>
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default DashboardTop;
