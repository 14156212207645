import { useState, useEffect } from "react";

import { useHttpClient } from "../../hooks/http-hook";

function getCurrencyName(currencyCode) {
  try {
    const displayNames = new Intl.DisplayNames("en", { type: "currency" });
    return displayNames.of(currencyCode);
  } catch (error) {
    console.error("Error getting currency name:", error);
    return currencyCode;
  }
}

const Currencies = () => {
  const { sendRequest } = useHttpClient();
  const [availiableCurrencies, setAvailableCurrencies] = useState();

  useEffect(() => {
    let isMounted = true;

    const fetchCurrencies = async () => {
      try {
        const responseData = await sendRequest(
          "https://open.er-api.com/v6/latest"
        );

        if (responseData.rates) {
          let selectedCurrencies = [];
          Object.entries(responseData.rates).forEach(([currency, value]) => {
            const optionText = `${currency} - ${getCurrencyName(currency)}`;
            selectedCurrencies.push({
              value: currency,
              label: optionText,
            });
          });

          if (isMounted) {
            setAvailableCurrencies(selectedCurrencies);
          }
        }
      } catch (err) {}
    };

    fetchCurrencies();

    return () => {
      isMounted = false;
    };
  }, [sendRequest]);

  return availiableCurrencies;
};

export default Currencies;
