import React from "react";
import { Link } from "react-router-dom";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import { Data } from "../../shared/connections/Data";
function SourcesCandidatePercentageCard(props) {
  const isMounted = useIsComponentMounted("SourcesCandidatePercentageCard");

  const data = Data({ type: "data", name: "canidates_by_source" });
  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          {!!data && (
            <div className="card-container-block">
              <div className="card card-flush h-xl-100 border">
                <div className="card-header border-0 pt-5 align-items-center">
                  <span className="fs-5 text-uppercase">Candidates by source</span>
                  <div className="card-toolbar">
                    <Link to="/" className="fs-5 text-warning">
                      See all leads
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5 mt-6">
                  {data.map((sourceInfo, index) => {
                    return (
                      <div key={index} className="d-flex flex-stack">
                        <div className="d-flex align-items-center me-3 mb-8">
                          <img
                            src={
                              process.env.REACT_APP_ASSET_URL_PATH +
                              sourceInfo.source.display.image_url
                            }
                            className="me-4 w-40px"
                            alt="source"
                          />
                          <div className="flex-grow-1">
                            <Link
                              to="/"
                              className="text-gray-800 text-hover-primary fs-5 fw-bold lh-0"
                            >
                              {sourceInfo.source.display.text.title}
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex align-items-center w-100 mw-125px">
                          <div className="progress h-6px w-100 me-2 bg-primary-light">
                            <div
                              className="progress-bar bg-primary"
                              role="progressbar"
                              style={{ width: `${sourceInfo.percentage}%` }}
                              aria-valuenow={sourceInfo.percentage}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <span className="fs-4 text-primary fw-semibold">
                            {sourceInfo.percentage}%
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default SourcesCandidatePercentageCard;
