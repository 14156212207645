import React from "react";
import Select from "../../../../shared/FormElements/Select";

import Languages from "../../../../shared/connections/ThirdParty/Languages";
import Timezones from "../../../../shared/connections/ThirdParty/Timezones";
import Currencies from "../../../../shared/connections/ThirdParty/Currencies";

const ProfileLocalePreferencesBlock = (props) => {

    const formFields = props.formFields;
    const selectHandler = props.selectHandler;

    const availiableLanguages = Languages();
    const availiableTimezones = Timezones();
    const availiableCurrencies = Currencies();
  
    const blockElements = [
      {
        title: "Language",
        element: (
          <Select
            name="profile_language"
            value={formFields["profile_language"].value}
            handler={selectHandler}
            defaultText="Select a language"
            options={availiableLanguages}
            isValid={formFields["profile_language"].isValid}
          />
        ),
      },
      {
        title: "Time Zone",
        element: (
          <Select
            name="profile_contactInformation_timezone"
            value={formFields["profile_contactInformation_timezone"].value}
            handler={selectHandler}
            defaultText="Select a Time zone"
            options={availiableTimezones}
            isValid={formFields["profile_contactInformation_timezone"].isValid}
          />
        ),
      },
      {
        title: "Currency",
        element: (
          <Select
            name="profile_currency"
            value={formFields["profile_currency"].value}
            handler={selectHandler}
            defaultText="Select a currency"
            options={availiableCurrencies}
            isValid={formFields["profile_currency"].isValid}
          />
        ),
      },
    ];
  
    return (
      <React.Fragment>
        <div className="separator separator-dashed my-6"></div>
  
        {blockElements.map((blockElement, index) => {
          return (
            <div key={index} className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                {blockElement.title}
              </label>
              <div className="col-lg-8 fv-row">{blockElement.element}</div>
            </div>
          );
        })}
      </React.Fragment>
    );
};

export default ProfileLocalePreferencesBlock;
