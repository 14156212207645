import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
import { UserProvider } from "./shared/context/user-context";
import { PageProvider } from "./shared/context/page-context";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";

import "./assets/css/style.bundle.css";
import "./assets/css/rpo.css";

import Landing from "./rpo/pages/Landing";
import Dashboard from "./rpo/pages/Dashboard";
import ClientDashboard from "./rpo/pages/ClientDashboard";
import Activities from "./rpo/pages/Activities";
import Activity from "./rpo/pages/Activity";
import Tasks from "./rpo/pages/Tasks";
import Job from "./rpo/pages/Job";
import Jobs from "./rpo/pages/Jobs";
import Jobboard from "./rpo/pages/Jobboard";
import Clients from "./rpo/pages/Clients";
import Client from "./rpo/pages/Client";
import Candidates from "./rpo/pages/Candidates";
import ClientCandidates from "./rpo/pages/ClientCandidates";
import Candidate from "./rpo/pages/Candidate";
import Company from "./rpo/pages/Company";
import LeadsBoard from "./rpo/pages/LeadsBoard";
import Messages from "./rpo/pages/Messages";
import Contracts from "./rpo/pages/Contracts";
import Reports from "./rpo/pages/Reports";
import Teams from "./rpo/pages/Teams";
import Field from "./rpo/pages/Field";
import Profile from "./rpo/pages/Profile";
import UserProfile from "./rpo/pages/UserProfile";
import Login from "./rpo/pages/Login";
function App() {
  const { token, login, logout, userId } = useAuth();

  let routes;
  if (token) {
    routes = (
      <UserProvider>
        <PageProvider>
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/profile" exact component={UserProfile} />
            <Route path="/profile/:profileId" exact component={Profile} />
            <Route path="/activities" exact component={Activities} />
            <Route path="/activity/:activityId" exact component={Activity} />
            <Route path="/tasks" exact component={Tasks} />
            <Route path="/job/:jobId" exact component={Job} />
            <Route path="/jobs" exact component={Jobs} />
            <Route path="/job-board" exact component={Jobboard} />
            <Route path="/clients" exact component={Clients} />
            <Route path="/client/:clientId" exact component={Client} />
            <Route path="/candidates" exact component={Candidates} />
            <Route path="/client-candidates" exact component={ClientCandidates} />
            <Route path="/candidate/:candidateId" exact component={Candidate} />
            <Route path="/company/:companyId" exact component={Company} />
            <Route path="/leads-board" exact component={LeadsBoard} />
            <Route path="/messages" exact component={Messages} />
            <Route path="/contracts-agreements" exact component={Contracts} />
            <Route path="/reports" exact component={Reports} />
            <Route path="/teams" exact component={Teams} />
            <Route path="/client-overview" exact component={ClientDashboard} />
            <Route path="/field/:fieldId" exact component={Field} />
            <Redirect to="/" />
          </Switch>
        </PageProvider>
      </UserProvider>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/auth" exact component={Login} />
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <Suspense
          fallback={
            <div className="center">
              <LoadingSpinner />
            </div>
          }
        >
          {routes}
        </Suspense>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
