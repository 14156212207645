import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useIsComponentMounted } from "../../../shared/hooks/component-hook";

function getTimeRangeWithPeriod(eventDateStr, durationInMinutes) {
  const eventDate = new Date(eventDateStr);
  const startTime = new Date(eventDate); // Make a copy of event date
  const endTime = new Date(eventDate); // Make a copy of event date

  // Extract hours and minutes from the event date
  const startHour = startTime.getUTCHours();
  const startMinute = startTime.getUTCMinutes();

  // Calculate end time
  endTime.setMinutes(startTime.getUTCMinutes() + durationInMinutes);

  // Extract hours and minutes from the end time
  const endHour = endTime.getUTCHours();
  const endMinute = endTime.getUTCMinutes();

  // Determine time periods (AM or PM)
  const startPeriod = startHour >= 12 ? 'PM' : 'AM';
  // const endPeriod = endHour >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const formattedStartHour = startHour % 12 || 12;
  const formattedEndHour = endHour % 12 || 12;

  // Format start and end times
  const formattedStartTime = `${formattedStartHour}:${startMinute < 10 ? '0' : ''}${startMinute}`;
  const formattedEndTime = `${formattedEndHour}:${endMinute < 10 ? '0' : ''}${endMinute}`;

  // Combine formatted start and end times
  const timeRange = `${formattedStartTime} - ${formattedEndTime}`;

  return {
      range: timeRange,
      startPeriod: startPeriod
  };
}

function UserEventsCardLayout(props) {
  const limit = props.limit || false;
  const isMounted = useIsComponentMounted("UserEventsCardLayout");
  const [dates, setDates] = useState();

  useEffect(() => {
    setDates(props.events);
  }, [props]);

  const onClickTabHandler = (clickedDate) => {
    const updatedDates = dates.map((date) => ({
      ...date,
      selected: date === clickedDate
    }));

    setDates(updatedDates);
  };

  const getClosestEvents = (events, limit) => {
    const now = new Date();
    const allEvents = [...events]; // Create a copy of events array
    allEvents.sort((a, b) => Math.abs(new Date(a.date) - now) - Math.abs(new Date(b.date) - now));
    return limit ? allEvents.slice(0, limit) : allEvents;
};

  return (
    <React.Fragment>
      {isMounted && (
        <div className="card border">
          <div className={`card-header border-0 ${props?.topCardPadding||"pt-3"} align-items-center`}>
            <span className="fs-5 text-uppercase">
              {dates.map((date) => date.status === "current" && date.date_full)}
            </span>
            <div className="card-toolbar">
              <Link to="/activities" className="fs-5 text-warning">
                See calendar
              </Link>
            </div>
          </div>
          <div className="card-body pt-7 px-0">
            <ul className="nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5">
              {dates.map((date, index) => (
                <li
                  key={index}
                  className="nav-item p-0 ms-0"
                  onClick={() => onClickTabHandler(date)}
                >
                  <span
                    className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-35px py-4 px-3 btn-active-warning ${date.selected && "active"}`}
                    data-bs-toggle="tab"
                    href="#kt_timeline_widget_3_tab_content_1"
                  >
                    <span className="fs-7 fw-semibold">{date.day_short}</span>
                    <span className={`fs-6 fw-bold ${date.events.length > 0 ? "text-warning" : ""}`}>{date.day_numeric}</span>
                  </span>
                </li>
              ))}
            </ul>
            <div className="tab-content mb-2 px-9">
              <div className="tab-pane fade show active">
                {dates.map((date, index) => (
                  date.selected && (
                    date.events.length > 0 ? (
                      getClosestEvents(date.events, limit).map((event, eindex) => {
                        const uniqueKey = `${index}-${eindex}`;
                        const { range, startPeriod } = getTimeRangeWithPeriod(event.date, event.duration);

                        return (
                          <div key={uniqueKey} className="d-flex align-items-center mb-6">
                            <span data-kt-element="bullet" className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 ${event.display.color.class}`}></span>
                            <div className="flex-grow-1 me-5">
                              <div className="text-gray-800 fw-semibold fs-2">{range}<span className="text-gray-400 fw-semibold fs-7">{startPeriod}</span></div>
                              <div className="text-gray-700 fw-semibold fs-6">{event.title}</div>
                              <div className="text-gray-400 fw-semibold fs-7">
                                Lead by
                                <span href="/" className="text-primary opacity-75-hover fw-semibold">{event.participants.owner.displayname}</span>
                              </div>
                            </div>
                            <Link to={`/activity/${event?.id}`} className="btn btn-sm btn-light">View</Link>
                          </div>
                        );
                      })
                    ) : (
                      <div key={`date-${index}`} className="fs-4 fw-semibold py-4">
                        <span className="d-flex fs-6 fw-bold">There are no planned events for {date.date_full}</span>
                      </div>
                    )
                  )
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default UserEventsCardLayout;
