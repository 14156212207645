import React, { useEffect, useState } from "react";

import Input from "../../../../shared/FormElements/Input";
import Button from "../../../../shared/FormElements/Button";
import Modal from "@mui/material/Modal";

import { getProfileLayout } from "../../../../shared/util/mappings";
import * as assets from "../../../../assets/svgs.js";

import { Data } from "../../../../shared/connections/Data";

const EntityGroupSelectionBlock = (props) => {
  const inputId = props.inputId;
  const hasChanges = props.formFields[inputId].hasChanges;
  const inputHandler = props.inputHandler;

  const type = props.type;
  const title = props.title;
  const selectionTitle = props.selectionTitle;

  const availiableEntities = Data({
    type: "data",
    path: "datatable",
    name: type,
  });

  /* ######################################################################## */

  const [inputSelectedValue, setInputSelectedValue] = useState();
  const [selectedEntities, setSelectedEntities] = useState();

  useEffect(() => {
    if (!hasChanges) {
      setInputSelectedValue(props.formFields[inputId].value);
      setSelectedEntities(props.entities);
    }
  }, [hasChanges, props, inputId]);

  /* ######################################################################## */

  const removeEntityHandler = (entity) => {
    const updatedEntities = selectedEntities.filter((e) => e.id !== entity.id);
    const idsString = updatedEntities.map((e) => e.id).join(",");
    setInputSelectedValue(idsString);
    setSelectedEntities(updatedEntities);
    inputHandler({ target: { id: inputId, value: idsString } });
  };

  /* ######################################################################## */

  const addEntityHandler = (entity) => {
    const updatedEntities = selectedEntities.concat(entity);
    const idsString = updatedEntities.map((e) => e.id).join(",");
    setInputSelectedValue(idsString);
    setSelectedEntities(updatedEntities);
    inputHandler({ target: { id: inputId, value: idsString } });
    setOpen(false);
  };

  /* ######################################################################## */

  const [open, setOpen] = useState(false);
  const openModalHandler = function (state) {
    setOpen(true);
  };
  const closeModalHandler = () => setOpen(false);

  /* ######################################################################## */

  // Check if the entity's ID exists in selectedEntities
  let finalAvailiableEntities = [];
  if (!!availiableEntities) {
    finalAvailiableEntities = availiableEntities.filter((entity) => {
      return !selectedEntities.some(
        (selectedEntity) => selectedEntity.id === entity.id
      );
    });
  }

  /* ######################################################################## */

  let entitiesLayout = "";
  if (!!selectedEntities) {
    switch (props.type) {
      case "hiring_managers":
      case "team_members":
      case "event_participants":
        entitiesLayout = (
          <React.Fragment>
            {selectedEntities.map((entity, index) => {
              return (
                <div className="row rounded p-2 my-2" key={index}>
                  <div className="col-11 d-flex align-items-center justify-content-start">
                    <div className="symbol symbol-30px symbol-md-40px symbol-circle mr-2">
                      <img
                        src={
                          process.env.REACT_APP_ASSET_URL_PATH +
                          entity.image_url
                        }
                        alt="entity"
                      />
                    </div>
                    <div className="d-flex align-items-start justify-content-center ms-2 ms-md-4 rpo-clickable">
                      <label className="ps-4 fs-6 fw-semibold text-gray-600">
                        {entity.firstname}{" "}
                      </label>
                      <label className="ps-4 fs-6 fw-semibold text-gray-600">
                        {entity.lastname}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-1 d-flex align-items-center justify-content-end">
                    <div
                      className="rpo-svg-20px rpo-fill-white rpo-stroke-path-orange rpo-clickable"
                      onClick={() => {
                        removeEntityHandler(entity);
                      }}
                    >
                      {assets.svg_trash}
                    </div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        );
        break;
      default:
        entitiesLayout = "";
        break;
    }
  }

  /* ######################################################################## */

  let optionsLayout = "";
  let optionsIcon = "";
  let optionNumberOfResults = -1;
  if (!!availiableEntities && finalAvailiableEntities.length > 0) {
    switch (props.type) {
      case "hiring_managers":
        optionNumberOfResults = new Set(
          availiableEntities
            .filter((entity) => !inputSelectedValue.includes(entity.id))
            .map((entity) => entity.id)
        ).size;
        optionsIcon = (
          <div className="rpo-svg-35px rpo-fill-purple">
            {assets.svg_user_tag}
          </div>
        );
        optionsLayout = (
          <React.Fragment>
            <span className="fs-4 fw-bolder text-gray-600">
              {optionNumberOfResults} result
              {optionNumberOfResults > 1 ? "s" : ""}
            </span>
            <div className="row mt-10 border rounded">
              <div className="col-12">
                <div className="row  border-bottom bg-secondary">
                  <div className="col-6 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">Name</span>
                  </div>
                  <div className="col-3 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">
                      Company
                    </span>
                  </div>
                  <div className="col-3 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">Role</span>
                  </div>
                </div>
                <React.Fragment>
                  {availiableEntities.map((entity, index) => {
                    if (!inputSelectedValue.includes(entity.id))
                      return (
                        <div
                          className={`row ${
                            index + 1 < availiableEntities.length
                              ? "border-bottom"
                              : ""
                          } rpo-clickable`}
                          onClick={() => addEntityHandler(entity)}
                          key={index}
                        >
                          <div className="col-6 py-4 px-10">
                            {getProfileLayout(entity)}
                          </div>
                          <div className="col-3 py-4 px-10">
                            <span className="fs-6 text-gray-600">
                              {entity.company?.name || entity.company}
                            </span>
                          </div>
                          <div className="col-3 py-4 px-10">
                            <span className="fs-6 text-gray-600">
                              {entity.role}
                            </span>
                          </div>
                        </div>
                      );
                    else {
                      return "";
                    }
                  })}
                </React.Fragment>
              </div>
            </div>
            <div className="row pt-12">
              <div className="col-12 d-flex justify-content-between align-items-center p-0">
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_left}
                  title="Previous"
                />
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_right}
                  iconAfterTitle={true}
                  title="Next"
                />
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "team_members":
        optionNumberOfResults = new Set(
          availiableEntities
            .filter((entity) => !inputSelectedValue.includes(entity.id))
            .map((entity) => entity.id)
        ).size;
        optionsIcon = (
          <div className="rpo-svg-35px rpo-fill-purple">{assets.svg_people}</div>
        );
        optionsLayout = (
          <React.Fragment>
            <span className="fs-4 fw-bolder text-gray-600">
              {optionNumberOfResults} result
              {optionNumberOfResults > 1 ? "s" : ""}
            </span>
            <div className="row mt-10 border rounded">
              <div className="col-12">
                <div className="row  border-bottom bg-secondary">
                  <div className="col-8 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">Name</span>
                  </div>
                  <div className="col-4 py-4 px-10">
                    <span className="fs-7 fw-semibold text-gray-600">Company Role</span>
                  </div>
                </div>
                <React.Fragment>
                  {availiableEntities.map((entity, index) => {
                    if (!inputSelectedValue.includes(entity.id))
                      return (
                        <div
                          className={`row ${
                            index + 1 < availiableEntities.length
                              ? "border-bottom"
                              : ""
                          } rpo-clickable`}
                          onClick={() => addEntityHandler(entity)}
                          key={index}
                        >
                          <div className="col-8 py-4 px-10">
                            {getProfileLayout(entity)}
                          </div>
                          <div className="col-4 py-4 px-10">
                            <span className="fs-6 text-gray-600">
                              {entity?.client?.title || "Not a client"}
                            </span>
                          </div>
                        </div>
                      );
                    else {
                      return "";
                    }
                  })}
                </React.Fragment>
              </div>
            </div>
            <div className="row pt-12">
              <div className="col-12 d-flex justify-content-between align-items-center p-0">
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_left}
                  title="Previous"
                />
                <Button
                  layout="inactive"
                  icon={assets.svg_arrow_right}
                  iconAfterTitle={true}
                  title="Next"
                />
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "event_participants":
        optionsIcon = (
          <div className="rpo-svg-35px rpo-fill-purple">{assets.svg_user}</div>
        );
        optionsLayout = (
          <React.Fragment>
            {availiableEntities.map((entity, index) => {
              if (!inputSelectedValue.includes(entity.id))
                return (
                  <div
                    className="row rounded p-2 my-4"
                    onClick={() => addEntityHandler(entity)}
                    key={index}
                  >
                    <div className="col-12 d-flex align-items-center justify-content-start">
                      <div className="symbol symbol-30px symbol-md-40px symbol-circle mr-2">
                        <img
                          src={
                            process.env.REACT_APP_ASSET_URL_PATH +
                            entity.image_url
                          }
                          alt="entity"
                        />
                      </div>
                      <div className="d-flex align-items-start justify-content-center ms-2 ms-md-4 rpo-clickable">
                        <label className="ps-4 fs-6 fw-semibold text-gray-600">
                          {entity.firstname}{" "}
                        </label>
                        <label className="ps-4 fs-6 fw-semibold text-gray-600">
                          {entity.lastname}{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              else {
                return "";
              }
            })}
          </React.Fragment>
        );
        break;
      default:
        optionsLayout = "";
        break;
    }
  }

  const optionsModal = (
    <Modal
      open={open}
      onClose={closeModalHandler}
      aria-labelledby="staticBackdrop"
      aria-describedby="modal-modal-description"
    >
      <div
        className="modal-dialog"
        role="document"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "650px",
        }}
      >
        <div className="modal-content p-10 pe-10 bg-white border-rounded rounded-3">
          <div className="modal-header border-0 pb-4">
            <div className="d-flex">
              {optionsIcon}
              <span className="fs-1 text-gray-600 d-flex ms-4">
                {selectionTitle}
              </span>
            </div>
            <div
              className="rpo-svg-35px rpo-fill-first-orange-other-white rpo-clickable"
              aria-label="Close"
              onClick={closeModalHandler}
            >
              {assets.svg_close_circle}
            </div>
          </div>

          <div className="modal-body">{optionsLayout}</div>
        </div>
      </div>
    </Modal>
  );

  /* ######################################################################## */

  return (
    <React.Fragment>
      {props?.displaySeperator && (
        <div className="separator separator-solid my-6"></div>
      )}
      <div className="row mb-8">
        <label className="col-lg-5 fs-6 fw-semibold text-gray-600 px-4">
          {title}
          <Input
            id={inputId}
            value={inputSelectedValue}
            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 d-none"
          />
        </label>
        <div className="col-lg-7">
          {entitiesLayout}
          {finalAvailiableEntities.length > 0 ? (
            <React.Fragment>
              <div className="row">
                <div className="col-12 text-end pt-4">
                  <span
                    className="fs-6 text-warning rpo-clickable"
                    onClick={openModalHandler}
                  >
                    {selectionTitle}
                  </span>
                  {optionsModal}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="row ">
                <div className="col-12 text-end pt-4">
                  <span className="fs-5 text-muted">No available {title}</span>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default EntityGroupSelectionBlock;
