import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import { getDatatableColumns } from "../../shared/util/functions";

import CandidateModal from "../parts/CandidateModal";
import ClientModal from "../parts/ClientModal";

import { Data } from "../../shared/connections/Data";

const DatatableCard = (props) => {
  const isMounted = useIsComponentMounted("DatatableCard");

  const cardTitle = props.title;
  const cardBadgeText = props.badgeText;
  const cardToolbarLink = props.toolbarLink;
  const datatableEntity = props.datatableEntity;
  const datatableColumns = props.datatableColumns;
  const useModal = props.useModal ? props.useModal : false;

  /* ######################################################################## */

  const data = Data({ type: "data", path: "datatable", name: datatableEntity });
  const columns = getDatatableColumns(datatableEntity, datatableColumns);

  /* ######################################################################## */

  const history = useHistory();

  const redirectToEntityPage = function (state) {
    let selectedEntity = data.filter((entity) => entity.id === state.id)[0];
    if (selectedEntity) {
      switch (datatableEntity) {
        case "activejob":
        case "job":
          history.push(`/job/${selectedEntity.id}`);
          break;
        case "candidate":
        case "client":
          history.push(`/${datatableEntity}/${selectedEntity.id}`);
          break;
        default:
          history.push(`/`);
          break;
      }
    }
  };

  /* ######################################################################## */

  const [selectedEntity, setSelectedEntity] = useState(false);

  const [open, setOpen] = React.useState(false);
  const openModalHandler = function (state) {
    setSelectedEntity(data.filter((entity) => entity.id === state.id)[0]);
    setOpen(true);
  };
  const closeModalHandler = () => setOpen(false);

  /* ######################################################################## */

  const datatableRowClickHandler = (state) => {
    useModal ? openModalHandler(state) : redirectToEntityPage(state);
  };

  /* ######################################################################## */

  const datatableSorting = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      if (direction === "asc") {
        switch (typeof selector(rowA)) {
          case "string":
            return selector(rowA).localeCompare(selector(rowB));
          case "number":
            return selector(rowA) - selector(rowB);
          case "boolean":
            return selector(rowA) - selector(rowB);
          case "object":
            if (selector(rowA).title)
              return selector(rowA).title.localeCompare(selector(rowB).title);
            return selector(rowA).length - selector(rowB).length;
          default:
            console.log(
              `>> Requested shorting (${direction}) (${typeof selector(
                rowA
              )}) could not be determined`
            );
            return false;
        }
      }
      if (direction === "desc") {
        switch (typeof selector(rowA)) {
          case "string":
            return selector(rowB).localeCompare(selector(rowA));
          case "number":
            return selector(rowB) - selector(rowA);
          case "boolean":
            return selector(rowB) - selector(rowA);
          case "object":
            if (selector(rowA).title)
              return selector(rowB).title.localeCompare(selector(rowA).title);
            return selector(rowB).length - selector(rowA).length;
          default:
            console.log(
              `>> Requested shorting (${direction}) (${typeof selector(
                rowA
              )}) could not be determined`
            );
            return false;
        }
      }
      console.log(
        `>> Requested shorting (${direction}) (${typeof selector(
          rowA
        )}) could not be determined`
      );
      return false;
    });
  };

  /* ######################################################################## */

  return (
    <React.Fragment>
      {isMounted && (
        <div className="card-container-block rpo-datatable-border">
          <div className="card card-flush h-xl-100">
            <div className="card-header border-0 pt-5 pb-2">
              <span className="fs-5 text-uppercase">
                {cardTitle}
                {cardBadgeText && (
                  <span className="badge badge-light-info fw-bold ms-2 p-2">
                    {!!data ? data.length : ""} {cardBadgeText}
                  </span>
                )}
              </span>
              <div className="card-toolbar">
                {!!cardToolbarLink && (
                  <Link to={cardToolbarLink.url} className="fs-5 text-warning">
                    {cardToolbarLink.title}
                  </Link>
                )}
              </div>
            </div>
            <div className="card-body p-0 pt-1 pb-2">
              {!data && (
                <span className="d-flex justify-content-center align-items-center p-4">
                  Fetching data ..
                </span>
              )}
              {!!data && (
                <DataTable
                  id={datatableEntity + `-datatable`}
                  columns={columns}
                  data={data}
                  conditionalRowStyles={[
                    {
                      when: (row) => true,
                      style: {
                        "&:hover": {
                          backgroundColor: "var(--bs-page-bg)",
                          cursor: "pointer",
                        },
                      },
                    },
                  ]}
                  sortFunction={datatableSorting}
                  onRowClicked={datatableRowClickHandler}
                />
              )}
            </div>

            {datatableEntity === "candidate" && selectedEntity && (
              <CandidateModal
                open={open}
                closeModalHandler={closeModalHandler}
                selectedEntity={selectedEntity}
              />
            )}

            {datatableEntity === "client" && selectedEntity && (
              <ClientModal
                open={open}
                closeModalHandler={closeModalHandler}
                selectedEntity={selectedEntity}
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DatatableCard;
