import React, { useState, useEffect } from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import CandidateForm from "../forms/CandidateForm";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { Data } from "../../shared/connections/Data";

const activeTabs = ["Details", "Applications", "Interviews", "Notifications"];
const CandidateCard = (props) => {
  const isMounted = useIsComponentMounted("CandidateCard");

  const candidate = Data({
    type: "candidates",
    path: "candidate",
    name: props.candidateId,
  });

  /* ######################################################################## */
  const [tabSelected, setTabSelected] = useState(false);

  useEffect(() => {
    setTabSelected(activeTabs[0]);
  }, []);

  const onClickTabHandler = (selection) => {
    setTabSelected(selection);
  };
  /* ######################################################################## */

  return (
    <React.Fragment>
      {isMounted && (
        <div className="card border mb-5 mb-xl-10">
          <div className="card-header border-0 pt-10 pb-7">
            <span className="fs-5 text-uppercase">Candidate Profile</span>
          </div>

          <div className="card-body pt-0">
            {!candidate && <LoadingSpinner asOverlay />}
            {candidate && (
              <React.Fragment>
                <div className="d-flex justify-content-start align-items-center border-bottom mb-7">
                  <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                    {activeTabs.map((activeTab, index) => {
                      return (
                        <li
                          key={index}
                          className="nav-item pe-5 rpo-clickable"
                          onClick={() => onClickTabHandler(activeTab)}
                        >
                          <div className="d-flex justify-content-start align-items-start">
                            <span
                              className={`nav-link fs-7 fw-normal text-gray-600 text-active-info m-0 p-0 pe-2 pb-5 ${
                                tabSelected === activeTab ? "active" : ""
                              }`}
                            >
                              {activeTab}
                            </span>
                            {candidate.reports.sums[
                              activeTab.toLowerCase()
                            ] && (
                              <div className="badge rpo-badge-border-pill rpo-badge-light-blue">
                                <span className="fs-7 fw-semibold">
                                  {
                                    candidate.reports.sums[
                                      activeTab.toLowerCase()
                                    ]
                                  }
                                </span>
                              </div>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div
                  className={`collapse ${
                    tabSelected === "Details" ? "show" : ""
                  }`}
                >
                  <CandidateForm candidate={candidate} />
                </div>

                <div
                  className={`collapse ${
                    tabSelected === "Applications" ? "show" : ""
                  }`}
                >
                  <span>Applications</span>
                </div>

                <div
                  className={`collapse ${
                    tabSelected === "Interviews" ? "show" : ""
                  }`}
                >
                  <span>Interviews</span>
                </div>

                <div
                  className={`collapse ${
                    tabSelected === "Notifications" ? "show" : ""
                  }`}
                >
                  <span>Notifications</span>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CandidateCard;
