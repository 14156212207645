import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import { Data } from "../../shared/connections/Data";

import UserEventsCardLayout from "./layouts/UserEventsCardLayout";
function UserEventsCard(props) {
  const isMounted = useIsComponentMounted("UserEventsCard");
  const data = Data({ type: "data", name: "calendar_widget_dates" });

  return (
    <React.Fragment>
      {isMounted && data && (
        <UserEventsCardLayout events={data} limit={props.limit} topCardPadding={props.topCardPadding}/>
      )}
    </React.Fragment>
  );
}
export default UserEventsCard;
