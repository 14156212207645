import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import Button from "../../shared/FormElements/Button";
import * as assets from "../../assets/svgs.js";

const ReportsSearchCard = () => {
  const isMounted = useIsComponentMounted("ReportsSearchCard");

  const onChangeHandler = (props) => {
    console.log("ReportsSearchCard onChange Handler", props);
  };

  return (
    <React.Fragment>
      {isMounted && (
        <div className="card h-md-100 border">
          <div className="card-header border-0 pt-5 pb-8">
            <span className="fs-5 text-uppercase">Reports search</span>
          </div>
          <div className="card-body pt-0">
            <form className="form d-flex justify-content-between align-items-center">
              <div className="input-group rpo-input-group mw-50 me-8">
                <div className="input-group-prepend d-flex justify-content-center align-items-center">
                  <div className="rpo-svg-24px rpo-fill-purple ps-4">
                    {assets.svg_documet_text}
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control border-0 bg-transparent"
                  name="reportssearch"
                  value=""
                  placeholder="Clients, Candidates, Interviews..."
                  onChange={onChangeHandler}
                />
              </div>

              <div className="input-group rpo-input-group rpo-width-13em">
                <div className="input-group-prepend d-flex justify-content-center align-items-center">
                  <div className="rpo-svg-24px rpo-fill-purple ps-4">
                    {assets.svg_calendar_date}
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control border-0 bg-transparent"
                  name="fromdate"
                  value=""
                  placeholder="DD/MM/YYYY"
                  onChange={onChangeHandler}
                />
              </div>

              <span className="fs-5">to</span>

              <div className="input-group rpo-input-group rpo-width-13em">
                <div className="input-group-prepend d-flex justify-content-center align-items-center">
                  <div className="rpo-svg-24px rpo-fill-purple ps-4">
                    {assets.svg_calendar_date}
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control border-0 bg-transparent"
                  name="todate"
                  value=""
                  placeholder="DD/MM/YYYY"
                  onChange={onChangeHandler}
                />
              </div>

              <div className="d-flex justify-content-end align-items-center">
                <Button icon={assets.svg_search_normal} title="Search" />
              </div>
            </form>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ReportsSearchCard;
