import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import ReactApexChart from "react-apexcharts";
import * as assets from "../../assets/svgs.js";
import { Data } from "../../shared/connections/Data";

function ClientsOpeningsGrowthWidget(props) {
  const isMounted = useIsComponentMounted("ClientsOpeningsGrowthWidget");

  const data = Data({
    type: "data",
    path: "chart",
    name: "clients_openings_growth",
  });
  let options = null;

  if (!!data) {
    var labelColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--bs-gray-500");
    var borderColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--bs-border-dashed-color");
    var baseColor = [
      getComputedStyle(document.documentElement).getPropertyValue(
        "--rpo-purple-3"
      ),
      getComputedStyle(document.documentElement).getPropertyValue(
        "--rpo-purple-2"
      ),
      getComputedStyle(document.documentElement).getPropertyValue(
        "--rpo-dark-purple"
      ),
    ];
    var lightColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--rpo-purple-2");

    options = {
      series: data.series,
      chart: {
        fontFamily: "inherit",
        type: "area",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
        position: "right",
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 80, 100],
        },
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: baseColor,
      },
      xaxis: {
        categories: data.categories,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 6,
        labels: {
          rotate: 0,
          rotateAlways: true,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: getComputedStyle(document.documentElement).getPropertyValue(
              "--rpo-dark-purple"
            ),
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        max: 1000,
        min: 0,
        tickAmount: 4,
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
          formatter: function (val) {
            return parseInt(val);
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return parseInt(val);
          },
        },
      },
      colors: [lightColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColor: baseColor,
        strokeWidth: 3,
      },
    };
  }

  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          <div className="d-flex justify-content-between align-items-center border-bottom">
            <span className="fs-6 fw-semibold p-5">Openings growth</span>
            <div className="rpo-svg-25px rpo-fill-orange rpo-stroke-path-orange pe-2 rpo-clickable">
              {assets.svg_more_vertical}
            </div>
          </div>
          {!!data && !!options && (
            <ReactApexChart
              options={options}
              series={options.series}
              type={options.chart.type}
              height={options.chart.height}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default ClientsOpeningsGrowthWidget;
