import React from "react";

import TextArea from "../../../../shared/FormElements/TextArea";

const CandidateNotesBlock = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const blockElements = [
    {
      title: "Summary",
      element: (
        <TextArea
          id="notes_summary"
          value={formFields["notes_summary"].value}
          handler={inputHandler}
          isValid={formFields["notes_summary"].isValid}
          rows="3"
        />
      ),
    },
    {
      title: "Currently",
      element: (
        <TextArea
          id="notes_currently"
          value={formFields["notes_currently"].value}
          handler={inputHandler}
          isValid={formFields["notes_currently"].isValid}
          rows="19"
        />
      ),
    },
    {
      title: "Why looking",
      element: (
        <TextArea
          id="notes_whylooking"
          value={formFields["notes_whylooking"].value}
          handler={inputHandler}
          isValid={formFields["notes_whylooking"].isValid}
          rows="12"
        />
      ),
    },
    {
      title: "Previously",
      element: (
        <TextArea
          id="notes_previously"
          value={formFields["notes_previously"].value}
          handler={inputHandler}
          isValid={formFields["notes_previously"].isValid}
          row="7"
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      {blockElements.map((blockElement, index) => {
        return (
          <React.Fragment key={index}>
            <div className="separator my-6"></div>

            <div className="row form-group pe-19 mb-6">
              <div className="col-4 d-flex justify-content-start align-items-start">
                <label className="fs-6 fw-semibold text-gray-600 px-4">
                  {blockElement.title}
                </label>
              </div>
              <div className="col-8">{blockElement.element}</div>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default CandidateNotesBlock;
