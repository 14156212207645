import React, { createContext, useContext, useState, useEffect } from "react";

import { useHttpClient } from "../hooks/http-hook";
import { AuthContext } from "../context/auth-context";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { sendRequest } = useHttpClient();
  const [user, setUser] = useState();

  const updateUser = (updatedUser) => {
    setUser(updatedUser);
  };

  const auth = useContext(AuthContext);
  useEffect(() => {
    const fetchUser = async () => {
      if (!!auth && auth.userId !== null) {
        try {
          const responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL +
              "/users/userinformation/" +
              auth.userId,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          setUser(responseData.user);
        } catch (err) {}
      }
    };
    fetchUser();
  }, [sendRequest, auth]);

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
