import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import BarChart from "../../shared/charts/BarChart";
import { Data } from "../../shared/connections/Data";

function CandidatesConversionCard() {
  const isMounted = useIsComponentMounted("CandidatesConversionCard");

  const data = Data({ type: "data", path: "chart", name: "client_conversion" });

  return (
    <React.Fragment>
      {isMounted && (
        <div className="card border">
          <div className="card-header border-0 pt-5 align-items-center">
            <span className="fs-5 text-uppercase">Conversions</span>
            <div className="card-toolbar">
              <a href="/" className=" fs-5 text-warning">
                See details
              </a>
            </div>
          </div>
          <div className="card-body pt-0">
            {!!data && <BarChart data={data} />}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
export default CandidatesConversionCard;
