import React from "react";

import ProfileBlock from "./ProfileBlock";
import ProfileContactBlock from "./ProfileContactBlock";
import ProfileSocialBlock from "./ProfileSocialBlock";
import ProfileLocationBlock from "./ProfileLocationBlock";
import ProfileLocalePreferencesBlock from "./ProfileLocalePreferencesBlock";
import ProfileFlagsBlock from "./ProfileFlagsBlock";

const ProfileBlocks = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;
  const checkboxHandler = props.checkboxHandler;

  return (
    <React.Fragment>
      <ProfileBlock formFields={formFields} inputHandler={inputHandler} />
      <ProfileContactBlock
        formFields={formFields}
        inputHandler={inputHandler}
      />
      <ProfileSocialBlock formFields={formFields} inputHandler={inputHandler} />
      <ProfileLocationBlock
        formFields={formFields}
        inputHandler={inputHandler}
      />
      <ProfileLocalePreferencesBlock
        formFields={formFields}
        selectHandler={inputHandler}
      />
      <ProfileFlagsBlock
        formFields={formFields}
        checkboxHandler={checkboxHandler}
      />
    </React.Fragment>
  );
};

export default ProfileBlocks;
