import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import RadialBarChart from "../../shared/charts/RadialBarChart";
import { Data } from "../../shared/connections/Data";
function CandidateMatchingScoreCard() {
  const isMounted = useIsComponentMounted("CandidateMatchingScoreCard");

  const data = Data({
    type: "data",
    path: "chart",
    name: "candidate_overall_matching_score",
  });
  return (
    <React.Fragment>
      {isMounted && (
        <div className="card border p-0 pb-14">
          <div className="card-header border-0 pt-5 align-items-center">
            <span className="fs-5 text-uppercase">Overall matching score</span>
          </div>
          <div className="card-body p-0 overflow-hidden">
            <div className="d-flex flex-center w-100">
              <div
                className="mixed-widget-17-chart"
                data-kt-chart-color="info"
                style={{ height: "300px", marginBottom: "-150px" }}
              >
                {!!data && (
                  <RadialBarChart
                    labels={data.labels}
                    series={data.series}
                    height="100%"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
export default CandidateMatchingScoreCard;
