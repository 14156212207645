import React, { useState, useContext } from "react";

import EventTimeBlocks from "./parts/event/EventTimeBlocks";
import EntityGroupSelectionBlock from "./parts/entity/EntityGroupSelectionBlock";
import EntitySelectionBlock from "./parts/entity/EntitySelectionBlock";

import Input from "../../shared/FormElements/Input";
import Button from "../../shared/FormElements/Button";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { validate } from "../../shared/util/validators";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const EventForm = (props) => {
  const auth = useContext(AuthContext);

  const eventElement = props.event;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  /* ######################################################################## */

  const generateField = (value, validators = [], initialValue = value) => {
    const field = {
      initialValue,
      isValid: true,
      hasChanges: false,
      validators,
    };

    if (value !== undefined && value !== null) {
      field.value = value;
    }

    return field;
  };

  /* ######################################################################## */

  const eventElementBlockFields = {
    event_title: generateField(eventElement?.title, [VALIDATOR_REQUIRE()]),
    event_date: generateField(eventElement?.date),
    event_duration: generateField(eventElement?.duration),
    event_participants_owner: generateField(
      eventElement?.participants?.owner?.id
    ),
    event_participants_members: generateField(
      eventElement?.participants?.members.map((p) => p.id).join(",")
    ),
    event_endDateTime: generateField(eventElement?.endDateTime),
  };

  /* ######################################################################## */

  const [formFields, setFormFields] = useState({
    ...eventElementBlockFields,
  });

  const [formState, setFormState] = useState({
    isValid: true,
    hasChanges: false,
  });

  /* ######################################################################## */

  const inputHandler = (event) => {
    const { id, value } = event.target;
    const field = formFields[id];
    let hasChanges = !(field.initialValue === value);
    let isValid = !!field.validators ? validate(value, field.validators) : true;

    setFormFields((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value: value,
        isValid: isValid,
        hasChanges: hasChanges,
      },
    }));

    setFormState((prevState) => ({
      ...prevState,
      isValid: Object.entries(formFields).every(([fieldId, fieldData]) =>
        id === fieldId ? isValid : fieldData.isValid
      ),
      hasChanges: Object.entries(formFields).some(([fieldId, fieldData]) =>
        id === fieldId ? hasChanges : fieldData.hasChanges
      ),
    }));
  };

  /* ######################################################################## */

  const submitFormHandler = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    for (const key in formFields) {
      if (
        typeof formFields[key].value === "object" &&
        typeof formFields[key].value.type === "undefined"
      ) {
        formData.append(key, JSON.stringify(formFields[key].value));
      } else {
        formData.append(key, formFields[key].value);
      }
    }

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/companies/eventElement/${eventElement.id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      console.log("EventForm", responseData);
      clearError();

      setFormState((prevState) => ({
        ...prevState,
        isValid: true,
        hasChanges: false,
      }));
    } catch (err) {}
  };

  const resetFormHandler = (event) => {
    //event.preventDefault();
    setFormFields((prevFormFields) => {
      const resetedFormFields = Object.fromEntries(
        Object.entries(prevFormFields).map(([key, value]) => [
          key,
          {
            ...value,
            value: value.initialValue,
            isValid: true,
            hasChanges: false,
          },
        ])
      );
      return resetedFormFields;
    });
    setFormState((prevState) => ({
      ...prevState,
      isValid: true,
      hasChanges: false,
    }));
    clearError();
  };

  /* ######################################################################## */

  return (
    <form
      className="form w-100"
      onSubmit={submitFormHandler}
      onReset={resetFormHandler}
    >
      {isLoading && <LoadingSpinner asOverlay />}
      {error && (
        <span
          className="d-flex aling-items-center justify-content-center text-danger p-2 my-4 bg-white rounded rpo-clickable"
          onClick={resetFormHandler}
        >
          {error}
        </span>
      )}

      <div className="row d-flex justify-content-between align-items-center">
        <div className="col-12 mb-8">
          <Input
            id={"event_title"}
            value={formFields["event_title"].value}
            handler={inputHandler}
            isValid={formFields["event_title"].isValid}
            className="form-control form-control-lg rpo-input fs-6 fw-normal text-gray-600 mb-3 mb-lg-0"
          />
        </div>

        <div className="col-6">
          <EntitySelectionBlock
            entity={eventElement?.participants?.owner}
            formFields={formFields}
            inputId="event_participants_owner"
            inputHandler={inputHandler}
            type="owner"
            title="Lead By"
            selectionTitle="Select an Owner"
            displaySeperator={false}
          />

          <EntityGroupSelectionBlock
            entities={eventElement?.participants?.members}
            formFields={formFields}
            inputId="event_participants_members"
            inputHandler={inputHandler}
            type="event_participants"
            title="Participants"
            selectionTitle="Select a Participant"
            displaySeperator={true}
          />
          <div className="separator separator-solid my-6 pt-6"></div>
        </div>
        <div className="col-5">
          <EventTimeBlocks formFields={formFields} inputHandler={inputHandler} />
        </div>
      </div>

      {(formState.isValid || formState.hasChanges) && (
        <React.Fragment>
          <div className="d-flex justify-content-end align items-center">
            {formState.hasChanges && <Button title="Discard" type="reset" />}
            {formState.isValid && formState.hasChanges && (
              <Button title="Save Changes" type="submit" />
            )}
          </div>
        </React.Fragment>
      )}
    </form>
  );
};

export default EventForm;
