import React from "react";
import ReactApexChart from "react-apexcharts";

function RadialBarChart(props) {

  var options = {
    labels: props.labels,
    series: props.series,
    chart: {
      fontFamily: "inherit",
      height: props.height,
      type: "radialBar",
      offsetY: 0,
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 0,
          size: "55%",
        },
        dataLabels: {
          showOn: "always",
          name: {
            show: true,
            fontSize: "14px",
            fontWeight: "500",
            offsetY: -40,
            color: getComputedStyle(document.documentElement).getPropertyValue(
              "--rpo-grey"
            ),
          },
          value: {
            show: true,
            fontSize: "48px",
            fontWeight: "500",
            offsetY: -5,
            color: getComputedStyle(document.documentElement).getPropertyValue(
              "--bs-text-gray-600"
            ),
            formatter: function (value) {
              return value + "%";
            },
          },
        },
        track: {
          background: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--rpo-light-purple"),
          strokeWidth: "100%",
        },
      },
    },
    colors: [
      getComputedStyle(document.documentElement).getPropertyValue(
        "--rpo-dark-purple"
      ),
    ],
    stroke: {
      lineCap: "round",
    },
  };

  return (
    <ReactApexChart
    options={options}
    series={options.series}
    type={options.chart.type}
    height={options.chart.height}
  />
  );
}
export default RadialBarChart;
