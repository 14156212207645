import React from "react";

import Input from "../../../../shared/FormElements/Input";

const UserBlock = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const getInput = (id, placeholder) => (
    <Input
      id={id}
      value={formFields[id].value}
      handler={inputHandler}
      isValid={formFields[id].isValid}
      placeholder={placeholder}
      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
    />
  );

  const blockElements = [
    {
      title: "Username (email)",
      element: getInput("email", "Username (email)"),
    },
    {
      title: "Role",
      element: getInput("role", "User role"),
    },
  ];

  return (
    <React.Fragment>
      <div className="row mb-6 mt-8">
        <div className="col-lg-4 d-md-flex flex-column align-items-start justify-content-center">
          <label className="col-form-label required fw-bold fs-6 p-0">
            User Information
          </label>
          <small className="col-form-label fw-light text-gray-400 p-0 pt-2">
            (email & role)
          </small>
        </div>
        <div className="col-lg-8">
          <div className="row">
            {blockElements.map((blockElement, index) => {
              return (
                <div key={index} className="col-lg-6 fv-row">
                  {blockElement.element}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserBlock;
