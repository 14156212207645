import React from "react";
import Select from "../../../../shared/FormElements/Select";

import Countries from "../../../../shared/connections/ThirdParty/Countries";
import Timezones from "../../../../shared/connections/ThirdParty/Timezones";

const CandidateLocationBlock = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const availiableCountries = Countries();
  const availiableTimezones = Timezones();

  const blockElements = [
    {
      title: "Location",
      element: (
        <Select
          name="profile_contactInformation_location_country"
          value={formFields["profile_contactInformation_location_country"].value}
          handler={inputHandler}
          defaultText="Select a location"
          options={availiableCountries}
          isValid={formFields["profile_contactInformation_location_country"].isValid}
        />
      ),
    },
    {
      title: "Time Zone",
      element: (
        <Select
          name="profile_contactInformation_timezone"
          value={formFields["profile_contactInformation_timezone"].value}
          handler={inputHandler}
          defaultText="Select a Time zone"
          options={availiableTimezones}
          isValid={formFields["profile_contactInformation_timezone"].isValid}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="separator separator-dashed my-6"></div>

      {blockElements.map((blockElement, index) => {
        return (
          <React.Fragment key={index}>
            <div className="separator my-6"></div>

            <div className="row form-group pe-19 mb-6">
              <div className="col-4 d-flex justify-content-start align-items-start">
                <label className="fs-6 fw-semibold text-gray-600 px-4">
                  {blockElement.title}
                </label>
              </div>
              <div className="col-4 pe-6">{blockElement.element}</div>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default CandidateLocationBlock;
