import React, { useState, useEffect } from "react";

const Select = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (props.options && props.value !== null) {
      const selected = props.options.find(
        (option) => option.value === props.value
      );
      setSelectedOption(selected);
    }
  }, [props.options, props.value]);

  if (props.options && props.options.length > 10)
    return (
      <div>
        <input
          id={props.name}
          list={`${props.name}-datalist`}
          className= {(props.isValid!==undefined && !props.isValid)?"form-control border-danger":"form-control"}
          placeholder={props.defaultText}
          value={selectedOption ? selectedOption.label : props.value}
          onChange={props.handler}
        />
        <datalist id={`${props.name}-datalist`}>
          {!!props.options &&
            props.options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
        </datalist>
      </div>
    );

  return (
    <select
      id={props.name}
      className={props.className?props.className:"form-select form-select-solid form-select-lg"}
      value={props.value}
      onChange={props.handler}
    >
      {!!props.defaultText && <option value="default">{props.defaultText}</option>}
      {!!props.options &&
        props.options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
  );
};

export default Select;
