import React, { useContext, useState } from "react";

import UserBlock from "./parts/user/UserBlock";
import ProfileBlock from "./parts/profile/ProfileBlock";
import ProfileContactBlock from "./parts/profile/ProfileContactBlock";
import ProfileLocationBlock from "./parts/profile/ProfileLocationBlock";
import ProfileSocialBlock from "./parts/profile/ProfileSocialBlock";
import CompanyRowBlock from "./parts/company/CompanyRowBlock";
import ProfileLocalePreferencesBlock from "./parts/profile/ProfileLocalePreferencesBlock";
import ProfileFlagsBlock from "./parts/profile/ProfileFlagsBlock";

import Button from "../../shared/FormElements/Button";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { validate } from "../../shared/util/validators";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_PHONE,
  VALIDATOR_WEBSITE,
  VALIDATOR_LINKEDIN,
} from "../../shared/util/validators";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useUser } from "../../shared/context/user-context";

const UserForm = () => {
  const auth = useContext(AuthContext);
  const { user, updateUser } = useUser();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  /* ######################################################################## */

  const generateField = (value, validators = [], initialValue = value) => {
    const field = {
      initialValue,
      isValid: true,
      hasChanges: false,
      validators,
    };

    if (value !== undefined && value !== null) {
      field.value = value;
    }

    return field;
  };

  /* ######################################################################## */

  const userBlockFields = {
    email: generateField(user.email, [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]),
    role: generateField(user.role, [VALIDATOR_REQUIRE()]),
  };

  const profileBlockFields = {
    profile_firstname: generateField(user?.profile?.firstname, [
      VALIDATOR_REQUIRE(),
    ]),
    profile_lastname: generateField(user?.profile?.lastname, [
      VALIDATOR_REQUIRE(),
    ]),
    profile_displayname: generateField(user?.profile?.displayname, [
      VALIDATOR_REQUIRE(),
    ]),
    image: generateField(user?.profile?.image_url),
    profile_contactInformation_email: generateField(
      user?.profile?.contactInformation?.email,
      [VALIDATOR_EMAIL()]
    ),
    profile_contactInformation_phone: generateField(
      user?.profile?.contactInformation?.phone,
      [VALIDATOR_PHONE()]
    ),
    profile_contactInformation_location_address: generateField(
      user?.profile?.contactInformation?.location?.address
    ),
    profile_contactInformation_location_aptsuite: generateField(
      user?.profile?.contactInformation?.location?.aptsuite
    ),
    profile_contactInformation_location_city: generateField(
      user?.profile?.contactInformation?.location?.city
    ),
    profile_contactInformation_location_stateprovince: generateField(
      user?.profile?.contactInformation?.location?.stateprovince
    ),
    profile_contactInformation_location_postalcode: generateField(
      user?.profile?.contactInformation?.location?.postalcode
    ),
    profile_contactInformation_location_country: generateField(
      user?.profile?.contactInformation?.location?.country
    ),
    profile_contactInformation_location_coordinates: generateField(
      user?.profile?.contactInformation?.location?.coordinates
    ),
    profile_contactInformation_social_linkedin: generateField(
      user?.profile?.contactInformation?.social?.linkedin,
      [VALIDATOR_REQUIRE(), VALIDATOR_LINKEDIN()]
    ),
    profile_contactInformation_social_website: generateField(
      user?.profile?.contactInformation?.social?.website,
      [VALIDATOR_REQUIRE(), VALIDATOR_WEBSITE()]
    ),
    profile_language: generateField(user?.profile?.language),
    profile_contactInformation_timezone: generateField(
      user?.profile?.contactInformation?.timezone
    ),
    profile_currency: generateField(user?.profile?.currency),
    profile_contactInformation_communication_email: generateField(
      user?.profile?.contactInformation?.communication?.email
    ),
    profile_contactInformation_communication_phone: generateField(
      user?.profile?.contactInformation?.communication?.phone
    ),
    profile_allowemailnotifications: generateField(
      user?.profile?.allowemailnotifications
    ),
  };

  const companyBlockFields = {
    company_name: generateField(user?.company?.name),
    company_website: generateField(user?.company?.website, [
      VALIDATOR_WEBSITE(),
    ]),
  };

  /* ######################################################################## */

  const [formFields, setFormFields] = useState({
    ...userBlockFields,
    ...profileBlockFields,
    ...companyBlockFields,
  });

  const [formState, setFormState] = useState({
    isValid: true,
    hasChanges: false,
  });

  /* ######################################################################## */

  const inputHandler = (event) => {
    const { id, value } = event.target;
    const field = formFields[id];
    let hasChanges = !(field.initialValue === value);
    let isValid = !!field.validators ? validate(value, field.validators) : true;

    setFormFields((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value: value,
        isValid: isValid,
        hasChanges: hasChanges,
      },
    }));

    setFormState((prevState) => ({
      ...prevState,
      isValid: Object.entries(formFields).every(([fieldId, fieldData]) =>
        id === fieldId ? isValid : fieldData.isValid
      ),
      hasChanges: Object.entries(formFields).some(([fieldId, fieldData]) =>
        id === fieldId ? hasChanges : fieldData.hasChanges
      ),
    }));
  };

  const checkboxHandler = (event) => {
    const { id, checked } = event.target;
    let hasChanges = !(formFields[id].initialValue === checked);

    setFormFields((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value: checked,
        hasChanges: hasChanges,
      },
    }));
    setFormState((prevState) => ({
      ...prevState,
      hasChanges: Object.entries(formFields).some(([fieldId, fieldData]) =>
        id === fieldId ? hasChanges : fieldData.hasChanges
      ),
    }));
  };

  /* ######################################################################## */

  const submitFormHandler = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    for (const key in formFields) {
      if (
        typeof formFields[key].value === "object" &&
        typeof formFields[key].value.type === "undefined"
      ) {
        formData.append(key, JSON.stringify(formFields[key].value));
      } else {
        formData.append(key, formFields[key].value);
      }
    }

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/users/user/${user.id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      updateUser(responseData);
      clearError();

      setFormState((prevState) => ({
        ...prevState,
        isValid: true,
        hasChanges: false,
      }));
    } catch (err) {}
  };

  const resetFormHandler = (event) => {
    //event.preventDefault();
    setFormFields((prevFormFields) => {
      const resetedFormFields = Object.fromEntries(
        Object.entries(prevFormFields).map(([key, value]) => [
          key,
          {
            ...value,
            value: value.initialValue,
            isValid: true,
            hasChanges: false,
          },
        ])
      );
      return resetedFormFields;
    });
    setFormState((prevState) => ({
      ...prevState,
      isValid: true,
      hasChanges: false,
    }));
    clearError();
  };

  /* ######################################################################## */
  return (
    <form
      className="form w-100"
      onSubmit={submitFormHandler}
      onReset={resetFormHandler}
    >
      {isLoading && <LoadingSpinner asOverlay />}
      {error && (
        <span
          className="d-flex aling-items-center justify-content-center text-danger p-2 my-4 bg-white rounded rpo-clickable"
          onClick={resetFormHandler}
        >
          {error}
        </span>
      )}
      <UserBlock formFields={formFields} inputHandler={inputHandler} />
      <ProfileBlock formFields={formFields} inputHandler={inputHandler} />
      <ProfileContactBlock
        formFields={formFields}
        inputHandler={inputHandler}
      />
      <ProfileLocationBlock
        formFields={formFields}
        inputHandler={inputHandler}
      />
      <CompanyRowBlock formFields={formFields} inputHandler={inputHandler} />
      <ProfileSocialBlock
        formFields={formFields}
        selectHandler={inputHandler}
      />
      <ProfileLocalePreferencesBlock
        formFields={formFields}
        selectHandler={inputHandler}
      />
      <ProfileFlagsBlock
        formFields={formFields}
        checkboxHandler={checkboxHandler}
      />

      {(formState.isValid || formState.hasChanges) && (
        <React.Fragment>
          <div className="separator separator-dashed my-6"></div>
          <div className="d-flex justify-content-end align items-center">
            {formState.hasChanges && <Button title="Discard" type="reset" />}
            {formState.isValid && formState.hasChanges && (
              <Button title="Save Changes" type="submit" />
            )}
          </div>
        </React.Fragment>
      )}
    </form>
  );
};

export default UserForm;
