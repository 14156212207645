import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import AreaChart from "../../shared/charts/AreaChart";
import * as assets from "../../assets/svgs.js";

import { Data } from "../../shared/connections/Data";

const VsWidgetCard = (props) => {
  const isMounted = useIsComponentMounted("VsWidgetCard");

  const data = Data({ type: "data", path: "chart", name: props.name });

  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          {!!data && (
            <div className="card border">
              <div className="card-header border-0 py-5">
                <span className="fs-5 text-uppercase">{data && data.title}</span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <h1 className="display-6 text-gray-600 fw-bold d-flex pb-4">
                      {data && data.current_value}
                    </h1>
                    <div className="d-flex align-items-center">
                      <div
                        className={`rpo-svg-20px ${
                          data && data.color === "danger"
                            ? "rpo-stroke-path-red rpo-rotate-90"
                            : "rpo-stroke-path-green"
                        }`}
                      >
                        {assets.svg_arrow_up}
                      </div>
                      <div
                        className={`fs-7 text-${data && data.color} ps-1 pe-2`}
                      >
                        {data && data.vs_last_month_percentage}%
                      </div>
                      <div className="fs-6 text-gray-200">vs last month</div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      className="card-rounded-bottom"
                      style={{ height: "80px" }}
                    >
                      <AreaChart
                        series={data.series}
                        categories={data.categories}
                        color={data.color}
                        height={80}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default VsWidgetCard;
