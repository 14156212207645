import React from "react";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import { Data } from "../../shared/connections/Data";
function CandidateCompaniesApplicationsCard(props) {
  const isMounted = useIsComponentMounted("CandidateCompaniesApplicationsCard");

  const data = Data({
    type: "candidates",
    path: "candidate/applications",
    name: props.candidateId,
  });

  let rows = [];

  if (!!data) {
    let companies = [];
    for (let application of data) {
      if (!companies[application.company.id]) {
        companies[application.company.id] = {
          company_name: application.company.name,
          company_website: application.company.website,
          company_image_url: application.company.image_url,
          applications: 1,
          max_match: parseFloat(application.matchPercentage),
        };
      } else {
        companies[application.company.id].applications += 1;
        let currentMatchPercentage = parseFloat(application.matchPercentage);
        if (
          currentMatchPercentage > companies[application.company.id].max_match
        ) {
          companies[application.company.id].max_match = currentMatchPercentage;
        }
      }
    }

    // Push company information into rows array
    for (let companyId in companies) {
      rows.push({
        company_id: companyId,
        company_name: companies[companyId].company_name,
        company_website: companies[companyId].company_website,
        company_image_url: companies[companyId].company_image_url,
        applications: companies[companyId].applications,
        max_match: companies[companyId].max_match,
      });
    }
  }

  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          {rows.length > 0 && (
            <div className="card border">
              <div className="card-header border-0 pt-5 align-items-center">
                <span className="fs-5 text-uppercase">Companies applications</span>
              </div>
              <div className="card-body">
                <div className="row pb-5">
                  <div className="col-6">
                    <span className="fs-7">Name</span>
                  </div>
                  <div className="col-2">
                    <span className="fs-7 d-flex justify-content-center align-items-center">
                      No.
                    </span>
                  </div>
                  <div className="col-4 d-flex justify-content-end align-items-center">
                    <span className="fs-7">Match(%)</span>
                  </div>
                </div>
                {rows.map((row, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="separator separator-dashed"></div>

                      <div className="row py-4">
                        <div className="col-6">
                          <div className="d-flex justify-content-start align-items-center">
                            <div className="symbol symbol-30px symbol-md-40px symbol-circle bg-info">
                              <img
                                src={
                                  process.env.REACT_APP_ASSET_URL_PATH +
                                  row.company_image_url
                                }
                                alt="company"
                              />
                            </div>
                            <div className="d-none d-md-flex flex-column align-items-start justify-content-center ms-2 ms-md-4">
                              <span className="fs-6 fw-semibold text-warning lh-1 mb-1">
                                {row.company_name}
                              </span>
                              <span className="fs-7 text-gray-300 lh-1">
                                {row.company_website}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 d-flex justify-content-center align-items-center">
                          <span className="fs-6 text-grey-600">
                            {row.applications}
                          </span>
                        </div>
                        <div className="col-4 d-flex justify-content-end align-items-center">
                          <span className="fs-6 text-info">
                            {row.max_match}
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default CandidateCompaniesApplicationsCard;
