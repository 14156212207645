import React from "react";

import Input from "../../../../shared/FormElements/Input";
import Select from "../../../../shared/FormElements/Select";

import { Data } from "../../../../shared/connections/Data";

const JobBlocks = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  const jobStatuses = Data({type: "fields", path: "availiable", name: "job_status" });
  const priorities = Data({type: "fields", path: "availiable", name: "priority" });
  const jobEmploymentType = Data({type: "fields", path: "availiable", name: "job_employment_type" });
  const jobWorkplaceType = Data({type: "fields", path: "availiable", name: "job_workplace_type" });

  const getInput = (id) => (
    <Input
      id={id}
      value={formFields[id].value}
      handler={inputHandler}
      isValid={formFields[id].isValid}
      className="form-control form-control-lg rpo-input fs-6 fw-normal text-gray-600 mb-3 mb-lg-0"
    />
  );

  const getSelect = (id, defaultText="Select a value", availiableOptions=[]) => (
    <Select
      name={id}
      value={formFields[id].value}
      handler={inputHandler}
      defaultText={defaultText}
      options={availiableOptions}
      isValid={formFields[id].isValid}
      className="form-control form-control-lg rpo-input rpo-select-icon-arrow-down fs-6 fw-normal text-gray-600 mb-3 mb-lg-0 me-6"
    />
  );

  const blockElements = [
    { title: "Title", element: getInput("job_title") },
    { title: "Status", element: getSelect("job_status", 'Select a status', jobStatuses)},
    { title: "Priority", element: getSelect("job_priority", 'Select a priority', priorities)},
    { title: "Employment Type", element: getSelect("job_information_employment_type", 'Select an Employment Type', jobEmploymentType)},
    { title: "Workplace Type", element: getSelect("job_information_workplace_type", 'Select an Employment Type', jobWorkplaceType)},
  ];

  return (
    <React.Fragment>
      <div className="separator separator-solid my-6"></div>

      {blockElements.map((blockElement, index) => {
        return (
          <div key={index} className="row mb-8">
            <label className="col-lg-6 fs-6 fw-semibold text-gray-600 px-4">
              {blockElement.title}
            </label>
            <div className="col-lg-6 fv-row">{blockElement.element}</div>
          </div>
        );
      })}

    </React.Fragment>
  );
};

export default JobBlocks;
