import React from "react";

import Input from "../../../../shared/FormElements/Input";

const CandidateProfileBlock = (props) => {
  const formFields = props.formFields;
  const inputHandler = props.inputHandler;

  return (
    <React.Fragment>
      <div className="separator my-6"></div>

      <div className="row form-group pe-19">
        <div className="col-4 d-flex justify-content-start align-items-center">
          <div className="d-flex">
            <div className="symbol symbol-30px symbol-md-40px symbol-circle">
              <img src={process.env.REACT_APP_ASSET_URL_PATH+formFields["image"].value} alt="candidate" />
            </div>
          </div>
          <label className="fs-6 fw-semibold text-gray-600 px-4">Name</label>
        </div>
        <div className="col-4 pe-6">
          <Input
            id="profile_firstname"
            value={formFields["profile_firstname"].value}
            handler={inputHandler}
            isValid={formFields["profile_firstname"].isValid}
            className="form-control form-control-lg rpo-input fs-6 fw-normal text-gray-600 mb-3 mb-lg-0"
          />          
        </div>
        <div className="col-4 ps-6">
        <Input
            id="profile_lastname"
            value={formFields["profile_lastname"].value}
            handler={inputHandler}
            isValid={formFields["profile_lastname"].isValid}
            className="form-control form-control-lg rpo-input fs-6 fw-normal text-gray-600 mb-3 mb-lg-0"
          /> 
        </div>
      </div>
    </React.Fragment>
  );
};

export default CandidateProfileBlock;
