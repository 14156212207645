import React from "react";

import Modal from "@mui/material/Modal";

import Button from "../../shared/FormElements/Button";
import * as assets from "../../assets/svgs.js";

const CandidateModal = (props) => {
  const selectedCandidate = props.selectedEntity;

  return (
    <Modal
      open={props.open}
      onClose={props.closeModalHandler}
      aria-labelledby="staticBackdrop"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-dialog float-end pe-16" role="document">
        <div
          className="modal-content p-10 pe-10 bg-white border-rounded rounded-3"
          style={{ top: "35px", minWidth: "450px", maxWidth: "490px" }}
        >
          <div className="modal-header p-0 border-0">
            <span className="fs-6 fw-semibold text-black">
              Candidate Profile
            </span>
            <div
              className="rpo-svg-25px rpo-fill-first-orange-other-white rpo-clickable"
              aria-label="Close"
              onClick={props.closeModalHandler}
            >
              {assets.svg_close_circle}
            </div>
          </div>

          <div className="modal-body p-0">
            <div className="d-flex justify-content-start align-items-center pt-14 pb-10">
              <div className="symbol symbol-30px symbol-md-65px symbol-circle">
                <img
                  src={
                    process.env.REACT_APP_ASSET_URL_PATH +
                    selectedCandidate.profile.image_url
                  }
                  alt="profile"
                />
              </div>
              <div className="d-none d-md-flex flex-column align-items-start justify-content-center ms-2 ms-md-4">
                <span className="fs-3 fw-semibold text-black lh-1">
                  {selectedCandidate.profile.display_name}
                </span>
                <span className="fs-6 text-gray-300 lh-1 py-2">
                  {
                    selectedCandidate.employment.history[
                      selectedCandidate.employment.history.length - 1
                    ].title
                  }
                </span>
                <div className="d-flex justify-content-start align-items-center">
                  {selectedCandidate.skills &&
                    selectedCandidate.skills.map((skill, index) => {
                      if (index < 3) {
                        return (
                          <span
                            key={index}
                            className={`badge ${skill.display.color.badge_color_class} rpo-badge-border-pill fs-7 fw-semibold me-2 px-3`}
                          >
                            {skill.display.text.title}
                          </span>
                        );
                      }
                      return "";
                    })}
                  {selectedCandidate.skills &&
                  selectedCandidate.skills.length > 3 ? (
                    <span className="badge rpo-badge-grey rpo-badge-border-pill fs-7 fw-semibold px-3">
                      {selectedCandidate.skills.length - 3}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <span className="fs-6 fw-semibold text-info pe-4 text-nowrap">
                Matching score:
              </span>
              <div className="progress h-6px w-100 me-2 bg-info-light">
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{
                    width: `${Math.max(
                      ...[
                        ...new Set(
                          selectedCandidate.applications.map((application) =>
                            parseInt(application.matchPercentage)
                          )
                        ),
                      ]
                    )}%`,
                  }}
                  aria-valuenow="40"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <span className="fs-6 fw-semibold text-gray-600">
                {Math.max(
                  ...[
                    ...new Set(
                      selectedCandidate.applications.map((application) =>
                        parseInt(application.matchPercentage)
                      )
                    ),
                  ]
                )}
                %
              </span>
            </div>

            <div className="separator my-10"></div>

            <div className="d-flex justify-content-start align-items-center pb-10">
              <span className="fs-6 fw-semibold text-info pe-4 text-nowrap rpo-min-w-128px text-end">
                Current:
              </span>
              <span className="fs-6 fw-semibold text-gray-600">
                {
                  selectedCandidate.employment.history[
                    selectedCandidate.employment.history.length - 1
                  ].title
                }
              </span>
            </div>

            <div className="d-flex justify-content-start align-items-center pb-10">
              <span className="fs-6 fw-semibold text-info pe-4 text-nowrap rpo-min-w-128px text-end">
                Previous:
              </span>
              <span className="fs-6 fw-semibold text-gray-600">
                {!!selectedCandidate.employment.history[
                  selectedCandidate.employment.history.length - 2
                ]
                  ? selectedCandidate.employment.history[
                      selectedCandidate.employment.history.length - 2
                    ].title
                  : "-"}
              </span>
            </div>

            <div className="d-flex justify-content-start align-items-center pb-10">
              <span className="fs-6 fw-semibold text-info pe-4 text-nowrap rpo-min-w-128px text-end">
                Education:
              </span>
              <span className="fs-6 fw-semibold text-gray-600">
                {selectedCandidate.education}
              </span>
            </div>

            <div className="d-flex justify-content-start align-items-center">
              <span className="fs-6 fw-semibold text-info pe-4 text-nowrap rpo-min-w-128px text-end">
                Email:
              </span>
              <a
                className="fs-6 fw-semibold text-warning"
                href={`mailto:${selectedCandidate.profile.contactInformation.email}`}
                alt="candidate"
              >
                {selectedCandidate.profile.contactInformation.email}
              </a>
            </div>

            <div className="separator my-10"></div>

            <div className="d-flex flex-column">
              <span className="fs-6 fw-semibold text-info pb-5">Summary</span>
              <div className="fs-6 fw-semibold text-gray-600 rpo-last-child-no-margin-no-padding">
                {selectedCandidate.notes.summary}
              </div>
            </div>

            <div className="separator my-10"></div>

            <div className="d-flex flex-column">
              <span className="fs-6 fw-semibold text-info pb-5">
                Experience
              </span>
              <div className="fs-6 fw-semibold text-gray-600 rpo-last-child-no-margin-no-padding">
                {selectedCandidate.experience}
              </div>
            </div>

            <div className="separator my-10"></div>

            <div className="d-flex justify-content-center align-items-center">
              <Button
                title="Show more"
                to={`/candidate/${selectedCandidate.id}`}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CandidateModal;
