import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useIsComponentMounted } from "../../shared/hooks/component-hook";

import MainNavigation from "../../shared/Navigation/MainNavigation";
import DashboardTop from "../parts/DashboardTop";
import Breadcrumb from "../parts/Breadcrumb";
import Scrolltop from "../../shared/components/UIElements/Scrolltop";

import CandidateCard from "../cards/CandidateCard";
import UserQuickActionsCard from "../cards/UserQuickActionsCard";
import CandidateMatchingScoreCard from "../cards/CandidateMatchingScoreCard";
import CandidateCompaniesApplicationsCard from "../cards/CandidateCompaniesApplicationsCard";
import UserEventsCard from "../cards/UserEventsCard";

const Candidate = () => {
  const isMounted = useIsComponentMounted("Candidate");
  useEffect(() => {
    document.title = "Candidate";
  }, []);
  const candidateId = useParams().candidateId;

  return (
    <React.Fragment>
      {isMounted && (
        <React.Fragment>
          <div className="page d-flex flex-row flex-column-fluid">
            <div className="wrapper d-flex flex-column flex-row-fluid rpo-wrapper-fuild-padding">
              <div className="d-flex flex-column-fluid">
                <MainNavigation />
                <div className="d-flex flex-column flex-column-fluid container-fluid rpo-px-32">
                  <div className="content flex-column-fluid">
                    <div className="row m-0 p-0">
                      <div className="col-12 m-0 p-0 pb-2">
                        <DashboardTop />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 mb-7">
                        <Breadcrumb />
                      </div>
                    </div>

                    <div className="row g-5 g-xl-12">
                      <div className="col-lg-12 col-xl-8 col-xxl-8 mb-5 mb-xl-0">
                        <CandidateCard candidateId={candidateId} />
                      </div>

                      <div className="col-lg-12 col-xl-4 col-xxl-4 mb-5 mb-xl-0">
                        <div className="mb-12">
                          <UserQuickActionsCard />
                        </div>
                        <div className="mb-12">
                          <CandidateMatchingScoreCard />
                        </div>
                        <div className="mb-12">
                          <CandidateCompaniesApplicationsCard
                            candidateId={candidateId}
                          />
                        </div>
                        <UserEventsCard />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrolltop />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Candidate;
