import React, { useRef, useEffect } from "react";

const Map = (props) => {
  const mapRef = useRef();
  const markerRef = useRef();
  const { mapId, center, zoom, hasAutoCompleteForm, autocompleteFields } =
    props;

  useEffect(() => {
    if (hasAutoCompleteForm && !!autocompleteFields) {
      const getFormInputElement = (component) =>
        document.getElementById(component);

      const map = new window.google.maps.Map(mapRef.current, {
        zoom: zoom,
        center: center,
        mapId: mapId,
        mapTypeControl: false,
      });

      markerRef.current = new window.google.maps.marker.AdvancedMarkerElement({
        map,
        position: center,
      });

      const autocompleteInput = getFormInputElement(autocompleteFields.route);
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteInput,
        {
          fields: ["address_components", "geometry", "name"],
          types: ["address"],
        }
      );

      autocomplete.addListener("place_changed", function () {
        const place = autocomplete.getPlace();

        if (!place.geometry) {
          return;
        }

        map.setCenter(place.geometry.location);
        markerRef.current.position = place.geometry.location;

        // Optional parameter
        const getAddressComp = (type) => {
          const component = place.address_components.find((component) =>
            component.types.includes(type)
          );
          return component ? component.long_name : "";
        };

        getFormInputElement(autocompleteFields.route).value = getAddressComp(
          autocompleteFields.route
        );

        Object.keys(autocompleteFields).forEach((key) => {
          const value = autocompleteFields[key];

          if (key === "coordinates") {
            if (
              markerRef.current.position.Gg &&
              markerRef.current.position.Hg
            ) {
              getFormInputElement(value).value =
                markerRef.current.position.Gg +
                ", " +
                markerRef.current.position.Hg;
            }
          } else {
            getFormInputElement(value).value = getAddressComp(key);
            if (key === "route") {
              getFormInputElement(value).value +=
                " " + getAddressComp("street_number");
            }
          }
        });

        // Trigger value / state update
        const inputElements = document.querySelectorAll("input");
        inputElements.forEach((input) => {
          input.dispatchEvent(new Event("input", { bubbles: true }));
        });
      });
    } else {
      const map = new window.google.maps.Map(mapRef.current, {
        zoom: zoom,
        center: center,
        mapId: mapId,
      });

      new window.google.maps.marker.AdvancedMarkerElement({
        map,
        position: center,
      });
    }
  }, [center, zoom, mapId, hasAutoCompleteForm, autocompleteFields]);

  return (
    <div
      ref={mapRef}
      className={`map ${props.className}`}
      style={props.style}
    ></div>
  );
};

export default Map;
