import React from "react";
import { Link } from "react-router-dom";

import * as assets from "../../assets/svgs.js";
import Logo from "../../shared/components/UIElements/Logo";

import { useUser } from "../../shared/context/user-context";

const MainNavigation = (props) => {
  const { user } = useUser();

  const aside_menu_items = [
    {
      url: "/",
      title: "Overview",
      icon: assets.svg_menu,
    },
    {
      url: "/client-overview",
      title: "Client Overview",
      icon: assets.svg_menu,
    },
    {
      url: "/activities",
      title: "Activities",
      icon: assets.svg_settings,
    },
    {
      url: "/tasks",
      title: "Tasks",
      icon: assets.svg_checklist,
    },
    {
      url: "/jobs",
      title: "Jobs",
      icon: assets.svg_briefcase,
    },
    {
      url: "/job-board",
      title: "Job board",
      icon: assets.svg_briefcase,
    },
    {
      url: "/clients",
      title: "Clients",
      icon: assets.svg_user_octagon,
    },
    {
      url: "/candidates",
      title: "Candidates",
      icon: assets.svg_user,
    },
    {
      url: "/client-candidates",
      title: "Client Candidates",
      icon: assets.svg_user,
    },
    {
      url: "/leads-board",
      title: "Leads board",
      icon: assets.svg_favorite_chart,
    },
    {
      url: "/messages",
      title: "Messages / Sequences",
      icon: assets.svg_sms,
    },
    // {
    //   url: "/contracts-agreements",
    //   title: "Contracts / Agreements",
    //   icon: assets.svg_book,
    // },
    {
      url: "/reports",
      title: "Insights / Reporting",
      icon: assets.svg_chart_2,
    },
    {
      url: "/teams",
      title: "Team",
      icon: assets.svg_people,
    },
  ];

  if (!!user) {
    aside_menu_items.push({
      url: "/profile/",
      title: "Settings",
      icon: assets.svg_setting_5,
    });
  }

  return (
    <React.Fragment>
      <div className="aside card bg-rpo rpo-border-1-r8 text-white rpo-left-32 overflow-hidden">
        <div className="aside-title d-flex justify-content-center align-items-center">
          <h1 className="display-6 text-white m-0 py-8">RPO.AI</h1>
        </div>
        <div className="aside-menu flex-column-fluid px-4">
          <div className="menu menu-column fs-6">
            {aside_menu_items.map((menuItem) => (
              <div className="menu-item" key={"menu-item-" + menuItem.title}>
                <Link
                  to={menuItem.url}
                  className={`menu-link rpo-main-menu-item ${
                    window.location.pathname === menuItem.url ? "selected" : ""
                  }`}
                >
                  <span className="menu-icon rpo-svg-20px">
                    {menuItem.icon}
                  </span>
                  <span className="menu-title">{menuItem.title}</span>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="aside-footer flex-column-auto pt-5 pb-7 px-7">
          <span className="d-flex align-items-center justify-content-center fs-8 flex-row-auto lh-1 pb-4">
            Powered by:
          </span>
          <a
            className="d-flex align-items-center justify-content-center"
            alt="rpo"
            href="/"
          >
            <Logo className="h-70px h-lg-60px" />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainNavigation;
